import React, { useState } from 'react';
import { IResetBtn } from '@shared/types/button';


export const ResetBtn = ({ onReset }: IResetBtn) => {
    const [isReset, setIsReset] = useState(false);

    const handleClick = () => {
        if (!isReset) {
            setIsReset(true);
            if (onReset) {
                onReset();
            }
            setTimeout(() => {
                setIsReset(false);
            }, 1000);
        }
    };

    return (
        <button
            onClick={handleClick}
            className="flex items-center gap-3 relative"
        >
            <p className="text-[#E34922] text-base font-semibold border-b-2 border-b-P3_Red leading-4">초기화</p>
        </button>
    );
};
