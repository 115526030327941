import React from 'react';
import {
    SalesAmount,
    WithdrawalAmount,
    MonthDepositAmount,
} from '@features/Calendar';
import { ITotalAmountDto } from '@entities/Calendar/model/interfaces';
import { Flex, RefreshBtn, Spacer } from '@shared/ui';
import { ColorLabel } from '@shared/ui/colorLabel/ColorLabel';

export const CalendarAmount = ({
    currentDate,
    totalAmountInfo,
}: ITotalAmountDto) => {
    return (
        <>
            <Flex justify="start" className="gap-[96px]">
                <SalesAmount
                    currentDate={currentDate}
                    totalAmountInfo={totalAmountInfo}
                />
                <MonthDepositAmount
                    currentDate={currentDate}
                    totalAmountInfo={totalAmountInfo}
                />
                <WithdrawalAmount
                    currentDate={currentDate}
                    totalAmountInfo={totalAmountInfo}
                />
                <Spacer />
                <RefreshBtn />
            </Flex>
            <div className="w-[100%] border-b border-P3_Gray5 my-4"></div>
            <Flex className="gap-5">
                <ColorLabel color="P3_PointB" label="매출액" />
                <ColorLabel color="P3_Red" label="정산액" />
                <ColorLabel color="P3_Gray4" label="출금액" />
            </Flex>
        </>
    );
};
