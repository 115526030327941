import { AxiosRequestHeaders, AxiosResponse } from 'axios';
import { ICompanyInfoDto } from '../model/interface';

const companyInfoData = {
    compInfo: {
        companyName: '주식회사 1234',
        establishmentDate: '3024-12-32',
        businessType: '개인사업자',
        businessRegistrationNumber: '123-45-78901',
        corporateRegistrationNumber: '111-11-11111',
        businessAddress: '서울시 송파구 강남동 382-24',
        detailedAddress: '5200002호',
        industryType: '도매 및 소매업',
        businessField: '전자상거래 소매업',
        website: 'https://violetpay.com',
        contactInfo: '1588-6655',
    },
};

export const companyInfoAPI = async (): Promise<
    AxiosResponse<ICompanyInfoDto>
> => {
    return {
        data: companyInfoData,
        status: 200,
        statusText: 'OK',
        headers: {},
        config: {
            headers: {} as AxiosRequestHeaders,
        },
    };
};
