import { tw } from '@shared/lib/Tailwindest';

export const walletBalanceContainer = tw.style({
    display: 'flex',
    justifyContent: 'justify-between',
    backgroundColor: 'bg-P3_Black',
    color: 'text-P3_White',
    width: 'w-[100%]',
    paddingY: 'py-[22px]',
    paddingLeft: 'pl-12',
    paddingRight: 'pr-[22px]',
    borderRadius: 'rounded-lg',
});

export const walletBalanceContainerBasic = tw.style({
    backgroundColor: 'bg-P3_Black',
    color: 'text-P3_White',
    width: 'w-[100%]',
    minHeight: 'min-h-[136px]',
    paddingY: 'py-[22px]',
    paddingLeft: 'pl-12',
    paddingRight: 'pr-[22px]',
    borderRadius: 'rounded-lg',
});

export const WarningContainer = tw.style({
    backgroundColor: 'bg-P3_Red',
    width: 'w-[30%]',
    paddingRight: 'pr-6',
    paddingLeft: 'pl-5',
    paddingTop: 'pt-6',
    paddingBottom: 'pb-5',
    borderTopLeftRadius: 'rounded-tl-lg',
    borderTopRightRadius: 'rounded-tr-lg',
    borderBottomRightRadius: 'rounded-br-lg',
});
