import React from 'react';
import { formatMonthDeposit } from '@features/Calendar/model/salesAmount.model';
import { Flex, Tooltip } from '@shared/ui';
import { ITotalAmountDto } from '@entities/Calendar';

export const WithdrawalAmount = ({
    currentDate,
    totalAmountInfo,
}: ITotalAmountDto) => {
    const monthText = formatMonthDeposit({ currentDate });
    return (
        <Flex col className="gap-3">
            <Flex items="center" className="gap-2">
                <span className="text-P3_Black font-semibold">
                    {monthText} 출금액
                </span>
                <Tooltip content="지갑에서 출금한 총액" direction="right" />
            </Flex>
            <span className="font-semibold text-xl">
                {' '}
                {totalAmountInfo
                    ? `${totalAmountInfo.monthWithdrawAmount.toLocaleString()} 원`
                    : '데이터 로딩 중...'}
            </span>
        </Flex>
    );
};
