import { tw } from '@shared/lib/Tailwindest';

export const paginationUl = tw.style({
    display: 'flex',
    width: 'w-[100%]',
    justifyContent: 'justify-center',
    alignItems:"items-center",
    gap: 'gap-[25px]',
    fontSize: 'text-[14px]',
    overflow: 'overflow-auto',
    color: 'text-P3_Gray5',
    // cursor: 'cursor-pointer'
});
