import { ICeoInfoDto } from '@entities/CompanyInfo';
import { Flex, Table, Tbody, Td, Text, Th, Tr } from '@shared/ui';
import React from 'react';

export const CEOInfo = ({ ceoInfo }: ICeoInfoDto) => {
    return (
        <div className='min-w-[860px]'>
            <Flex justify="between" className="my-5">
                <h3 className="text-P3_Black text-[18px] font-semibold">
                    대표자
                </h3>
            </Flex>
            <Table className="font-semibold">
                <Tbody>
                    <Tr className="border-P3_Gray4">
                        <Th className="w-[15%] border-none text-start py-[10px] px-5 min-w-[140px]">
                            이름(국문)
                        </Th>
                        <Td className="w-[35%] border-none">
                            {ceoInfo.koreaName}
                        </Td>
                        <Th className="w-[15%] border-none text-start py-[10px] px-5">
                            이름(영어)
                        </Th>
                        <Td className="border-none">{ceoInfo.englishName}</Td>
                    </Tr>
                    <Tr className="border-P3_Gray4">
                        <Th className="border-none text-start py-[10px] px-5">
                            주민번호
                        </Th>
                        <Td className="border-none">
                            {ceoInfo.socialSecurityNumber}
                        </Td>
                        <Th className="border-none text-start py-[10px] px-5">
                            연락처
                        </Th>
                        <Td className="border-none">{ceoInfo.phoneNumber}</Td>
                    </Tr>
                    <Tr className="border-P3_Gray4">
                        <Th className="border-none text-start py-[10px] px-5">
                            주소
                        </Th>
                        <Td colSpan={3}>{ceoInfo.address}</Td>
                    </Tr>
                    <Tr className="border-P3_Gray4">
                        <Th className="border-none text-start py-[10px] px-5">
                            사업장주소
                        </Th>
                        <Td colSpan={3}>{ceoInfo.businessAddress}</Td>
                    </Tr>
                </Tbody>
            </Table>
            <Text size="sm" className="flex justify-end mt-3">
                ∙ 정보 수정을 원하시면 고객센터로 연락해 주세요. 고객센터
                (02-4323-1004)
            </Text>
        </div>
    );
};
