import React from 'react';
import { Toaster } from 'react-hot-toast';
import 'tailwindcss/tailwind.css';
import { RouterProvider } from 'react-router-dom';
import CustomRouter from './AppRouter';
import { Provider } from 'react-redux';
import { store } from './AppStore';

function App() {
    return (
        <>
            <Toaster position="top-center" />
                <Provider store={store}>
                    <RouterProvider router={CustomRouter} />
                </Provider>
        </>
    );
}

export default App;
