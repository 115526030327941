import React, { useEffect, useState } from 'react';

export const NoticeCharge = () => {
    // 성공, 취소 별로 데이터가 나타나야 함
    // 지금은 정적인 데이터로 작성
    // 추후 웹소켓 동적으로 받아올 때 다시 작성 해야 함

    interface IData {
        amount: number;
        date: string;
        walletBalance: number;
    }

    interface ISuccess {
        success: IData;
    }

    interface IFail {
        fail: IData;
    }

    type DataType = ISuccess | IFail;

    const [success, setSuccess] = useState<IData[]>([]);
    const [fail, setFail] = useState<IData[]>([]);

    const data: DataType[] = [
        {
            success: {
                amount: 632000,
                date: '2024-06-13 14:23:36',
                walletBalance: 100,
            },
        },
        {
            fail: {
                amount: 632000,
                date: '2024-06-13 14:23:36',
                walletBalance: 100,
            },
        },
        {
            success: {
                amount: 632000,
                date: '2024-06-13 14:23:36',
                walletBalance: 100,
            },
        },
        {
            fail: {
                amount: 632000,
                date: '2024-06-13 14:23:36',
                walletBalance: 100,
            },
        },
        {
            fail: {
                amount: 632000,
                date: '2024-06-13 14:23:36',
                walletBalance: 100,
            },
        },
        {
            fail: {
                amount: 632000,
                date: '2024-06-13 14:23:36',
                walletBalance: 100,
            },
        },
    ];

    useEffect(() => {
        const successData: IData[] = [];
        const failData: IData[] = [];

        data.forEach((item) => {
            if ('success' in item) {
                successData.push(item.success);
            } else if ('fail' in item) {
                failData.push(item.fail);
            }
        });

        setSuccess(successData);
        setFail(failData);
    }, []);

    return (
        <div className="mt-2 h-[550px] overflow-y-scroll">
            <ul className="w-full">
                {fail.length > 0 &&
                    fail.map((f, index) => (
                        <li
                            key={index}
                            className="py-8 pl-[53px] pr-[60px] border-b"
                        >
                            <div className="flex justify-between">
                                <p className="text-P3_Red text-xl font-bold">
                                    충전 실패
                                </p>
                                <p className="text-P3_Gray5 text-sm">
                                    {f.date}
                                </p>
                            </div>
                            <p className="text-P3_Black text-lg">
                                {f.amount}원 취소에 실패했어요. 입금 기한을
                                확인해 주세요.
                            </p>
                            <p className="text-P3_Gray5">
                                오류발생 시 고객센터로 연락해주세요. 고객센터
                                02-0000-0000
                            </p>
                        </li>
                    ))}
            </ul>
            <ul className="w-full">
                {success.length > 0 &&
                    success.map((s, index) => (
                        <li
                            key={index}
                            className="py-8 pl-[53px] pr-[60px] border-b"
                        >
                            <div className="flex justify-between">
                                <p className="text-P3_Black text-xl font-bold">
                                    충전 성공
                                </p>
                                <p className="text-P3_Gray5 text-sm">
                                    {s.date}
                                </p>
                            </div>
                            <p className="text-P3_Black text-lg">
                                {s.amount}원 취소에 실패했어요. 지갑의 잔액을
                                확인해 주세요.
                            </p>
                            <p className="text-P3_Gray5">
                                지갑 잔액: {s.walletBalance}원
                            </p>
                        </li>
                    ))}
            </ul>
        </div>
    );
};
