import { TransactionPaymentInfoAPI } from '@entities/TransactionPaymentInfo/api/TransactionPaymentInfoAPI';
import { ITransactionPaymentInfoHistoryDto, ITransactionPaymentInfoHistoryArrDto } from '@entities/TransactionPaymentInfo/model/interfaces';
import { useEffect, useState } from 'react';

export function useTransactionPaymentInfo() {
    const [amountData, setAmountData] = useState<ITransactionPaymentInfoHistoryDto[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await TransactionPaymentInfoAPI();
                const data: ITransactionPaymentInfoHistoryArrDto = response.data;
                setAmountData(data.TransactionHistoryInfo);
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        };

        fetchData();
    }, []);

    return amountData;
}
