import React, { useCallback, useState } from 'react';
import {
    TransactionPaymentInfoHistory,
    TransactionPaymentInfoFilter,
    TransactionPaymentInfoHeader,
} from '@widgets/TransactionPaymentInfo';
import { ITransactionPaymentInfoHistoryDto, useTransactionPaymentInfo } from '@entities/TransactionPaymentInfo';
import { Button, Flex } from '@shared/ui';
import { AnimatePresence, motion } from 'framer-motion';
import { transactionPaymentInfoContainer } from './transactionPaymentInfo.styled';
import { filterCheck } from '@features/TransactionPaymentInfo/lib/filterCheck';

export const TransactionPaymentInfo = () => {
    const [filteredHistory, setFilteredHistory] = useState<ITransactionPaymentInfoHistoryDto[]>([]);
    const [filterCriteria, setFilterCriteria] = useState({
        startDate: '',
        endDate: '',
        buyerName: '',
        buyerId: '',
        paymentAmount: '',
    });
    const [searchTerm, setSearchTerm] = useState('');
    const TransactionHistoryInfo = useTransactionPaymentInfo();

    const handleSearch = useCallback((term: string) => {
        setSearchTerm(term);
    }, []);

    const handleFilter = useCallback(() => {
        if (TransactionHistoryInfo) {
            let results = [...TransactionHistoryInfo];
            results = filterCheck({ TransactionHistoryInfo: results }, filterCriteria);
            if (searchTerm) {
                results = results.filter(item =>
                    item.orderNumber.toLowerCase().includes(searchTerm.toLowerCase())
                );
            }
            if (filterCriteria.buyerName) {
                results = results.filter(item =>
                    item.buyerName.toLowerCase().includes(filterCriteria.buyerName.toLowerCase())
                );
            }
            if (filterCriteria.buyerId) {
                results = results.filter(item =>
                    item.buyerId.toLowerCase().includes(filterCriteria.buyerId.toLowerCase())
                );
            }
            if (filterCriteria.paymentAmount) {
                results = results.filter(item =>
                    item.paymentAmount.toString().includes(filterCriteria.paymentAmount)
                );
            }
            setFilteredHistory(results);
        }
    }, [TransactionHistoryInfo, filterCriteria, searchTerm]);

    return (
        <div className={transactionPaymentInfoContainer.class}>
            <AnimatePresence>
                <motion.div
                    key={location.pathname}
                    initial={{ opacity: 0, y: -1000 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 1000, x: 1000 }}
                    transition={{ duration: 0.4 }}
                >
                    <div className="text-2xl font-bold">건별 결제 조회</div>
                    <TransactionPaymentInfoHeader onSearch={handleSearch} />
                    <TransactionPaymentInfoFilter setFilterCriteria={setFilterCriteria} />
                    <Flex justify="end" className="mt-[12px] min-w-[1080px]">
                        <Button size="sm" bg="bg-P3_Red" color="text-white" onClick={handleFilter}>
                            조회하기
                        </Button>
                    </Flex>
                    {TransactionHistoryInfo && (
                        <TransactionPaymentInfoHistory
                            TransactionHistoryInfo={filteredHistory}
                        />
                    )}
                </motion.div>
            </AnimatePresence>
        </div>
    );
};