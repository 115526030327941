import React from 'react';

export const CloseBtnIcon = () => {
    return (
        <button >
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="40" height="40" fill="#76858C" />
                <g id="&#237;&#140;&#157;&#236;&#151;&#133;_&#237;&#145;&#156;&#236;&#132;&#164;&#236;&#160;&#149;_&#236;&#160;&#149;&#236;&#130;&#176;">
                    <rect x="-307" y="-40" width="387" height="639" rx="8" fill="white" />
                    <g id="Frame 487">
                        <g id="Frame 486">
                            <g id="Frame 485">
                                <g id="Frame 483">
                                    <g id="Frame 315">
                                        <g id="Group 31">
                                            <g id="Group">
                                                <path id="Vector" d="M30 10L10 30" stroke="#6C6D5D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path id="Vector_2" d="M10 10L30 30" stroke="#6C6D5D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </button>
    );
};
