import { tw } from '@shared/lib/Tailwindest';

export const withdrawHopeContainer = tw.style({
    display: 'flex',
    backgroundColor: 'bg-P3_Gray1',
    width: 'w-full',
    marginY: 'my-[30px]',
    paddingY: 'py-[32px]',
    paddingX: 'px-[56px]',
    borderRadius: 'rounded-[8px]',
    minWidth: 'min-w-[860px]',
});

export const inBoxContainer = tw.style({
    display: 'flex',
    justifyContent: 'justify-between',
    alignItems: 'items-center',
    backgroundColor: 'bg-P3_White',
    width: 'w-full',
    paddingY: 'py-[20px]',
    paddingX: 'px-[26px]',
    borderRadius: 'rounded-[8px]',
});

export const inBoxContainer2 = tw.style({
    display: 'flex',
    justifyContent: 'justify-between',
    backgroundColor: 'bg-P3_White',
    marginTop: 'mt-3',
    paddingY: 'py-[20px]',
    paddingX: 'px-[26px]',
    borderRadius: 'rounded-[8px]',

    ':focus-within': {
        borderColor: 'focus-within:border-P3_Red',
    },
});

export const dropdownUl = tw.style({
    position: 'absolute',
    backgroundColor: 'bg-P3_White',
    width: 'w-full',
    height: 'h-[175px]',
    paddingTop: 'pt-6',
    paddingX: 'px-8',
    borderColor: 'border-P3_Gray4',
    borderStyle: 'border-solid',
    borderWidth: 'border',
    borderRadius: 'rounded-lg',
    overflow: 'overflow-y-scroll',
});
