import React from 'react';
import { IDailyPaymentTotalListArrDto, IDailyPaymentTotalListDto } from '@entities/DailyPaymentInfo';
import { ExcelDownload } from '@features/Settlement';
import { Table, Tbody, Td, Th, Thead, Tr } from '@shared/ui';


export const TotalDailyPaymentInfoList = ({ totalListInfo }: IDailyPaymentTotalListArrDto) => {


    // 변수명 변경해야됨
    const calculateTotals = (items: IDailyPaymentTotalListDto[]) => {
        const totals = {
            totalPaymentCancletransaction: 0,
            totalPaymentCancleAmounts: 0,
            totalTransactions: 0,
            totalAmount: 0,
        };

        items.forEach((item) => {
            totals.totalPaymentCancletransaction += item.paymentCancleTransaction;
            totals.totalPaymentCancleAmounts += item.paymentCancleAmounts;
            totals.totalTransactions += item.transactions;
            totals.totalAmount += item.amounts;
        });
        return totals;
    };

    const totals = calculateTotals(totalListInfo);
    return (
        <div className='min-w-[1080px] '>
            <Table>
                <Thead >
                    <Tr className='h-[41px]'>
                        <Th colSpan={2} >합계</Th>
                        <Th colSpan={2}>결제</Th>
                        <Th colSpan={2}>취소</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    <Tr className="text-center bg-P3_Gray1">
                        <Td className="border-r">결제건+취소건</Td>
                        <Td className="border-r">결제액-취소액</Td>
                        <Td className="border-r">건</Td>
                        <Td className="border-r">금액</Td>
                        <Td className="border-r">건</Td>
                        <Td>금액</Td>
                    </Tr>
                    <Tr className="text-center">
                        <Td className="border-r min-w-[100px]">{totals.totalPaymentCancletransaction}</Td>
                        <Td className="border-r min-w-[100px]">{totals.totalPaymentCancleAmounts}</Td>
                        <Td className="border-r min-w-[100px]">{totals.totalTransactions}</Td>
                        <Td className="border-r min-w-[100px]">{totals.totalAmount}</Td>
                        <Td className="border-r min-w-[100px]">{totals.totalTransactions}</Td>
                        <Td className="border-r min-w-[100px]">{totals.totalAmount}</Td>
                    </Tr>
                </Tbody>
            </Table>
            <div className='mt-20 mb-5'>
                <ExcelDownload />
            </div>
        </div>
    );
};
