import {
    CalendarIcon,
    DropdownGrayIcon,
    Flex,
    HyphenIcon,
    Table,
    Tbody,
    Td,
    Th,
    Tr,
} from '@shared/ui';
import { CheckInput } from '@shared/ui/input/CheckInput';
import React, { useEffect, useRef, useState } from 'react';
import {
    customCheckboxInput,
    customDateInput,
    customLabel,
    dropdownUl,
} from './phfilter.styled';
import {
    IPaymentFilter,
    IWithdrawalAccount,
} from '@widgets/PaymentHistory/model/interface';
import { useDateChange, useDropdown, useOutsideClick } from '@shared/lib';
import { handleDateClick } from '@shared/lib/handler';
import { handleCriteriaChange } from '@features/PaymentHistory';

export const PHFilter: React.FC<IPaymentFilter> = ({ setFilterCriteria }) => {
    // 임시 더미 값 - 계좌 데이터는 따로 받아올 것으로 예상되어 임시 작성
    const withdrawalAccountMockData = [
        {
            account: '전체',
            bankName: '',
            accountNumber: '',
        },
        {
            account: '계좌 A',
            bankName: '국민',
            accountNumber: '123412-41-2421313',
        },
        {
            account: '계좌 B',
            bankName: '은행B',
            accountNumber: '987-654-321',
        },
        {
            account: '계좌 C',
            bankName: '은행C',
            accountNumber: '12-6542-3321',
        },
    ];

    const [paymentTypes, setPaymentTypes] = useState<string[]>(['withdraw']);
    const [account, setAccount] = useState<IWithdrawalAccount[]>([
        {
            account: '전체',
            bankName: '',
            accountNumber: '',
        },
    ]);
    const {
        dropdownIsOpen: isDropdownOpen,
        toggleDropdown,
        selectItem,
        selectedItem,
    } = useDropdown<IWithdrawalAccount>();
    const ref = useRef<HTMLDivElement>(null);

    useOutsideClick(ref, toggleDropdown, isDropdownOpen);

    const { startDate, setStartDate, endDate, setEndDate, changeHandler } =
        useDateChange();

    useEffect(() => {
        setFilterCriteria((prev) => ({
            ...prev,
            paymentType: paymentTypes,
            startDate: startDate,
            endDate: endDate,
        }));
    }, [paymentTypes, startDate, endDate, setFilterCriteria]);

    // 필터 업데이트 관리
    useEffect(() => {
        if (selectedItem) {
            setAccount([selectedItem]);
            setFilterCriteria((prev) => ({
                ...prev,
                withdrawalAccount: selectedItem,
            }));
        }
    }, [selectedItem, setFilterCriteria]);

    // 초기 항목으로 '전체' 선택, API 가져오면 로직 바꿔야 됌
    useEffect(() => {
        selectItem(withdrawalAccountMockData[0]);
    }, []);

    return (
        <Flex className="mt-[33px] min-w-[860px]">
            <Table>
                <Tbody>
                    <Tr className="border-P3_Gray4">
                        <Th className="text-start border-none">
                            <label className="py-[10px] pl-[20px] text-P3_Black">
                                조회기간
                            </label>
                        </Th>
                        <Flex items="center">
                            <Td className="border-none">
                                <div className="flex items-center ml-[15px]">
                                    <CalendarIcon />
                                    <input
                                        type="date"
                                        value={startDate}
                                        onChange={(e) =>
                                            setStartDate(e.target.value)
                                        }
                                        onClick={handleDateClick}
                                        className={`${customDateInput.class} hide-calendar-icon`}
                                    />
                                </div>
                            </Td>
                            <span className="flex justify-center">
                                <HyphenIcon />
                            </span>
                            <Td className="border-none">
                                <div className="flex ml-[22px]">
                                    <input
                                        type="date"
                                        value={endDate}
                                        onChange={(e) =>
                                            setEndDate(e.target.value)
                                        }
                                        onClick={handleDateClick}
                                        className={`${customDateInput.class} hide-calendar-icon`}
                                    />
                                </div>
                            </Td>
                            <Td className="border-none">
                                <label className="flex items-center">
                                    <CheckInput
                                        input_type="radio"
                                        input_name="query_period"
                                        input_value="yesterday"
                                        input_onChange={changeHandler}
                                    />
                                    <span className="ml-2 text-P3_Black">
                                        어제
                                    </span>
                                </label>
                            </Td>
                            <Td className="border-none">
                                <label className="flex items-center">
                                    <CheckInput
                                        input_type="radio"
                                        input_name="query_period"
                                        input_value="lastWeek"
                                        input_onChange={changeHandler}
                                    />
                                    <span className="ml-2 text-P3_Black">
                                        일주일
                                    </span>
                                </label>
                            </Td>
                            <Td className="border-none">
                                <label className="flex items-center">
                                    <CheckInput
                                        input_type="radio"
                                        input_name="query_period"
                                        input_value="lastMonth"
                                        input_onChange={changeHandler}
                                    />
                                    <span className="ml-2 text-P3_Black">
                                        지난달
                                    </span>
                                </label>
                            </Td>
                            <Td>
                                <label className="flex items-center">
                                    <CheckInput
                                        input_type="radio"
                                        input_name="query_period"
                                        input_value="thisMonth"
                                        input_onChange={changeHandler}
                                        defaultChecked
                                    />
                                    <span className="ml-2 font-medium text-P3_Black">
                                        이번 달
                                    </span>
                                </label>
                            </Td>
                        </Flex>
                    </Tr>
                    <Tr className="relative border-P3_Gray4">
                        <Th className="w-[14%] text-start border-none">
                            <label className="py-[10px] pl-[20px] font-medium text-P3_Black">
                                거래 유형
                            </label>
                        </Th>
                        <Td>
                            <div className="inline-block mr-5 ml-[15px]">
                                <label className={customLabel.class}>
                                    <input
                                        type="checkbox"
                                        id="withdraw"
                                        value="withdraw"
                                        checked={paymentTypes.includes(
                                            'withdraw',
                                        )}
                                        onChange={(e) =>
                                            handleCriteriaChange(
                                                e,
                                                setPaymentTypes,
                                            )
                                        }
                                        className={customCheckboxInput.class}
                                    />
                                    <span className="text-P3_Black">출금</span>
                                </label>
                            </div>
                            <div className="inline-block mr-5">
                                <label className={customLabel.class}>
                                    <input
                                        type="checkbox"
                                        id="charge"
                                        value="charge"
                                        checked={paymentTypes.includes(
                                            'charge',
                                        )}
                                        onChange={(e) =>
                                            handleCriteriaChange(
                                                e,
                                                setPaymentTypes,
                                            )
                                        }
                                        className={customCheckboxInput.class}
                                    />
                                    <span className="text-P3_Black">충전</span>
                                </label>
                            </div>
                            <div className="inline-block">
                                <label className={customLabel.class}>
                                    <input
                                        type="checkbox"
                                        id="cancel"
                                        value="cancel"
                                        checked={paymentTypes.includes(
                                            'cancel',
                                        )}
                                        onChange={(e) =>
                                            handleCriteriaChange(
                                                e,
                                                setPaymentTypes,
                                            )
                                        }
                                        className={customCheckboxInput.class}
                                    />
                                    <span className="font-medium text-P3_Black">
                                        결제 취소
                                    </span>
                                </label>
                            </div>
                            <div className="absolute right-0 top-2">
                                <p className="text-[14px] text-P3_Gray4">
                                    `정산`은 정산 내역에서 확인할 수 있어요.
                                </p>
                            </div>
                        </Td>
                    </Tr>
                    <Tr className="border-P3_Gray4">
                        <Th className="w-[14%] text-start border-none">
                            <span className="py-[10px] pl-[20px] font-medium text-P3_Black">
                                출금 계좌
                            </span>
                        </Th>
                        <Td>
                            <Flex className="ml-[15px]">
                                <button onClick={toggleDropdown}>
                                    <Flex items="center" className="relative">
                                        {account.map((a) => (
                                            <span
                                                key={a.accountNumber}
                                                className="mr-[15px] text-P3_Black"
                                            >
                                                {a.account}
                                            </span>
                                        ))}
                                        <DropdownGrayIcon />
                                    </Flex>
                                </button>
                                {isDropdownOpen && (
                                    <div className="sticky" ref={ref}>
                                        <ul className={dropdownUl.class}>
                                            {withdrawalAccountMockData.map(
                                                (a, index) => (
                                                    <li
                                                        key={index}
                                                        className={`font-medium mb-5 cursor-pointer ${
                                                            selectedItem &&
                                                            a.account ===
                                                                selectedItem.account &&
                                                            a.bankName ===
                                                                selectedItem.bankName &&
                                                            a.accountNumber ===
                                                                selectedItem.accountNumber
                                                                ? 'text-P3_Black'
                                                                : 'text-P3_Gray4'
                                                        }`}
                                                        onClick={() =>
                                                            selectItem({
                                                                account:
                                                                    a.account,
                                                                bankName:
                                                                    a.bankName,
                                                                accountNumber:
                                                                    a.accountNumber,
                                                            })
                                                        }
                                                    >
                                                        {a.account}
                                                    </li>
                                                ),
                                            )}
                                        </ul>
                                    </div>
                                )}
                            </Flex>
                        </Td>
                    </Tr>
                </Tbody>
            </Table>
        </Flex>
    );
};
