import { tw } from '@shared/lib/Tailwindest';


export const loginContainer = tw.style({
    maxWidth:"max-w-[620px]",
    width:"w-full",
    maxHeight: "max-h-[810px]",
    minHeight: "min-h-[810px]",
    backgroundColor: "bg-P3_White",
    borderRadius:"rounded-xl"
});

// className = "max-w-[620px] w-full max-h-[810px] min-h-[810px] bg-white rounded-xl

export const loginInput = tw.style({
    backgroundColor:"bg-[#F1F1EE]",
    width: "w-full",
    height: "h-full",
    borderRadius: "rounded-[10px]",
    padding: "p-3",
    borderWidth: "border",
    marginTop: "mt-2",
    boxSizing: "box-border",
    minHeight: "min-h-[25px]",
    ':focus': {
        outlineStyle: "focus:outline-none",
        ringWidth: "focus:ring-2",
        ringColor: "focus:ring-P3_Red",
    },
    ':hover': {
        ringWidth: "hover:ring-2",
        ringColor: "hover:ring-P3_Red",

    }
});

export const loginErrorMsg = tw.style({
    position: "absolute",
    color: "text-red-500",
    fontSize: "text-base",
    fontWeight:"font-bold",
    left: "left-0",
    top: "top-[160%]"
});
