import { withdrawAPI } from '@entities/Calendar/api/withdrawAPI';
import { IWithdrawDto } from '@entities/Calendar/model/interfaces';
import { useEffect, useState } from 'react';

export function useWithdraw() {
    const [withdraw, setWithdraw] = useState<IWithdrawDto | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await withdrawAPI();
                setWithdraw(response.data);
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        };

        fetchData();
    }, []);

    return withdraw;
}
