import { parse } from 'date-fns';
import React from 'react';

export const handleShowPicker = (
    inputRef: React.RefObject<HTMLInputElement>,
) => {
    if (inputRef.current) inputRef.current.showPicker();
};

export const handleInputChange = (
    setCurrentDate: React.Dispatch<React.SetStateAction<Date>>,
    e: React.ChangeEvent<HTMLInputElement>,
) => {
    const newDate = parse(e.target.value, 'yyyy-MM', new Date());
    setCurrentDate(newDate);
};
