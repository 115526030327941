import React, { useRef, useState } from 'react';

import { useModal, useOutsideClick } from '@shared/lib';
import {
    checkboxLayout,
    checkboxStatus,
    modalContainer,
    modalLayout,
    modalTransiton,
    selectBtn,
} from './tableSettingBtn.styled';
import { Button, CloseBtnIcon, Flex, SettingIcon } from '@shared/ui';

interface ITableSettingBtn {
    onSettingsChange: (checkedValues: string[]) => void;
}

export const TableSettingBtn = ({ onSettingsChange }: ITableSettingBtn) => {
    const modalRef = useRef<HTMLDivElement>(null);
    const { modalIsOpen, openModal, closeModal } = useModal();
    const [isClosing, setIsClosing] = useState<boolean>(false);

    useOutsideClick(
        modalRef,
        () => {
            setIsClosing(true);
            setTimeout(() => {
                setIsClosing(false);
                closeModal();
            }, 200);
        },
        modalIsOpen,
    );

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            setIsClosing(false);
            closeModal();
        }, 200);
    };

    const allValues = [
        '결제일시',
        '취소 요청 일시',
        '취소 완료 일시',
        '주문번호',
        '결제상태',
        '취소하기',
        '영수증',
        '결제액',
        '취소액',
        '구매자명',
        '구매자아이디',
    ];
    const [checkedItems, setCheckedItems] = useState<string[]>(allValues);
    const [changeItems, setChangeItems] = useState<string[]>(allValues);

    const handleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const { value, checked } = event.target;
        let updatedCheckedItems;

        if (checked) {
            updatedCheckedItems = [...checkedItems, value];
        } else {
            updatedCheckedItems = checkedItems.filter((item) => item !== value);
        }

        setCheckedItems(updatedCheckedItems);
        setChangeItems(updatedCheckedItems);
    };

    const applyHandler = () => {
        onSettingsChange(changeItems);
        closeModal();
    };

    const selectAll = () => {
        setCheckedItems(allValues);
        setChangeItems(allValues);
    };

    const deselectAll = () => {
        setCheckedItems([]);
        setChangeItems([]);
    };

    return (
        <div>
            <Button
                size="sm"
                bg="bg-P3_Black"
                className="mb-5"
                onClick={() => {
                    openModal();
                }}
                icon={<SettingIcon />}
            >
                <p className="content-center">표 설정</p>
            </Button>
            <div
                className={`${modalIsOpen && !isClosing ? 'opacity-100 ' : 'opacity-0'} ${modalTransiton.class}`}
            >
                {modalIsOpen && (
                    <div className={modalLayout.class}>
                        <div ref={modalRef} className={modalContainer.class}>
                            <div className="w-[100%] h-[100%]">
                                <Flex
                                    justify="between"
                                    className="mb-2"
                                    onClick={handleClose}
                                >
                                    <h1 className="text-xl flex items-end font-bold">
                                        표 설정
                                    </h1>
                                    <CloseBtnIcon />
                                </Flex>
                                <p className="text-lg font-medium mb-8 text-P3_Gray5">
                                    보고 싶은 항목을 선택해주세요
                                </p>
                                <Flex className="gap-5" justify="end">
                                    <button
                                        className={selectBtn.class}
                                        onClick={selectAll}
                                    >
                                        전체 선택
                                    </button>
                                    <button
                                        className={selectBtn.class}
                                        onClick={deselectAll}
                                    >
                                        전체 해제
                                    </button>
                                </Flex>
                                <div className={checkboxLayout.class}>
                                    <Flex items="center">
                                        <input
                                            type="checkbox"
                                            id="결제일시"
                                            value="결제일시"
                                            checked={checkedItems.includes(
                                                '결제일시',
                                            )}
                                            onChange={handleCheckboxChange}
                                            className={checkboxStatus.class}
                                        />
                                        <label htmlFor="결제일시">
                                            결제일시
                                        </label>
                                    </Flex>
                                    <Flex items="center">
                                        <input
                                            type="checkbox"
                                            id="취소 요청 일시"
                                            value="취소 요청 일시"
                                            checked={checkedItems.includes(
                                                '취소 요청 일시',
                                            )}
                                            onChange={handleCheckboxChange}
                                            className={checkboxStatus.class}
                                        />
                                        <label htmlFor="취소 요청 일시">
                                            취소 요청 일시
                                        </label>
                                    </Flex>
                                    <Flex items="center">
                                        <input
                                            type="checkbox"
                                            id="취소 완료 일시"
                                            value="취소 완료 일시"
                                            checked={checkedItems.includes(
                                                '취소 완료 일시',
                                            )}
                                            onChange={handleCheckboxChange}
                                            className={checkboxStatus.class}
                                        />
                                        <label htmlFor="취소 완료 일시">
                                            취소 완료 일시
                                        </label>
                                    </Flex>
                                    <Flex items="center">
                                        <input
                                            type="checkbox"
                                            id="주문번호"
                                            value="주문번호"
                                            checked={checkedItems.includes(
                                                '주문번호',
                                            )}
                                            onChange={handleCheckboxChange}
                                            className={checkboxStatus.class}
                                        />
                                        <label htmlFor="주문번호">
                                            주문번호
                                        </label>
                                    </Flex>
                                    <Flex items="center">
                                        <input
                                            type="checkbox"
                                            id="결제상태"
                                            value="결제상태"
                                            checked={checkedItems.includes(
                                                '결제상태',
                                            )}
                                            onChange={handleCheckboxChange}
                                            className={checkboxStatus.class}
                                        />
                                        <label htmlFor="결제상태">
                                            결제상태
                                        </label>
                                    </Flex>
                                    <Flex items="center">
                                        <input
                                            type="checkbox"
                                            id="취소하기"
                                            value="취소하기"
                                            checked={checkedItems.includes(
                                                '취소하기',
                                            )}
                                            onChange={handleCheckboxChange}
                                            className={checkboxStatus.class}
                                        />
                                        <label htmlFor="취소하기">
                                            취소하기
                                        </label>
                                    </Flex>
                                    <Flex items="center">
                                        <input
                                            type="checkbox"
                                            id="영수증"
                                            value="영수증"
                                            checked={checkedItems.includes(
                                                '영수증',
                                            )}
                                            onChange={handleCheckboxChange}
                                            className={checkboxStatus.class}
                                        />
                                        <label htmlFor="영수증">영수증</label>
                                    </Flex>
                                    <Flex items="center">
                                        <input
                                            type="checkbox"
                                            id="결제액"
                                            value="결제액"
                                            checked={checkedItems.includes(
                                                '결제액',
                                            )}
                                            onChange={handleCheckboxChange}
                                            className={checkboxStatus.class}
                                        />
                                        <label htmlFor="결제액">결제액</label>
                                    </Flex>
                                    <Flex items="center">
                                        <input
                                            type="checkbox"
                                            id="취소액"
                                            value="취소액"
                                            checked={checkedItems.includes(
                                                '취소액',
                                            )}
                                            onChange={handleCheckboxChange}
                                            className={checkboxStatus.class}
                                        />
                                        <label htmlFor="취소액">취소액</label>
                                    </Flex>
                                    <Flex items="center">
                                        <input
                                            type="checkbox"
                                            id="구매자명"
                                            value="구매자명"
                                            checked={checkedItems.includes(
                                                '구매자명',
                                            )}
                                            onChange={handleCheckboxChange}
                                            className={checkboxStatus.class}
                                        />
                                        <label htmlFor="구매자명">
                                            구매자명
                                        </label>
                                    </Flex>
                                    <Flex items="center">
                                        <input
                                            type="checkbox"
                                            id="구매자아이디"
                                            value="구매자아이디"
                                            checked={checkedItems.includes(
                                                '구매자아이디',
                                            )}
                                            onChange={handleCheckboxChange}
                                            className={checkboxStatus.class}
                                        />
                                        <label htmlFor="구매자아이디">
                                            구매자아이디
                                        </label>
                                    </Flex>
                                </div>
                                <Button
                                    size="lg"
                                    onClick={() => {
                                        applyHandler();
                                    }}
                                    className="w-full"
                                >
                                    적용하기
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
