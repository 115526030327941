import React from 'react';
import { ICalendarHeader } from '@shared/types';
import { prevMonth } from '@features/Calendar/model/prevMonthBtn';

export const PrevBtn = ({
    currentDate,
    setCurrentDate,
}: ICalendarHeader) => {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        prevMonth({ ...e, currentDate, setCurrentDate });
    };
    return (
        <button onClick={handleClick}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
            >
                <rect width="32" height="32" rx="4" fill="#27262B" />
                <path
                    d="M19 11L12.1683 15.7308C12.1152 15.7653 12.0728 15.807 12.0439 15.8533C12.0149 15.8996 12 15.9495 12 16C12 16.0505 12.0149 16.1004 12.0439 16.1467C12.0728 16.193 12.1152 16.2347 12.1683 16.2692L19 21"
                    stroke="#E34922"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </button>
    );
};
