import { AlarmIcon } from '@shared/ui';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const HeaderNotice = () => {
    const navigate = useNavigate();
    return (
        <button onClick={() => navigate('/notice')}>
           <AlarmIcon/>
        </button>
    );
};
