import React from 'react';

export const RefreshIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="18"
            viewBox="0 0 17 18"
            fill="none"
        >
            <path
                d="M13.1154 11.6667L15.423 11L16 13.6667"
                stroke="#E34922"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.4231 11C14.9106 12.6782 13.9797 14.1434 12.7476 15.2112C11.5156 16.2789 10.0377 16.9014 8.5 17C7.0786 17.0003 5.69156 16.495 4.52729 15.5528C3.36302 14.6105 2.47796 13.277 1.99231 11.7333"
                stroke="#E34922"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.88462 6.33337L1.57692 7.00004L1 4.33337"
                stroke="#E34922"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.5769 7C2.54614 3.93333 5.52306 1 8.49998 1C9.92831 1.00464 11.3204 1.51968 12.4855 2.4745C13.6506 3.42933 14.5316 4.7772 15.0077 6.33333"
                stroke="#E34922"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
