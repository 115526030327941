import { Flex } from '@shared/ui';
import React from 'react';

const TodayIndicator = () => {
    return (
        <Flex className="w-12 h-6 ml-2 bg-P3_Red rounded-full">
            <svg viewBox="0 -20 200 200" width="100%" height="100%">
                <ellipse cx="100" cy="80" rx="100" ry="90" fill="#E34922" />
                <text
                    x="50%"
                    y="45%"
                    dominantBaseline="middle"
                    textAnchor="middle"
                    fill="white"
                    fontSize="100"
                    fontWeight="bold"
                >
                    오늘
                </text>
            </svg>
        </Flex>
    );
};

export default TodayIndicator;
