import React from 'react';

import { IDayInfo, IDayDetails } from '@shared/types';
import { DayUtils } from '@features/Calendar/lib/utils/DayUtils';

const DayInfo = ({
    day,
    monthStart,
    onDayClick,
    children,
}: IDayInfo) => {
    const {
        formattedDate,
        keyDate,
        cloneDay,
        isSaturday,
        isSunday,
        isCurrentMonth,
        isTodayDate,
    } = DayUtils({ day, monthStart });

    const dayDetails: IDayDetails = {
        formattedDate,
        keyDate,
        cloneDay,
        isSaturday,
        isSunday,
        isCurrentMonth,
        isTodayDate,
    };

    return (
        <div
            key={keyDate}
            className={`w-[14.28%] mt-4 text-[16px] font-bold border-[#27262B] ${isCurrentMonth ? 'opacity-100 pointer-events-auto' : 'opacity-40 pointer-events-none'}`}
            onClick={() => onDayClick(cloneDay)}
        >
            {children(dayDetails)}
        </div>
    );
};

export default DayInfo;
