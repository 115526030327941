import React from 'react';
import 'tailwindcss/tailwind.css';
import { createBrowserRouter } from 'react-router-dom';
import { Home } from '@pages/Home';
import Layout from '@app/layout/Layout';
import { Settlement } from '@pages/Settlement';
import { DailyPaymentInfo } from '@pages/DailyPaymentInfo';
import { CompanyInfo } from '@pages/CompanyInfo';

import { Login } from '@pages/Login';
// import { Signup } from '@pages/Signup';
import { UsageInfo } from '@pages/UsageInfo';
import { PaymentHistory } from '@pages/PaymentHistory';
import { Withdraw } from '@pages/Withdraw';
import { TransactionPaymentInfo } from '@pages/TransactionPaymentInfo';
import { Charge } from '@pages/Charge';
import { Notice } from '@pages/Notice';

const CustomRouter = createBrowserRouter([
    {
        path: '/user',
        errorElement: <span>404</span>,
        children: [
            {
                path: 'login',
                element: <Login />,
            },
            // {
            //     path: 'signup',
            //     element: <Signup />,
            // },
        ],
    },
    {
        path: '/',
        element: <Layout />,
        errorElement: <span>404</span>,
        children: [
            {
                path: '',
                element: <Home />,
            },
            {
                path: 'notice',
                element: <Notice />,
            },
            {
                path: 'settlement',
                element: <Settlement />,
            },
            {
                path: 'payment-history',
                element: <PaymentHistory />,
            },
            {
                path: 'withdraw',
                element: <Withdraw />,
            },
            {
                path: 'charge',
                element: <Charge />,
            },
            {
                path: 'daily-payment-info',
                element: <DailyPaymentInfo />,
            },
            {
                path: 'transaction-payment-info',
                element: <TransactionPaymentInfo />,
            },
            {
                path: 'company-info',
                element: <CompanyInfo />,
            },
            {
                path: 'usage-info',
                element: <UsageInfo />,
            },
        ],
    },
]);

export default CustomRouter;
