import React from 'react';
import { IColorLabel } from '@shared/types';
import { Flex } from '../flex/Flex';



export const ColorLabel = ({
    label,
    color,
}: IColorLabel) => {
    return (
        <Flex items='center'>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
            >
                <circle cx="6" cy="6" r="6" className={`fill-${color}`} />
            </svg>
            <p className="ml-2 font-medium text-P3_Black">
                {label}
            </p>
        </Flex>
    );
};