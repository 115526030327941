import React, { useState } from 'react';
import { ExcelDownload, TableSettingBtn } from '@features/Settlement';
import {
    Flex,
    PaginatedList,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from '@shared/ui';
import {
    ISettlementHistoryArrDto,
    ISettlementHistoryDto,
} from '@entities/Settlement';
import { formatNumber } from '@shared/lib/FormatNumber';
import { handlePaginate } from '@shared/lib';

export const SettlementHistory = ({
    historyInfo,
}: ISettlementHistoryArrDto) => {
    const allValues = [
        '정산날짜',
        '결제+취소건수',
        '매출액',
        'PG수수료',
        'PG부가세',
        'PG수수료 합',
        '정산액',
        '출금액',
        '지갑잔고',
    ];
    const [visibleColumns, setVisibleColumns] = useState<string[]>(allValues);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const totalPages = Math.ceil(historyInfo.length / itemsPerPage);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const slicedHistoryInfo = historyInfo.slice(
        indexOfFirstItem,
        indexOfLastItem,
    );

    const tableComponent = (historyInfo: ISettlementHistoryDto[]) => {
        return (
            <Table>
                <Thead>
                    <Tr>
                        {visibleColumns.includes('정산날짜') && (
                            <Th className="font-medium">정산날짜</Th>
                        )}
                        {visibleColumns.includes('결제+취소건수') && (
                            <Th className="font-medium">결제+취소 건수</Th>
                        )}

                        {visibleColumns.includes('매출액') && (
                            <Th className="font-medium">매출액(A)</Th>
                        )}

                        {visibleColumns.includes('PG수수료') && (
                            <Th className="font-medium">PG수수료(B)</Th>
                        )}

                        {visibleColumns.includes('PG부가세') && (
                            <Th className="font-medium">PG부가세(C)</Th>
                        )}

                        {visibleColumns.includes('PG수수료 합') && (
                            <Th className="font-medium">
                                <p>PG수수료 합</p>
                                <p>(D)=(B+C)</p>
                            </Th>
                        )}

                        {visibleColumns.includes('정산액') && (
                            <Th className="font-medium">
                                <p>정산액</p>
                                <p>(E)=(A-D)</p>
                            </Th>
                        )}
                        {visibleColumns.includes('출금액') && (
                            <Th className="font-medium">출금액</Th>
                        )}
                        {visibleColumns.includes('지갑잔고') && (
                            <Th className="font-medium">지갑잔고</Th>
                        )}
                    </Tr>
                </Thead>
                <Tbody>
                    {historyInfo.map((data, index) => (
                        <Tr className="text-end text-[14px]" key={index}>
                            {visibleColumns.includes('정산날짜') && (
                                <Td className="text-center">
                                    {data.settlementDepositDate}
                                </Td>
                            )}
                            {visibleColumns.includes('결제+취소건수') && (
                                <Td>
                                    {formatNumber(
                                        data.paymentCancellationCount,
                                    )}
                                </Td>
                            )}

                            {visibleColumns.includes('매출액') && (
                                <Td>{formatNumber(data.historySalesAmount)}</Td>
                            )}

                            {visibleColumns.includes('PG수수료') && (
                                <Td>{formatNumber(data.pgFee)}</Td>
                            )}

                            {visibleColumns.includes('PG부가세') && (
                                <Td>{formatNumber(data.pgVat)}</Td>
                            )}

                            {visibleColumns.includes('PG수수료 합') && (
                                <Td>{formatNumber(data.totalPgFee)}</Td>
                            )}

                            {visibleColumns.includes('정산액') && (
                                <Td>
                                    {formatNumber(data.dailySettlementAmount)}
                                </Td>
                            )}
                            {visibleColumns.includes('출금액') && (
                                <Td>{formatNumber(data.withdrawAmount)}</Td>
                            )}
                            {visibleColumns.includes('지갑잔고') && (
                                <Td>{formatNumber(data.walletBalance)}</Td>
                            )}
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        );
    };

    return (
        <div className="min-w-[860px] overflow-auto">
            <Flex className="gap-[18px] mb-5">
                <TableSettingBtn onSettingsChange={setVisibleColumns} />
                <ExcelDownload />
            </Flex>
            <ul>{tableComponent(slicedHistoryInfo)}</ul>
            {visibleColumns.length > 0 && (
                <PaginatedList
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(pageNumber) =>
                        handlePaginate(pageNumber, totalPages, setCurrentPage)
                    }
                />
            )}
        </div>
    );
};
