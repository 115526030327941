import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface WalletState {
    balance: number;
    loading: boolean;
    error: string | null;
}

const initialState: WalletState = {
    balance: 0,
    loading: false,
    error: null,
};

const walletSlice = createSlice({
    name: 'walletBalance',
    initialState,
    reducers: {
        setBalance: (state, action: PayloadAction<number>) => {
            state.balance = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setError: (state, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
    },
});

export const { setBalance, setError, setLoading } = walletSlice.actions;
export default walletSlice.reducer;
