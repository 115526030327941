import React from 'react';
import { ITodaysDto } from '@entities/Calendar';
import { RenderCell } from '@features/Calendar';

export const CalendarCells = ({
    currentDate,
    todaysInfo,
}: ITodaysDto) => {
    return (
        <RenderCell
            currentDate={currentDate}
            todaysInfo={todaysInfo}
        />
    );
};
