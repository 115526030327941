import React, { PropsWithChildren } from 'react';

interface ITr extends PropsWithChildren {
    className?: string;
}

export const Tr = ({ children, className = '' }: ITr) => {
    return (
        <tr className={`${className} border-P3_Gray3 border-y`}>
            {children}
        </tr>
    );
};
