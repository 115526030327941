import React from 'react';
import { tw } from '@shared/lib/Tailwindest';
import { ButtonSize, IButton } from '@shared/types/button';
import { buttonFrame, buttonSize } from './button.styled';
import { Flex } from '../flex/Flex';


export const Button = ({
    children,
    size = 'lg',
    onClick,
    color,
    bg,
    className,
    disabled = false,
    icon,
    iconPosition = "left"
}: IButton) => {

    const buttonStyle = tw.mergeProps(
        buttonFrame.style,
        buttonSize[size as ButtonSize].style,
    );

    const colorClass = `${color}`;
    const bgClass = `${bg}`;

    const renderContent = () => (
        <>
            {iconPosition === 'left' && icon}
            {children}
            {iconPosition === 'right' && icon}
        </>
    );

    return (
        <button
            className={`${buttonStyle} ${colorClass} ${bgClass} ${className} `}
            onClick={onClick}
            disabled={disabled}
            style={{ backgroundColor: disabled ? '#E4E6E1' : '' }}
        >
            <Flex items='center' className='gap-[10px]'>
                {renderContent()}
            </Flex>
        </button>
    );
};
