import React from 'react';

export const AlarmIcon = () => {
    return (
        <svg
            width="20"
            height="23"
            viewBox="0 0 20 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.33337 20.2299C8.45343 20.7368 8.7231 21.1856 9.10012 21.5059C9.47713 21.8262 9.94019 22 10.4167 22C10.8932 22 11.3563 21.8262 11.7333 21.5059C12.1103 21.1856 12.38 20.7368 12.5 20.2299"
                fill="white"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect
                y="13.8552"
                width="20"
                height="3.46379"
                rx="1.73189"
                fill="white"
            />
            <path
                d="M2.5 7.85715C2.5 3.51777 6.01776 0 10.3571 0V0C14.6965 0 18.2143 3.51777 18.2143 7.85715V15.9334H2.5V7.85715Z"
                fill="white"
            />
        </svg>
    );
};
