import React from 'react';
import { withdrawContainer } from './withdraw.styled';
import { AnimatePresence, motion } from 'framer-motion';
import { WithdrawHope, WithdrawManagement } from '@widgets/Withdraw';
import { WalletBalance } from '@widgets/WalletBalance';
import { useSavedAccountInfo } from '@entities/Withdraw';

export const Withdraw = () => {
    const savedAccountInfo = useSavedAccountInfo();

    return (
        <div className={withdrawContainer.class}>
            <AnimatePresence>
                <motion.div
                    key={location.pathname}
                    initial={{ opacity: 0, y: 1000 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -100 }}
                    transition={{ duration: 0.4 }}
                >
                    <h1 className="text-P3_Black text-[22px] font-bold mb-5">
                        출금하기
                    </h1>
                    <WalletBalance />
                    {savedAccountInfo && (
                        <WithdrawHope
                            savedAccountInfo={savedAccountInfo.savedAccountInfo}
                        />
                    )}
                    {savedAccountInfo && (
                        <WithdrawManagement
                            savedAccountInfo={savedAccountInfo.savedAccountInfo}
                        />
                    )}
                </motion.div>
            </AnimatePresence>
        </div>
    );
};
