import { MIDAPI } from '@entities/CompanyInfo/api/MIDAPI';
import { useEffect, useState } from 'react';

export const useMID = () => {
    const [marketIDs, setMarketIDs] = useState<string[]>([]);

    useEffect(() => {
        const fetch = async () => {
            try {
                const res = await MIDAPI();
                console.log(res);
                setMarketIDs(res.data.marketID);
            } catch (error) {
                console.log(error);
            }
        };
        fetch();
    }, [marketIDs]);

    return marketIDs;
};
