import { ceoInfoAPI } from '@entities/CompanyInfo/api/ceoInfo.API';
import { ICeoInfoDto } from '@entities/CompanyInfo/model/interface';
import { useEffect, useState } from 'react';

export const useCeoInfo = () => {
    const [ceoInfo, setCeoInfo] = useState<ICeoInfoDto>();

    useEffect(() => {
        const fetch = async () => {
            try {
                const res = await ceoInfoAPI();
                console.log(res);
                setCeoInfo(res.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetch();
    });
    return ceoInfo;
};
