import { tw } from '@shared/lib/Tailwindest';

export const drawerContainer = tw.style({
    width: "w-full",
    height: "h-full",
    minWidth:"min-w-[250px]",
    paddingY: "py-[29px]",
    borderBottomRightRadius: "rounded-br-xl",
    backgroundColor:"bg-white"
});
