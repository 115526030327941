import { format, startOfWeek, addDays } from 'date-fns';
import { ko } from 'date-fns/locale';
import React from 'react';
import { ICurrentDate } from '../types';

export const WeekDay = ({
    currentDate,
    i,
}: ICurrentDate & { i: number }) => {
    const dateFormat = 'eee';
    const startDay = startOfWeek(currentDate);
    const endDay = addDays(startDay, i);
    const formattedEndDay = format(endDay, dateFormat, { locale: ko });

    return <div>{formattedEndDay}</div>;
};
