import { AxiosRequestHeaders, AxiosResponse } from 'axios';
import { ITodayDetailDto } from '../model/interfaces';

const detailData: ITodayDetailDto = {
    payments: 100,
    cancellations: 1,
    paymentsAmount: 2,
    cancelAmount: 3,
    PGCharge: 4,
    todaySettlementAmount: 5,
    thisMonthSettlementAmount: 6,
};

export const detailAPI = async (): Promise<AxiosResponse<ITodayDetailDto>> => {
    return {
        data: detailData,
        status: 200,
        statusText: 'OK',
        headers: {},
        config: {
            headers: {} as AxiosRequestHeaders,
        },
    };
};
