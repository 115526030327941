import { AxiosRequestHeaders, AxiosResponse } from 'axios';
import { IWithdrawDto } from '../model/interfaces';

const withdrawData: IWithdrawDto = {
    withdraw: 1000,
};

export const withdrawAPI = async (): Promise<AxiosResponse<IWithdrawDto>> => {
    return {
        data: withdrawData,
        status: 200,
        statusText: 'OK',
        headers: {},
        config: {
            headers: {} as AxiosRequestHeaders,
        },
    };
};
