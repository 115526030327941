import React, { useState } from 'react';
import { dailyPaymentInfoContainer } from './dailyPaymentInfo.styled';
import {
    DailyPaymentInfoHistory,
    DailyPaymentInfoFilter,
} from '@widgets/DailyPaymentInfo';
import { TotalDailyPaymentInfoList } from '@widgets/DailyPaymentInfo';
import { IDailyPaymentInfoHistoryDto, useHistoryInfo, useTotalList } from '@entities/DailyPaymentInfo';
import { Button, Flex } from '@shared/ui';
import { AnimatePresence, motion } from 'framer-motion';
import { filterCheck } from '@features/DailyPaymentInfo';



export const DailyPaymentInfo = () => {

    const [filteredHistory, setFilteredHistory] = useState<
        IDailyPaymentInfoHistoryDto[]
    >([]);

    const [filterCriteria, setFilterCriteria] = useState({
        startDate: '',
        endDate: '',
    });
    const DailyHistoryInfo = useHistoryInfo();
    const totalListInfo = useTotalList();
    return (
        <div className={dailyPaymentInfoContainer.class}>
            <AnimatePresence>
                <motion.div
                    key={location.pathname}
                    initial={{ opacity: 0, y: 1000 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 0 }}
                    transition={{ duration: 0.4 }}
                >
                    <>
                        <div className="text-2xl font-bold text-P3_Black">일자별 결제 조회</div>
                        <DailyPaymentInfoFilter
                            setFilterCriteria={setFilterCriteria} />
                        <Flex justify="end" className="mb-11 min-w-[1080px] ">
                            <Button
                                size="sm"
                                bg="bg-P3_Red"
                                color="text-white"
                                onClick={() => {
                                    if (DailyHistoryInfo) {
                                        filterCheck(
                                            DailyHistoryInfo,
                                            filterCriteria,
                                            setFilteredHistory,
                                        );
                                    }
                                }}
                            >
                                조회하기
                            </Button>
                        </Flex>

                        <TotalDailyPaymentInfoList totalListInfo={totalListInfo?.totalListInfo || []} />

                        {DailyHistoryInfo && (
                            <DailyPaymentInfoHistory
                                DailyHistoryInfo={
                                    filteredHistory
                                }
                            />
                        )}
                    </>
                </motion.div>
            </AnimatePresence>
        </div>
    );
};
