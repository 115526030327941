import { format } from 'date-fns';
import { ICurrentDate } from '@shared/types';

// 매출액
export const formatMonthSales = ({ currentDate }: ICurrentDate) => {
    return format(currentDate, 'M월');
};
// 월 입금 정산액
export const formatMonthDeposit = ({ currentDate }: ICurrentDate) => {
    return format(currentDate, 'M월');
};
