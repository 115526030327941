import React from 'react';
import { WeekDay } from '@shared/model/weekDay';
import { ICurrentDate } from '@shared/types';
import { Flex } from '@shared/ui';

export const WeekDays = ({ currentDate }: ICurrentDate) => {
    const days = [];

    for (let i = 0; i < 7; i++) {
        days.push(
            <Flex justify="start" key={i} className="w-[14.28%] mt-10">
                <WeekDay currentDate={currentDate} i={i} />
            </Flex>,
        );
    }

    return <Flex className="font-bold text-P3_Black">{days}</Flex>;
};
