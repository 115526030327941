import { AxiosRequestHeaders, AxiosResponse } from 'axios';
import { IWithdrawalAccountArrDto } from '../model/interface';

const withdrawalAccountMockData = {
    savedAccountInfo: [
        {
            account: '전체',
            bankName: '국민',
            bankNumber: '123412-41-2421313',
            savedDate: '2024-07-15',
        },
        {
            account: '계좌 A',
            bankName: '국민',
            bankNumber: '123412-41-2421313',
            savedDate: '2024-07-15',
        },
        {
            account: '계좌 B',
            bankName: '신한',
            bankNumber: '987-654-321',
            savedDate: '2024-07-15',
        },
        {
            account: '계좌 C',
            bankName: '농협',
            bankNumber: '12-6542-3321',
            savedDate: '2024-07-15',
        },
        {
            account: '계좌 D',
            bankName: '카카오뱅크',
            bankNumber: '12-6542-3321',
            savedDate: '2024-07-15',
        },
    ],
};

export const savedAccountAPI = async (): Promise<
    AxiosResponse<IWithdrawalAccountArrDto>
> => {
    return {
        data: withdrawalAccountMockData,
        status: 200,
        statusText: 'OK',
        headers: {},
        config: {
            headers: {} as AxiosRequestHeaders,
        },
    };
};
