import { IDrawerProps } from '@shared/types';
import React from 'react';
import { FiMenu } from "react-icons/fi";


export const HeaderMenu = ({ toggleDrawer }: IDrawerProps) => {
    return (
        <button
            className={`h-6 w-6`}
            onClick={toggleDrawer}
        >
            <FiMenu className='h-6 w-6 text-white' />
        </button>
    );
};
