import {
    ISettlementHistoryArrDto,
    ISettlementHistoryDto,
} from '@entities/Settlement';
import React from 'react';

interface IFilterCriteria {
    startDate: string;
    endDate: string;
}

export const filterCheck = (
    historyInfo: ISettlementHistoryArrDto,
    filterCriteria: IFilterCriteria,
    setFilteredHistory: React.Dispatch<
        React.SetStateAction<ISettlementHistoryDto[]>
    >,
) => {
    if (!historyInfo) return;

    const isDateInRange = (item: ISettlementHistoryDto) => {
        const date = item.settlementDepositDate;
        return (
            filterCriteria.startDate <= date && date <= filterCriteria.endDate
        );
    };

    const filtered = historyInfo.historyInfo.filter((item) => {
        return isDateInRange(item);
    });
    setFilteredHistory(filtered);
};
