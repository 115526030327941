import { tw } from "@shared/lib/Tailwindest";

export const menuContainer = tw.style({
    position: 'relative',
    display: 'inline-block',
});

export const menuButton = tw.style({
    backgroundColor: 'bg-[#3182ce]',
    color: 'text-white',
    padding: 'p-2',
    paddingX: 'px-4',
    borderStyle: 'border-none',
    borderRadius: 'rounded-lg',
    cursor: 'cursor-pointer',
    display: 'flex',
    alignItems: 'items-center',
});

export const menuList = tw.style({
    position: 'absolute',
    top: 'top-full',
    left: 'left-0',
    backgroundColor: 'bg-white',
    borderWidth: 'border',
    borderColor: 'border-gray-300',
    borderRadius: 'rounded-lg',
    // marginTop: 'mt-2',
    boxShadow: 'shadow-md',
    listStyleType: 'list-none',
    paddingY: 'py-2',
    width: 'w-48',
});

export const menuItem = tw.style({
    paddingY: 'py-2',
    paddingX: 'px-4',
    cursor: 'cursor-pointer',
    whitespace: 'whitespace-nowrap',
});
