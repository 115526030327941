import React from 'react';

export const AlarmBadgeIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
        >
            <path
                d="M18.3334 29.2299C18.4534 29.7367 18.7231 30.1855 19.1001 30.5059C19.4771 30.8262 19.9402 31 20.4167 31C20.8932 31 21.3563 30.8262 21.7333 30.5059C22.1103 30.1855 22.38 29.7367 22.5 29.2299"
                fill="white"
            />
            <path
                d="M18.3334 29.2299C18.4534 29.7367 18.7231 30.1855 19.1001 30.5059C19.4771 30.8262 19.9402 31 20.4167 31C20.8932 31 21.3563 30.8262 21.7333 30.5059C22.1103 30.1855 22.38 29.7367 22.5 29.2299H18.3334Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect
                x="10"
                y="22.8552"
                width="20"
                height="3.46379"
                rx="1.73189"
                fill="white"
            />
            <path
                d="M12.5 16.8571C12.5 12.5178 16.0178 9 20.3571 9C24.6965 9 28.2143 12.5178 28.2143 16.8571V24.9334H12.5V16.8571Z"
                fill="white"
            />
            <circle
                cx="27"
                cy="12"
                r="6.5"
                fill="#FF3300"
                stroke="#27262B"
                strokeWidth="3"
            />
        </svg>
    );
};
