import React from 'react';
import { Table, Tbody, Td, Th, Thead, Tooltip, Tr } from '@shared/ui';
import { ISettlementTotalListArrDto } from '@entities/Settlement';
import { formatNumber } from '@shared/lib';
import { calculateTotals } from '@features/Settlement';

export const TotalSettlementList = ({
    totalListInfo,
}: ISettlementTotalListArrDto) => {
    const totals = calculateTotals(totalListInfo);

    const formattedTotals = {
        totalPaymentCancellationCount: formatNumber(
            totals.totalPaymentCancellationCount,
        ),
        totalSalesAmount: formatNumber(totals.totalSalesAmount),
        totalPgFee: formatNumber(totals.totalPgFee),
        totalSettlementAmount: formatNumber(totals.totalSettlementAmount),
        totalWithdrawalAmount: formatNumber(totals.totalWithdrawalAmount),
    };

    return (
        <div className="min-w-[860px] my-10">
            <Table>
                <Thead>
                    <Tr>
                        <Th className="font-medium">결제+취소 건수</Th>
                        <Th className="font-medium">매출액</Th>
                        <Th className="font-medium">PG수수료</Th>
                        <Th className="font-medium">
                            <div className="flex justify-center items-center gap-1">
                                <p>정산액</p>
                                <Tooltip
                                    content="매출액 - PG수수료(부가세포함)"
                                    direction="right"
                                />
                            </div>
                        </Th>
                        <Th className="font-medium">
                            <div className="flex justify-center items-center gap-1">
                                <p>출금액</p>
                                <Tooltip
                                    content="지갑에서 출금한 총액"
                                    direction="left"
                                />
                            </div>
                        </Th>
                    </Tr>
                </Thead>
                <Tbody>
                    <Tr className="text-center">
                        <Td className="border-r">
                            {formattedTotals.totalPaymentCancellationCount}
                        </Td>
                        <Td className="border-r">
                            {formattedTotals.totalSalesAmount}
                        </Td>
                        <Td className="border-r">
                            {formattedTotals.totalPgFee}
                        </Td>
                        <Td className="border-r">
                            {formattedTotals.totalSettlementAmount}
                        </Td>
                        <Td>{formattedTotals.totalWithdrawalAmount}</Td>
                    </Tr>
                </Tbody>
            </Table>
            <div className="flex justify-end text-P3_Gray4 mt-2">
                (부가세포함)
            </div>
        </div>
    );
};
