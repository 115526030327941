import { AxiosRequestHeaders, AxiosResponse } from 'axios';
import { ISettlementDto } from '../model/interfaces';

const settlementData: ISettlementDto = {
    settlement: 1000,
};

export const settlementAPI = async (): Promise<
    AxiosResponse<ISettlementDto>
> => {
    return {
        data: settlementData,
        status: 200,
        statusText: 'OK',
        headers: {},
        config: {
            headers: {} as AxiosRequestHeaders,
        },
    };
};
