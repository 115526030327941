import { ITodayDetailDto, detailAPI } from '@entities/Calendar';
import { useEffect, useState } from 'react';

export function useDetail() {
    const [detail, setDetail] = useState<ITodayDetailDto | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await detailAPI();
                setDetail(response.data);
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        };

        fetchData();
    }, []);

    return detail;
}
