import React, { ChangeEvent, useState } from 'react';
import { searchInput } from './orderSearchBar.styled';
import { FaSearch } from 'react-icons/fa';
import { Flex } from '@shared/ui';
import { ISearchBar } from '@shared/types/searchBarInterface';

export const OrderSearchBar = ({ onSearch }: ISearchBar) => {
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchTerm(value);
            onSearch(value);
    };

    return (
        <Flex items='center' className="relative">
            <div className="absolute left-4 top-3 hidden md:block" >
                <FaSearch />
            </div>
            <input
                placeholder='주문번호를 입력해주세요'
                className={`${searchInput.class} md:pl-10`}
                type="text"
                value={searchTerm}
                onChange={handleSearch}
            />
        </Flex>
    );
};