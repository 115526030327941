import React, { useRef, useState } from 'react';
import { useModal, useOutsideClick } from '@shared/lib';
import {
    modalContainer,
    modalLayout,
    modalTransiton,
} from './cancelBtn.styled';
import { Button, Table, Td, Th, Tr, Flex } from '@shared/ui';
import { CheckInput } from '@shared/ui/input/CheckInput';

export const CancelBtn = () => {
    const modalRef = useRef<HTMLDivElement>(null);
    const { modalIsOpen, openModal, closeModal } = useModal();
    const [isClosing, setIsClosing] = useState<boolean>(false);
    const [partialCancel, setPartialCancel] = useState<boolean>(false);
    const [cancelAmount, setCancelAmount] = useState<string>('')

    useOutsideClick(
        modalRef,
        () => {
            setIsClosing(true);
            setTimeout(() => {
                setIsClosing(false);
                closeModal();
            }, 200);
        },
        modalIsOpen,
    );

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            setIsClosing(false);
            closeModal();
        }, 200);
    }

    const handlePartialCancelChange = (isPartial: boolean) => {
        setPartialCancel(isPartial);
        if (!isPartial) {
            setCancelAmount('');
        }
    }

    const handleCancelAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            setCancelAmount(value);
        }
    }

    const isCancelDisabled = partialCancel && cancelAmount === '';

    return (
        <>
            <Button
                size="xs"
                bg="bg-P3_Black"
                color="text-white"
                className='min-w-14'
                onClick={() => {
                    openModal();
                }}
            >
                <p>취소하기</p>
            </Button>
            <div
                className={`${modalIsOpen && !isClosing ? 'opacity-100 ' : 'opacity-0'} ${modalTransiton.class}`}
            >
                {modalIsOpen && (
                    <div className={modalLayout.class}>
                        <div ref={modalRef} className={modalContainer.class}>
                            <div className="w-[100%] h-[100%]">
                                <Flex
                                    justify="between"
                                    className="mb-5"
                                    onClick={handleClose}
                                >
                                    <h1 className="text-xl flex items-end font-bold">
                                        취소하기
                                    </h1>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                        <path d="M30 10L10 30" stroke="#6C6D5D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M10 10L30 30" stroke="#6C6D5D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </Flex>
                                <Flex className='gap-4'>
                                    <Flex items='center'>
                                        <CheckInput
                                            input_type="radio"
                                            input_name="cancel"
                                            input_value="full"
                                            defaultChecked
                                            onChange={() => handlePartialCancelChange(false)}
                                        />
                                        <p className='ml-2 font-medium'> 전체취소</p>
                                    </Flex>
                                    <Flex items='center'>
                                        <CheckInput
                                            input_type="radio"
                                            input_name="cancel"
                                            input_value="partial"
                                            onChange={() => handlePartialCancelChange(true)}
                                        />
                                        <p className='ml-2 font-medium'> 부분취소</p>
                                    </Flex>
                                </Flex>
                                <Table className='h-full mt-5'>
                                    <Tr>
                                        <Th className='w-[114px]'>주문번호</Th>
                                        <Td className="px-4 font-medium ">
                                            5000
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Th>구매자</Th>
                                        <Td className="px-4 font-medium ">
                                            5000
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Th>구매상품</Th>
                                        <Td className="px-4 font-medium ">
                                            5000000원
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Th>결제일시</Th>
                                        <Td className="px-4 font-medium ">
                                            400000원
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Th>결제액</Th>
                                        <Td className="px-4 font-medium ">
                                            55555555
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Th>취소가능액</Th>
                                        <Td className="px-4 font-medium ">
                                            50000000
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Th>취소액</Th>
                                        <Td className="px-4 font-medium ">{partialCancel ?
                                            (<input
                                                type="text"
                                                value={cancelAmount}
                                                onChange={handleCancelAmountChange}
                                                className='outline-none'
                                            />) : (
                                                50000000)}
                                        </Td>
                                    </Tr>
                                </Table>
                                <Button
                                    size='lg'
                                    onClick={() => {
                                        closeModal();
                                    }}
                                    className="w-full mt-5"
                                    disabled={isCancelDisabled}
                                >
                                    취소요청
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
