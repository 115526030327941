import { useModal, useOutsideClick } from '@shared/lib';
import { Button, Flex } from '@shared/ui';
import React, { useEffect, useRef, useState } from 'react';
import {
    modalContainer,
    modalLayout,
    modalTransiton,
} from './WithdrawBtn.styled';
import { CloseBtnIcon } from '@shared/ui/icon/CloseBtnIcon';
import { useSelector } from 'react-redux';
import { RootState } from '@app/AppStore';
import { useNavigate } from 'react-router-dom';

interface IWithdrawBtn {
    reqAmount?: number;
    resetState: () => void;
}

export const WithdrawBtn = ({ resetState, reqAmount }: IWithdrawBtn) => {
    const modalRef = useRef<HTMLDivElement>(null);
    const { modalIsOpen, openModal, closeModal } = useModal();
    const [isClosing, setIsClosing] = useState<boolean>(false);
    const { balance } = useSelector((state: RootState) => state.wallet);
    const [amount, setAmount] = useState<number>();
    const navigate = useNavigate();

    useOutsideClick(
        modalRef,
        () => {
            setIsClosing(true);
            resetState();
            setTimeout(() => {
                setIsClosing(false);
                closeModal();
            }, 200);
        },
        modalIsOpen,
    );

    const handleClose = () => {
        setIsClosing(true);
        resetState();
        setTimeout(() => {
            setIsClosing(false);
            closeModal();
        }, 200);
    };

    const withdrawHandler = () => {
        // 지갑 잔액보다 요청 금액이 많으면 출금실패
        if (!amount || amount > balance) {
            alert('Test 출금실패요, 출금실패 알림으로 바꿀 예정');
            // 홈으로 네이게이트, 중요알림에 출금실패 알림 뜨게
            navigate('/');
            // 중요알림에 출금실패 알림 뜨는 함수 -> 알림 페이지에도 출금실패 스택 쌓여야함
        } else {
            openModal();
        }
    };

    useEffect(() => {
        console.log(reqAmount);
        if (reqAmount) {
            setAmount(reqAmount);
        }
    }, [amount, setAmount, reqAmount]);

    return (
        <>
            <Button size="md" className="ml-[90px]" onClick={withdrawHandler}>
                출금하기
            </Button>
            <div
                className={`${modalIsOpen && !isClosing ? 'opacity-100 ' : 'opacity-0'} ${modalTransiton.class}`}
            >
                {modalIsOpen && (
                    <div className={modalLayout.class}>
                        <div ref={modalRef} className={modalContainer.class}>
                            <div className="w-[100%] h-[100%]">
                                <Flex justify="between" className="mb-5">
                                    <h1 className="text-xl flex items-end font-bold">
                                        출금액
                                    </h1>
                                    <div
                                        className="cursor-pointer"
                                        onClick={handleClose}
                                    >
                                        <CloseBtnIcon />
                                    </div>
                                </Flex>
                                <div className="flex-col text-P3_Gray5 text-lg mb-4">
                                    <p>신청해주신 계좌로 출금 신청이</p>
                                    <p>완료되었어요.</p>
                                    <br />
                                    <p>(최대 3시간 소요)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
