import { tw } from '@shared/lib/Tailwindest';

export const settlementContainer = tw.style({
    width: 'w-[100%]',
    height: 'h-[100%]',
    backgroundColor: 'bg-P3_White',
    paddingY: 'py-[28px]',
    paddingX: 'px-[25px]',
    overflow: 'overflow-auto',
});
