import React from 'react';
import { ISpotlightBtn } from '@shared/types';

export const SpotlightBtn = ({
    viewValue,
    currentView,
    setView,
    children,
}: ISpotlightBtn) => {
    return (
        <>
            <button
                onClick={() => setView(viewValue)}
                className={
                    currentView === viewValue
                        ? 'min-w-[9%] text-lg font-semibold text-P3_Red py-3 px-[7px] border-b-2 border-current'
                        : 'min-w-[9%] text-lg font-semibold text-P3_Gray5 py-3 px-[7px] border-b-2 border-P3_Gray5'
                }
            >
                {children}
            </button>
        </>
    );
};
