import { ITooltip } from '@shared/types';
import React, { useState } from 'react';
import { tooltipContent } from './tooltip.styled';
import { InfoIcon } from '../icon';

const positionClasses = {
    top: 'bottom-full mb-1 translate-x-[-50%] left-1/2',
    right: 'min-w-[200px] rounded-t rounded-br left-full translate-y-[-100%] top-1/2 z-10',
    bottom: 'top-full mt-1 translate-x-[-50%] left-1/2',
    left: 'min-w-[200px] rounded-t rounded-bl right-full translate-y-[-100%] top-1/2 z-10',
};

export const Tooltip = ({
    children,
    content,
    direction = 'top',
    delay = 250,
    className,
}: ITooltip) => {
    const [active, setActive] = useState(false);
    let timeout: NodeJS.Timeout;

    const toggleTip = (show: boolean) => {
        clearTimeout(timeout);
        if (show) {
            timeout = setTimeout(() => setActive(true), delay);
        } else {
            setActive(false);
        }
    };

    return (
        <div className={`${className} relative inline-block rounded-t-lg`}>
            <div
                onMouseEnter={() => toggleTip(true)}
                onMouseLeave={() => toggleTip(false)}
                className="flex justify-center items-center"
            >
                {children}
                <InfoIcon />
            </div>
            {active && (
                <div
                    className={`${tooltipContent.class} ${positionClasses[direction]}`}
                    style={{
                        opacity: active ? 1 : 0,
                        pointerEvents: 'none',
                        maxWidth: '200px',
                        textAlign: 'center',
                    }}
                >
                    {content}
                </div>
            )}
        </div>
    );
};
