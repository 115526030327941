import React from 'react';
import { formatMonthDeposit } from '@features/Calendar/model/salesAmount.model';
import { ITotalAmountDto } from '@entities/Calendar';
import { Flex, Tooltip } from '@shared/ui';

export const MonthDepositAmount = ({
    currentDate,
    totalAmountInfo,
}: ITotalAmountDto) => {
    const monthText = formatMonthDeposit({ currentDate });
    return (
        <Flex col className="gap-3">
            <Flex items="center" className="gap-2">
                <span className="text-P3_Black font-semibold">
                    {monthText} 정산액
                </span>
                <Tooltip
                    content="매출액 - PG수수료(부가세포함)"
                    direction="right"
                />
            </Flex>
            <span className="font-semibold text-xl">
                {' '}
                {totalAmountInfo
                    ? `${totalAmountInfo.monthSettlementAmount.toLocaleString()} 원`
                    : '데이터 로딩 중...'}
            </span>
        </Flex>
    );
};
