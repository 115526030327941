import { AxiosRequestHeaders, AxiosResponse } from 'axios';
import { ISettlementTotalListArrDto } from '../model/interfaces';

const totalListInfoData = {
    totalListInfo: [
        {
            paymentCancleAmount: 11111,
            salesAmount: 77777777,
            PGFeeAmount: 3333333,
            settlementAmount: 222222,
            withdrawalAmount: 900,
        },
        {
            paymentCancleAmount: 11111,
            salesAmount: 77777777,
            PGFeeAmount: 3333333,
            settlementAmount: 222222,
            withdrawalAmount: 900,
        },
        {
            paymentCancleAmount: 11111,
            salesAmount: 77777777,
            PGFeeAmount: 3333333,
            settlementAmount: 222222,
            withdrawalAmount: 900,
        },
    ],
};

export const totalListAPI = async (): Promise<
    AxiosResponse<ISettlementTotalListArrDto>
> => {
    return {
        data: totalListInfoData,
        status: 200,
        statusText: 'OK',
        headers: {},
        config: {
            headers: {} as AxiosRequestHeaders,
        },
    };
};
