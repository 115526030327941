import React from 'react';

export const WarningIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="14"
            viewBox="0 0 16 14"
            fill="none"
        >
            <path
                d="M9.09589 0.586157C8.99256 0.409675 8.83557 0.261568 8.64221 0.158146C8.44885 0.0547241 8.22662 0 8 0C7.77338 0 7.55115 0.0547241 7.35779 0.158146C7.16443 0.261568 7.00744 0.409675 6.90411 0.586157L0.131722 12.4377C0.0372653 12.6018 -0.00770579 12.7842 0.00107906 12.9677C0.00986391 13.1512 0.0721131 13.3297 0.181915 13.4862C0.291717 13.6427 0.445426 13.7721 0.628445 13.862C0.811464 13.9519 1.01772 13.9994 1.22762 14H14.7724C14.9823 13.9994 15.1885 13.9519 15.3716 13.862C15.5546 13.7721 15.7083 13.6427 15.8181 13.4862C15.9279 13.3297 15.9901 13.1512 15.9989 12.9677C16.0077 12.7842 15.9627 12.6018 15.8683 12.4377L9.09589 0.586157Z"
                fill="#27262B"
            />
            <path
                d="M7.99634 4.84192V8.07417"
                stroke="#E34922"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.99649 11.8447C8.33636 11.8447 8.61187 11.6037 8.61187 11.3063C8.61187 11.0089 8.33636 10.7678 7.99649 10.7678C7.65662 10.7678 7.3811 11.0089 7.3811 11.3063C7.3811 11.6037 7.65662 11.8447 7.99649 11.8447Z"
                stroke="#E34922"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
