import React, { useState } from 'react';
import { noticeContainer } from './notice.styled';
import { AnimatePresence, motion } from 'framer-motion';
import { Flex, SpotlightBtn, Text } from '@shared/ui';
import { NoticeCancel, NoticeCharge, NoticeWithdraw } from '@widgets/Notice';

export const Notice = () => {
    const [view, setView] = useState<string>('cancel');
    return (
        <div className={noticeContainer.class}>
            <AnimatePresence>
                <motion.div
                    key={location.pathname}
                    initial={{ opacity: 0, y: 1000 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -100 }}
                    transition={{ duration: 0.4 }}
                >
                    <h1 className="text-P3_Black text-[22px] font-bold mb-5">
                        알림
                    </h1>
                    <Flex className="gap-8">
                        <SpotlightBtn
                            viewValue="cancel"
                            currentView={view}
                            setView={setView}
                        >
                            결제 취소
                        </SpotlightBtn>
                        <SpotlightBtn
                            viewValue="charge"
                            currentView={view}
                            setView={setView}
                        >
                            충전
                        </SpotlightBtn>
                        <SpotlightBtn
                            viewValue="withdraw"
                            currentView={view}
                            setView={setView}
                        >
                            출금
                        </SpotlightBtn>
                    </Flex>
                    {view === 'cancel' && (
                        <>
                            <NoticeCancel />
                        </>
                    )}
                    {view === 'charge' && (
                        <>
                            <NoticeCharge />
                        </>
                    )}
                    {view === 'withdraw' && (
                        <>
                            <NoticeWithdraw />
                        </>
                    )}
                    <Text size="md" className="flex justify-center mt-8">
                        알림은 30일 이후 순차적으로 지워져요.
                    </Text>
                </motion.div>
            </AnimatePresence>
        </div>
    );
};
