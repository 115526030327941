import React from 'react';

export const DropdownGrayIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                d="M6 7.97693L9.78462 12.8567C9.81224 12.8947 9.8456 12.9249 9.88265 12.9456C9.91969 12.9663 9.95963 12.9769 10 12.9769C10.0404 12.9769 10.0803 12.9663 10.1174 12.9456C10.1544 12.9249 10.1878 12.8947 10.2154 12.8567L14 7.97693"
                stroke="#93948B"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
