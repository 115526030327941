import React, { PropsWithChildren } from 'react';

interface ITh extends PropsWithChildren {
    className?: string;
    colSpan?: number;
    rowSpan?: number;
}

export const Th = ({
    children,
    className = '',
    colSpan,
    rowSpan,
}: ITh) => {
    return (
        <th
            className={`${className} bg-P3_Gray1 border-r border-P3_Gray3 font-medium last:border-none text-P3_Black`}
            colSpan={colSpan}
            rowSpan={rowSpan}
        >
            {children}
        </th>
    );
};
