import React from 'react';
import { HeaderMenu } from './ui/menu/HeaderMenu';
import { HeaderNotice } from './ui/notice/HeaderNotice';
import { Flex } from '@shared/ui';
import { IDrawerProps } from '@shared/types';


const NavigationBar = ({ toggleDrawer }: IDrawerProps) => {
    return (
        <Flex
            className="fixed w-[100%] h-14 py-2 px-5 bg-P3_Black z-10"
            justify="between"
            items="center"
        >
            <div className="w-6 h-6">
                <HeaderMenu toggleDrawer={toggleDrawer} />
            </div>
            <div className="w-6 h-6">
                <HeaderNotice />
            </div>
        </Flex>
    );
};

export default NavigationBar;
