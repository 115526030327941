import { useEffect, useState } from 'react';
import { IPaymentHistoryArrDto } from '../model/interfaces';
import { historyAPI } from '../api/historyAPI';

export const useHistoryInfo = () => {
    const [historyInfo, setHistoryInfo] =
        useState<IPaymentHistoryArrDto | null>(null);

    useEffect(() => {
        const fetch = async () => {
            try {
                const res = await historyAPI();
                console.log(res);
                setHistoryInfo(res.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetch();
    }, []);
    return historyInfo;
};
