import { useState } from 'react';
import { IUseModal } from '../../types/modalInterface';

export const useModal = (initialState = false): IUseModal => {
    const [modalIsOpen, setIsOpen] = useState<boolean>(initialState);
    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);

    return { modalIsOpen, openModal, closeModal };
};
