import React from 'react';
import {
    modalContainer,
    modalLayout,
    modalTransiton,
} from './withdrawalBtn.styled';
import { Flex, Table, Td, Th, Tr } from '@shared/ui';
import { CloseBtnIcon } from '@shared/ui/icon/CloseBtnIcon';
import { InModalDetailDto } from '@entities/Calendar';

export const DetailBtn = ({
    detailInfo,
    handleClose,
    modalIsOpen,
    isClosing,
    modalRef,
}: InModalDetailDto) => {
    return (
        <div
            className={`${modalIsOpen && !isClosing ? 'opacity-100 ' : 'opacity-0'} ${modalTransiton.class}`}
        >
            {modalIsOpen && (
                <div className={modalLayout.class}>
                    <div ref={modalRef} className={modalContainer.class}>
                        <div className="w-[100%] h-[100%]">
                            <Flex justify="between" className="mb-5">
                                <h1 className="text-xl flex items-end font-bold">
                                    출금액
                                </h1>
                                <div
                                    className="cursor-pointer"
                                    onClick={handleClose}
                                >
                                    <CloseBtnIcon />
                                </div>
                            </Flex>
                            <Table className="h-full">
                                <Tr>
                                    <Th>결제 건수</Th>
                                    <Td className="px-4 font-medium ">
                                        {detailInfo
                                            ? `${detailInfo.payments.toLocaleString()} 원`
                                            : '데이터 로딩 중...'}
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Th>취소 건수</Th>
                                    <Td className="px-4 font-medium ">
                                        {detailInfo
                                            ? `${detailInfo.cancellations.toLocaleString()} 원`
                                            : '데이터 로딩 중...'}
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Th>결제액</Th>
                                    <Td className="px-4 font-medium ">
                                        {detailInfo
                                            ? `${detailInfo.paymentsAmount.toLocaleString()} 원`
                                            : '데이터 로딩 중...'}
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Th>취소액</Th>
                                    <Td className="px-4 font-medium ">
                                        {detailInfo
                                            ? `${detailInfo.cancelAmount.toLocaleString()} 원`
                                            : '데이터 로딩 중...'}
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Th>PG수수료</Th>
                                    <Td className="px-4 font-medium ">
                                        {detailInfo
                                            ? `${detailInfo.PGCharge.toLocaleString()} 원`
                                            : '데이터 로딩 중...'}
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Th>정산총액</Th>
                                    <Td className="px-4 font-medium ">
                                        {detailInfo
                                            ? `${detailInfo.todaySettlementAmount.toLocaleString()} 원`
                                            : '데이터 로딩 중...'}
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Th>출금액</Th>
                                    <Td className="px-4 font-medium ">
                                        {detailInfo
                                            ? `${detailInfo.thisMonthSettlementAmount.toLocaleString()} 원`
                                            : '데이터 로딩 중...'}
                                    </Td>
                                </Tr>
                            </Table>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
