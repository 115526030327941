import { ITransactionPaymentInfoHistoryArrDto, ITransactionPaymentInfoHistoryDto } from '@entities/TransactionPaymentInfo';

interface IFilterCriteria {
    startDate: string;
    endDate: string;
}

export const filterCheck = (
    TransactionHistoryInfo: ITransactionPaymentInfoHistoryArrDto,
    filterCriteria: IFilterCriteria
): ITransactionPaymentInfoHistoryDto[] => {
    if (!TransactionHistoryInfo) return [];

    const isDateInRange = (item: ITransactionPaymentInfoHistoryDto) => {
        const date = item.paymentDateTime;
        return (
            filterCriteria.startDate <= date && date <= filterCriteria.endDate
        );
    };

    return TransactionHistoryInfo.TransactionHistoryInfo.filter((item) => {
        return isDateInRange(item);
    });
};