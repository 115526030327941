import React from 'react';

export const ReceiptIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="15"
            viewBox="0 0 13 15"
            fill="none"
        >
            <path
                d="M11.375 0H1.625C0.727537 0 0 0.610521 0 1.36364V13.6364C0 14.3895 0.727537 15 1.625 15H11.375C12.2725 15 13 14.3895 13 13.6364V1.36364C13 0.610521 12.2725 0 11.375 0Z"
                fill="#C3C4BC"
            />
            <path
                d="M3.25 3.40918H9.75"
                stroke="white"
                strokeWidth="1.7"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.25 6.81812H9.75"
                stroke="white"
                strokeWidth="1.7"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.25 10.2273H6.5"
                stroke="white"
                strokeWidth="1.7"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
