
import { totalListAPI } from '@entities/DailyPaymentInfo/api/totalListAPI';
import { IDailyPaymentTotalListArrDto } from '@entities/DailyPaymentInfo/model/interfaces';
import { useEffect, useState } from 'react';

export const useTotalList = () => {
    const [totalListInfo, setTotalListInfo] =
        useState<IDailyPaymentTotalListArrDto | null>(null);

    useEffect(() => {
        const fetch = async () => {
            try {
                const res = await totalListAPI();
                console.log(res);
                setTotalListInfo(res.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetch();
    }, []);
    return totalListInfo;
};
