import React from 'react';
import { ICompanyInfoDto } from '@entities/CompanyInfo';
import { Flex, Table, Tbody, Td, Th, Tr } from '@shared/ui';

export const CompInfo = ({ compInfo }: ICompanyInfoDto) => {
    return (
        <div className="min-w-[860px]">
            <Flex justify="between" className="mb-5">
                <h2 className="text-P3_Black text-[18px] font-semibold">
                    회사
                </h2>
            </Flex>
            <Table className="font-semibold">
                <Tbody>
                    <Tr className="border-P3_Gray4">
                        <Th className="w-[15%] border-none text-start py-[10px] px-5 min-w-[140px]">
                            상호
                        </Th>
                        <Td className="w-[35%] border-none">
                            {compInfo.companyName}
                        </Td>
                        <Th className="w-[15%] border-none text-start py-[10px] px-5">
                            개업일
                        </Th>
                        <Td className="border-none">
                            {compInfo.establishmentDate}
                        </Td>
                    </Tr>
                    <Tr className="border-P3_Gray4">
                        <Th className="border-none text-start py-[10px] px-5">
                            사업자형태
                        </Th>
                        <Td className="border-none">{compInfo.businessType}</Td>
                    </Tr>
                    <Tr className="border-P3_Gray4">
                        <Th className="border-none text-start py-[10px] px-5">
                            사업자등록번호
                        </Th>
                        <Td className="border-none">
                            {compInfo.businessRegistrationNumber}
                        </Td>
                        <Th className="border-none text-start py-[10px] px-5">
                            법인등록번호
                        </Th>
                        <Td className="border-none">
                            {compInfo.corporateRegistrationNumber}
                        </Td>
                    </Tr>
                    <Tr className="border-P3_Gray4">
                        <Th className="border-none text-start py-[10px] px-5">
                            사업장주소
                        </Th>
                        <Td className="border-none" colSpan={3}>
                            {compInfo.businessAddress}
                        </Td>
                    </Tr>
                    <Tr className="border-P3_Gray4">
                        <Th className="border-none text-start py-[10px] px-5">
                            업체
                        </Th>
                        <Td className="border-none">{compInfo.industryType}</Td>
                        <Th className="border-none text-start py-[10px] px-5">
                            종목
                        </Th>
                        <Td className="border-none">
                            {compInfo.businessField}
                        </Td>
                    </Tr>
                    <Tr className="border-P3_Gray4">
                        <Th className="border-none text-start py-[10px] px-5">
                            홈페이지
                        </Th>
                        <Td className="border-none">{compInfo.website}</Td>
                        <Th className="border-none text-start py-[10px] px-5">
                            연락처
                        </Th>
                        <Td className="border-none">{compInfo.contactInfo}</Td>
                    </Tr>
                </Tbody>
            </Table>
        </div>
    );
};
