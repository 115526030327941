import { tw } from '@shared/lib/Tailwindest';

export const dailyPaymentInfoContainer = tw.style({
    width: 'w-[100%]',
    height: 'h-[100%]',
    backgroundColor: 'bg-white',
    paddingX: 'px-[25px]',
    paddingY: "py-[28px]",
    overflow: 'overflow-auto',
});
