import React, { useRef } from 'react';
import { ICalendarHeader } from '@shared/types';
import { format } from 'date-fns';
import {
    handleInputChange,
    handleShowPicker,
} from '@features/Calendar/lib/handlers';

export const CurrentMonth = ({
    currentDate,
    setCurrentDate,
}: ICalendarHeader) => {
    const currentMonthFormat = format(currentDate, 'yyyy년 M월');

    const inputRef = useRef<HTMLInputElement>(null);

    const handleBtnClick = () => {
        handleShowPicker(inputRef);
    };

    const handleInputChangeClick = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleInputChange(setCurrentDate, e);
    };

    return (
        <button
            className="sticky text-P3_Black font-bold text-2xl"
            onClick={handleBtnClick}
        >
            {currentMonthFormat}
            <input
                ref={inputRef}
                type="month"
                defaultValue={format(currentDate, 'yyyy-MM')}
                onChange={handleInputChangeClick}
                style={{
                    position: 'absolute',
                    opacity: 0,
                    pointerEvents: 'none',
                }}
            />
        </button>
    );
};
