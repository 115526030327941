import { totalAmountAPI } from '@entities/Calendar/api/totalAmountAPI';
import { ITotalAmountDto } from '@entities/Calendar/model/interfaces';
import { useEffect, useState } from 'react';

export function useTotalAmount() {
    const [totalAmount, setTotalAmount] = useState<ITotalAmountDto | null>(
        null,
    );

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await totalAmountAPI();
                setTotalAmount(response.data);
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        };

        fetchData();
    }, []);

    return totalAmount;
}
