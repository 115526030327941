import { tw } from "@shared/lib/Tailwindest";

export const customDateInput = tw.style({
    backgroundColor: 'bg-P3_Gray1',
    color: 'text-P3_Black',
    fontWeight: 'font-semibold',
    paddingY: 'py-[10px]',
    paddingX: 'px-[20px]',
    borderWidth: 'border',
    borderRadius: 'rounded-[8px]',
    borderStyle: 'border-none',
});
