import { useEffect, useState } from 'react';
import { IChargeHistoryArrDto } from '../model/interface';
import { historyAPI } from '../api/historyAPI';

export const useHistoryInfo = () => {
    const [historyInfo, setHistoryInfo] = useState<IChargeHistoryArrDto | null>(
        null,
    );

    useEffect(() => {
        const fetch = async () => {
            try {
                const res = await historyAPI();
                console.log(res);
                setHistoryInfo(res.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetch();
    }, []);
    return historyInfo;
};
