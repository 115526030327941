import { tw } from '@shared/lib/Tailwindest';

export const tooltipContent = tw.style({
    position: 'absolute',
    padding: 'p-2',
    fontSize: 'text-sm',
    backgroundColor: 'bg-white',
    borderWidth: 'border',
    transitionProperty: 'transition-opacity',
    transitionDuration: 'duration-300',
});
