import { createTools, type Tailwindest } from 'tailwindest';

type TailwindCustom = Tailwindest<{
  
  color:
  | 'P3_Red'
  | 'P3_Black'
  | 'P3_White'
  | 'P3_Gray1'
  | 'P3_Gray2'
  | 'P3_Gray3'
  | 'P3_Gray4'
  | 'P3_Gray5'
  | 'P3_PointB'
  | 'P3_ExcelG'
  | 'P3_bg_cloudy' // 배경 흐리게
  screens: 'iphone' | 'ipad' | 'mac';
}>;

const tw = createTools<TailwindCustom>();

export { tw };
