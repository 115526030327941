import React, { useState } from 'react';
import { Drawer } from '@widgets/Drawer';
import { Outlet } from 'react-router-dom';
import NavigationBar from '@app/layout/NavigationBar/NavigationBar';
import { Flex } from '@shared/ui';

const Layout = () => {
    const [isDrawerVisible, setDrawerVisible] = useState(true);

    const toggleDrawer = () => {
        setDrawerVisible(!isDrawerVisible);
    };

    return (
        <Flex col className="h-screen bg-P3_Gray1 touch-none overflow-hidden">
            <NavigationBar toggleDrawer={toggleDrawer} />
            <Flex className={`flex-1 overflow-hidden ${isDrawerVisible ? 'gap-14' : ''
                }`}>
                <div
                    className={`flex-shrink-0 transition-all duration-300 ease-in-out ${isDrawerVisible ? 'w-[250px]' : 'w-0'
                        }`}
                >
                    <div className={`w-64 h-full transition-transform duration-300 ease-in-out mt-14 ${isDrawerVisible ? 'translate-x-0' : '-translate-x-full mr-14'
                        }`}>
                        <Drawer />
                    </div>
                </div>
                <div className="flex-grow overflow-auto transition-all duration-300 ease-in-out mt-14">
                    <Outlet />
                </div>
            </Flex >
        </Flex>
    );
};

export default Layout;
