import React, { useEffect } from 'react';
import { Button, Flex } from '@shared/ui';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@app/AppStore';
import { getWalletBalance } from '@features/WalletBalance';
import { useCountUpAni } from '@shared/lib/hook/useCountUpAni.hook';

export const Wallet = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { balance, loading, error } = useSelector(
        (state: RootState) => state.wallet,
    );

    useEffect(() => {
        const fetchBalance = () => {
            dispatch(getWalletBalance());
        };

        fetchBalance(); // 초기 1번 갱신
        const intervalId = setInterval(fetchBalance, 10000); // 10초마다 실시간 갱신

        return () => clearInterval(intervalId);
    }, [dispatch]);

    const aniBalance = useCountUpAni({ end: balance });

    // 지갑 갱신 로딩이랑 에러처리 방식 변경 해야함 - 이미지, 애니메이션 등으로
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    return (
        <Flex justify="center" items="center" col className="w-[45%]">
            <h1 className="text-lg text-[#27262B] mb-[31px]">지갑</h1>
            <p className="text-[32px] text-[#27262B] font-bold mb-[35px]">
                {/* 잔액 10초마다 실시간 갱신 */}
                {aniBalance}원
            </p>
            <Button size="xl" onClick={() => navigate('/withdraw')}>
                출금하기
            </Button>
        </Flex>
    );
};
