import { ISettlementTotalListDto } from '@entities/Settlement';

export const calculateTotals = (items: ISettlementTotalListDto[]) => {
    const totals = {
        totalPaymentCancellationCount: 0,
        totalSalesAmount: 0,
        totalPgFee: 0,
        totalSettlementAmount: 0,
        totalWithdrawalAmount: 0,
    };

    items.forEach((item) => {
        totals.totalPaymentCancellationCount += item.paymentCancleAmount;
        totals.totalSalesAmount += item.salesAmount;
        totals.totalPgFee += item.PGFeeAmount;
        totals.totalSettlementAmount += item.settlementAmount;
        totals.totalWithdrawalAmount += item.withdrawalAmount;
    });
    return totals;
};
