import React from 'react';
import { AdminInfo, CEOInfo, CompInfo, MaketID } from '@widgets/CompanyInfo';
import {
    useCeoInfo,
    useCompanyInfo,
    useMID,
} from '@entities/CompanyInfo';
import { companyInfoContainer } from './companyInfo.styled';
import { AnimatePresence, motion } from 'framer-motion';

export const CompanyInfo = () => {
    const MarketI = useMID();
    const CompI = useCompanyInfo();
    const CEOI = useCeoInfo();

    return (
        <div className={companyInfoContainer.class}>
            <AnimatePresence>
                <motion.div
                    key={location.pathname}
                    initial={{ opacity: 0, x: 1000 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 1000 }}
                    transition={{ duration: 0.4 }}
                >
                    <h1 className="text-[22px] font-bold mb-5">회사 정보</h1>
                    {MarketI && <MaketID marketID={MarketI} />}
                    {/* 회사 */}
                    {CompI && <CompInfo compInfo={CompI.compInfo} />}
                    {/* 대표자 */}
                    {CEOI && <CEOInfo ceoInfo={CEOI.ceoInfo} />}
                    {/* 담당자 */}
                    <AdminInfo />
                </motion.div>
            </AnimatePresence>
        </div>
    );
};
