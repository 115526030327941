import React, { useEffect } from 'react';
import { CheckInput } from '@shared/ui/input/CheckInput';
import { customDateInput } from './dailyPaymentInfo.styledFilter';
import { CalendarIcon, Flex, HyphenIcon, Table, Tbody, Td, Th, Tr } from '@shared/ui';
import { useDateChange } from '@shared/lib';
import { handleDateClick } from '@shared/lib/handler';
import { IDailyPaymentInfoFilter } from '@features/DailyPaymentInfo';

export const DailyPaymentInfoFilter = ({ setFilterCriteria }: IDailyPaymentInfoFilter) => {
    const { startDate, setStartDate, endDate, setEndDate, changeHandler } =
        useDateChange();

    useEffect(() => {
        setFilterCriteria({
            startDate,
            endDate,
        });
    }, [startDate, endDate]);

    return (
        <Flex className="mt-[33px] mb-3 min-w-[1080px]">
            <Table>
                <Tbody>
                    <Tr className="border-P3_Gray4">
                        <Th className=" text-start border-none w-[14%]">
                            <label className="py-[10px] pl-[20px] pr-[64px] text-[16px] font-medium text-P3_Black ">
                                조회기간
                            </label>
                        </Th>
                        <Td className="border-none  flex items-center">
                            <div className="flex ml-[15px]  items-center">
                                <CalendarIcon />
                                <input
                                    type="date"
                                    value={startDate}
                                    onChange={(e) =>
                                        setStartDate(e.target.value)
                                    }
                                    onClick={handleDateClick}
                                    className={`${customDateInput.class} hide-calendar-icon ml-[22px] `}
                                />
                            </div>

                            <span className="flex items-center justify-center">
                                <HyphenIcon />
                            </span>

                            <div className="flex ml-[22px]">
                                <input
                                    type="date"
                                    value={endDate}
                                    onChange={(e) =>
                                        setEndDate(e.target.value)
                                    }
                                    onClick={handleDateClick}
                                    className={`${customDateInput.class} hide-calendar-icon`}
                                />
                            </div>
                            <div className='flex gap-6'>
                                <label className="flex items-center">
                                    <CheckInput
                                        input_type="radio"
                                        input_name="query_period"
                                        input_value="yesterday"
                                        input_onChange={changeHandler}
                                    />
                                    <span className="ml-2 text-base font-medium text-P3_Black">
                                        어제
                                    </span>
                                </label>
                                <label className="flex items-center">
                                    <CheckInput
                                        input_type="radio"
                                        input_name="query_period"
                                        input_value="lastWeek"
                                        input_onChange={changeHandler}
                                    />
                                    <span className="ml-2 text-base font-medium text-P3_Black">
                                        지난주
                                    </span>
                                </label>
                                <label className="flex items-center">
                                    <CheckInput
                                        input_type="radio"
                                        input_name="query_period"
                                        input_value="lastMonth"
                                        input_onChange={changeHandler}
                                    />
                                    <span className="ml-2 text-base font-medium text-P3_Black">
                                        지난달
                                    </span>
                                </label>

                                <label className="flex items-center">
                                    <CheckInput
                                        input_type="radio"
                                        input_name="query_period"
                                        input_value="thisMonth"
                                        input_onChange={changeHandler}
                                        defaultChecked
                                    />
                                    <span className="ml-2 text-base font-medium text-P3_Black">
                                        이번달
                                    </span>
                                </label>
                            </div>
                        </Td>
                    </Tr>
                </Tbody>
            </Table>
        </Flex>

    );
};
