import {
    Button,
    Flex,
    HyphenIcon,
    PaginatedList,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from '@shared/ui';
import React, { useState } from 'react';
import { customDateInput } from './history.styled';
import { getTodayDate } from '@shared/lib';
import { getLastWeekDate } from '@shared/lib/date';
import { IChargeHistoryArrDto, IChargeHistoryDto } from '@entities/Charge';
import { StatusHandler } from '@features/Charge';
import { formatNumber } from '@shared/lib/FormatNumber';
import { handleDateClick, handlePaginate } from '@shared/lib/handler';

export const ChargeHistory = ({ chargeHistoryInfo }: IChargeHistoryArrDto) => {
    const [startDate, setStartDate] = useState(getLastWeekDate());
    const [endDate, setEndDate] = useState(getTodayDate());
    const [currentPage, setCurrentPage] = useState(1);
    const [filterDate, setFilterDate] = useState<IChargeHistoryDto[]>([]);
    const itemsPerPage = 5;
    const totalPages = Math.ceil(filterDate.length / itemsPerPage);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentItems = filterDate.slice(indexOfFirstItem, indexOfLastItem);

    const queryBtnHandler = () => {
        const filtered = chargeHistoryInfo.filter((item) => {
            const chargeDate = new Date(item.chargeDate);
            return (
                chargeDate >= new Date(startDate) &&
                chargeDate <= new Date(endDate)
            );
        });
        setFilterDate(filtered);
        setCurrentPage(1);
    };

    return (
        <div className="min-w-[860px]">
            <h1 className="text-[18px] font-semibold text-P3_Black mb-[17px]">
                충전 내역 확인
            </h1>
            <Flex items="center" className="mb-4">
                <>
                    <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        onClick={handleDateClick}
                        className={`${customDateInput.class} hide-calendar-icon`}
                    />
                    <div className="mx-4">
                        <HyphenIcon />
                    </div>
                    <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        onClick={handleDateClick}
                        className={`${customDateInput.class} hide-calendar-icon`}
                    />
                </>
                <Button
                    size="sm"
                    bg="bg-[#E34922]"
                    color="text-[#FFFFF]"
                    className="ml-6"
                    onClick={queryBtnHandler}
                >
                    조회하기
                </Button>
            </Flex>
            <Table>
                <Thead>
                    <Tr>
                        <Th className="py-[10px]">신청일시</Th>
                        <Th>충전일시</Th>
                        <Th>충전금액</Th>
                        <Th>진행상태</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {currentItems.map((d, index) => (
                        <Tr className="text-center" key={index}>
                            <Td className="py-[14px]">{d.requestDate}</Td>
                            <Td>{d.chargeDate}</Td>
                            <Td>{formatNumber(d.balance)}</Td>
                            <Td>
                                <StatusHandler data={d.status} />
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
            <Flex col className="text-end mt-[18px]">
                <PaginatedList
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(pageNumber) =>
                        handlePaginate(pageNumber, totalPages, setCurrentPage)
                    }
                />
            </Flex>
        </div>
    );
};
