import { AxiosResponse, AxiosRequestHeaders } from 'axios';
import { ITransactionPaymentInfoHistoryArrDto } from '../model/interfaces';

const totalListData: ITransactionPaymentInfoHistoryArrDto = {
    TransactionHistoryInfo: [{
        paymentDateTime: '2024-05-25',
        cancellationDateTime: '2024-05-26',
        orderNumber: "324-234-5433",
        paymentStatus: '완료',
        cancel: '완료',
        receipt: 234,
        buyerName: 'sumin1',
        buyerId: 'point3-1',
        paymentAmount: 234,
        cancellationAmount: 234,
    },
    {
        paymentDateTime: '2024-05-25',
        cancellationDateTime: '2024-05-26',
        orderNumber: "324-234-5433",
        paymentStatus: '취소',
        cancel: '취소',
        receipt: 23434,
        buyerName: 'sumin2',
        buyerId: 'point3-2',
        paymentAmount: 2334214,
        cancellationAmount: 2334214,
    },
    {
        paymentDateTime: '2024-06-25',
        cancellationDateTime: '2024-06-26',
        orderNumber: "424-234-5433",
        paymentStatus: '완료',
        cancel: '완료',
        receipt: 234,
        buyerName: 'sumin3',
        buyerId: 'point3-3',
        paymentAmount: 234,
        cancellationAmount: 234,
    }
    ]
}

export const TransactionPaymentInfoAPI = async (): Promise<
    AxiosResponse<ITransactionPaymentInfoHistoryArrDto>
> => {
    return {
        data: totalListData,
        status: 200,
        statusText: 'OK',
        headers: {},
        config: {
            headers: {} as AxiosRequestHeaders,
        },
    };
};
