import { Flex } from '@shared/ui';
import React, { useEffect, useRef, useState } from 'react';
import { paginationUl } from './paginatedList.styled';

interface IPaginationList {
    currentPage: number;
    totalPages: number;
    onPageChange: (pageNumber: number) => void;
}

export const PaginatedList = ({
    currentPage,
    totalPages,
    onPageChange,
}: IPaginationList) => {
    const maxPageNumbersToShow = 5;
    const [showInput, setShowInput] = useState<{
        left: boolean;
        right: boolean;
    }>({ left: false, right: false });
    const inputRef = useRef<HTMLInputElement | null>(null);

    const getPageNumbers = () => {
        let pages: (number | string)[] = [];
        if (totalPages <= maxPageNumbersToShow) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            if (currentPage < 5) {
                pages = [1, 2, 3, 4, 5, '...', totalPages];
            } else if (currentPage >= totalPages - 3) {
                pages = [
                    1,
                    '...',
                    totalPages - 4,
                    totalPages - 3,
                    totalPages - 2,
                    totalPages - 1,
                    totalPages,
                ];
            } else {
                pages = [
                    1,
                    '...',
                    currentPage - 1,
                    currentPage,
                    currentPage + 1,
                    '...',
                    totalPages,
                ];
            }
        }
        return pages;
    };

    const pageNumbers = getPageNumbers();

    const jjumBtnHandler = (side: 'left' | 'right') => {
        setShowInput((prev) => ({
            left: side === 'left' ? !prev.left : false,
            right: side === 'right' ? !prev.right : false,
        }));
        if (showInput.left) onPageChange(currentPage - 2);
        if (showInput.right) onPageChange(currentPage + 2);
    };

    useEffect(() => {
        if (showInput && inputRef.current) {
            inputRef.current.focus();
        }
    }, [showInput]);

    return (
        <Flex col items="center" className="mt-5">
            <nav className="w-[100%]">
                <ul className={`${paginationUl.class}`}>
                    <button
                        onClick={() => onPageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                        >
                            <path
                                d="M12.0396 6L7.14372 9.78462C7.10565 9.81224 7.0753 9.8456 7.05455 9.88265C7.03381 9.91969 7.0231 9.95963 7.0231 10C7.0231 10.0404 7.03381 10.0803 7.05455 10.1174C7.0753 10.1544 7.10565 10.1878 7.14372 10.2154L12.0396 14"
                                stroke="#6C6D5D"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                    {pageNumbers.map((number, index) =>
                        typeof number === 'number' ? (
                            <button key={index} className='w-[25px]'>
                                <li
                                    className={`${number === currentPage &&
                                        'w-[25px] h-[23px] text-center font-semibold text-P3_White bg-P3_Red rounded-full'
                                        }`}
                                    onClick={() => onPageChange(number)}
                                >
                                    {number}
                                </li>
                            </button>
                        ) : (
                            <button
                                key={index}
                                onClick={() =>
                                    jjumBtnHandler(
                                        index === 1 ? 'left' : 'right',
                                    )
                                }
                            >
                                <li>...</li>
                            </button>
                        ),
                    )}
                    <button
                        onClick={() => onPageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                        >
                            <path
                                d="M7.9604 6L12.8563 9.78462C12.8944 9.81224 12.9247 9.8456 12.9454 9.88265C12.9662 9.91969 12.9769 9.95963 12.9769 10C12.9769 10.0404 12.9662 10.0803 12.9454 10.1174C12.9247 10.1544 12.8944 10.1878 12.8563 10.2154L7.9604 14"
                                stroke="#6C6D5D"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                </ul>
            </nav>
        </Flex>
    );
};
