import React, { PropsWithChildren } from 'react';

interface ITable extends PropsWithChildren {
    className?: string;
}

export const Table = ({ children, className = '' }: ITable) => {
    return (
        <table className={`${className} w-[100%] border-collapse`}>
            {children}
        </table>
    );
};
