import { IAdminDeleteBtn } from '@entities/CompanyInfo';
import React from 'react';

export const AdminDeleteBtn: React.FC<IAdminDeleteBtn> = ({
    deleteManager,
}) => {
    return (
        <button onClick={deleteManager}>
            <p className="text-P3_Red font-semibold underline">삭제</p>
        </button>
    );
};
