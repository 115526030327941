import { useState } from 'react';

export const useDropdown = <T>() => {
    const [dropdownIsOpen, setIsOpen] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<T>();

    const toggleDropdown = () => setIsOpen(!dropdownIsOpen);
    const selectItem = (item: T) => {
        setSelectedItem(item);
        setIsOpen(false);
    };

    return { dropdownIsOpen, toggleDropdown, selectItem, selectedItem };
};
