import React from 'react';

export const HyphenIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="2"
            viewBox="0 0 10 2"
            fill="none"
        >
            <path
                d="M1 1H9"
                stroke="#E34922"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
