import React, { useEffect, useState } from 'react';
import { Flex, Spacer, Table, Tbody, Td, Text, Th, Tr } from '@shared/ui';
import {
    AdminAddBtn,
    AdminDeleteBtn,
    addManager,
    deleteManager,
} from '@features/CompanyInfo';
import { IManager } from '@entities/CompanyInfo/model/interface';

export const AdminInfo = () => {
    const [managers, setManagers] = useState<IManager[]>([]);
    const [disable, setDisable] = useState<boolean>(false);

    // 임시 로컬스토리지 값, 추후 API로 불러오면 됌.
    useEffect(() => {
        const storedManagers = JSON.parse(
            localStorage.getItem('managers') || '[]',
        );
        setManagers(storedManagers);
    }, []);

    useEffect(() => {
        if (managers.length < 5) {
            setDisable(false);
        } else {
            setDisable(true);
        }
    }, [managers]);

    const handleAddManager = (newManager: IManager) => {
        addManager(managers, setManagers, newManager);
    };

    const handleDeleteManager = (index: number) => {
        deleteManager(managers, setManagers, index);
    };

    return (
        <div className="min-w-[860px]">
            <Flex className="my-5">
                <h1 className="text-P3_Black text-lg font-semibold">담당자</h1>
                <Spacer />
                <AdminAddBtn
                    addManager={handleAddManager}
                    onDisable={disable}
                />
            </Flex>
            <Table className="font-semibold">
                <Tbody>
                    {managers.map((manager, index) => (
                        <React.Fragment key={index}>
                            <Tr className="border-P3_Gray4">
                                <Th className="w-[15%] border-none text-start py-[10px] px-5 min-w-[140px]">
                                    이름
                                </Th>
                                <Td className="w-[15%] border-none" colSpan={3}>
                                    {manager.name}
                                </Td>
                                <Td className="text-end">
                                    <AdminDeleteBtn
                                        deleteManager={() =>
                                            handleDeleteManager(index)
                                        }
                                    />
                                </Td>
                            </Tr>
                            <Tr className="border-P3_Gray4">
                                <Th className="w-[15%] border-none text-start py-[10px] px-5">
                                    연락처
                                </Th>
                                <Td className="w-[35%] border-none">
                                    {manager.contact}
                                </Td>
                                <Th className="w-[15%] border-none text-start py-[10px] px-5">
                                    업무
                                </Th>
                                <Td>{manager.job}</Td>
                            </Tr>
                            <div className="h-4"></div>
                        </React.Fragment>
                    ))}
                </Tbody>
            </Table>
            <Text size="sm" className="flex justify-end mt-3">
                ∙ 담당자는 최대 5명까지 등록이 가능해요.
            </Text>
        </div>
    );
};
