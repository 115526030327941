import { tw } from '@shared/lib/Tailwindest';

export const buttonFrame = tw.style({
    borderRadius: 'rounded-full',
    fontWeight: 'font-semibold',
    color: 'text-P3_White',
});

export const buttonSize = {
    xs: tw.style({
        paddingX: 'px-[10px]',
        paddingY: 'py-[8px]',
        fontSize: 'text-base',
        minWidth: "min-w-[76px]"
    }),
    sm: tw.style({
        paddingX: 'px-[20px]',
        paddingY: 'py-[10px]',
        fontSize: 'text-base',
        minWidth: "min-w-[96px]"
    }),
    md: tw.style({
        backgroundColor: 'bg-P3_Black',
        paddingX: 'px-[35px]',
        paddingY: 'py-[20px]',
        fontSize: 'text-[18px]',
        minWidth: "min-w-[133px]"
    }),
    lg: tw.style({
        backgroundColor: 'bg-P3_Red',
        paddingX: 'px-[118px]',
        paddingY: 'py-[17px]',
        fontSize: 'text-base',
        display: "flex",
        justifyContent: "justify-center"
    }),
    xl: tw.style({
        backgroundColor: 'bg-P3_Black',
        paddingX: 'px-[154px]',
        paddingY: 'py-[17px]',
        fontSize: 'text-base',
        minWidth: "min-w-[364px]"
    }),
};
