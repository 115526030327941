import React from 'react';
import { OrderSearchBar } from '@features/TransactionPaymentInfo';
import { Flex, } from '@shared/ui';
import { ResetBtn } from '@shared/ui/button/ResetBtn';
import { ISearchBar } from '@shared/types/searchBarInterface';

export const TransactionPaymentInfoHeader = ({ onSearch }: ISearchBar) => {
    return (

        <Flex justify='between' items='center' className='mt-8 mb-4 min-w-[1080px]'>
            <OrderSearchBar onSearch={onSearch} />
            <ResetBtn />
        </Flex>

    );
};
