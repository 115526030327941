import React, { PropsWithChildren } from 'react';

interface ITd extends PropsWithChildren {
    className?: string;
    colSpan?: number;
    onClick?: () => void;
}

export const Td = ({
    children,
    className = '',
    colSpan,
    onClick,
}: ITd) => {
    return (
        <td
            className={`${className} p-2 border-P3_Gray3 border-r last:border-none text-P3_Black`}
            colSpan={colSpan}
            onClick={onClick}
        >
            {children}
        </td>
    );
};
