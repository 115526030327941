import { AxiosResponse, AxiosRequestHeaders } from 'axios';
import { ITotalAmountDto } from '../model/interfaces';

const CalendarAmountData: ITotalAmountDto = {
    currentDate: new Date(),
    totalAmountInfo: {
        monthSales: 100000000,
        monthSettlementAmount: 10000000,
        monthWithdrawAmount: 1000000,
    },
};

export const totalAmountAPI = async (): Promise<
    AxiosResponse<ITotalAmountDto>
> => {
    return {
        data: CalendarAmountData,
        status: 200,
        statusText: 'OK',
        headers: {},
        config: {
            headers: {} as AxiosRequestHeaders,
        },
    };
};
