import { Flex } from '@shared/ui';
import React from 'react';
import { LiContainer } from './homeNotice.styled';

export const HomeNotice = () => {
    return (
        <Flex items="center" col className="w-[60%] bg-[#F1F1EE]">
            <Flex col items="center" className="w-full">
                <h1 className="text-lg text-[#27262B] mt-5 mb-4">중요알림</h1>
                <ul className="w-[90%] h-[250px] overflow-y-scroll">
                    <li className={LiContainer.class}>
                        <div className="flex justify-between">
                            <p className="text-P3_Red text-lg font-semibold">
                                출금 완료
                            </p>
                            <p className="text-P3_Gray4 text-sm">2024-06-13</p>
                        </div>
                        <p className="text-P3_Black">
                            계좌1(신한)로 345,543,000원이 출금 되었어요.
                        </p>
                    </li>
                    <li className={LiContainer.class}>
                        <div className="flex justify-between">
                            <p className="text-P3_Red text-lg font-semibold">
                                출금 완료
                            </p>
                            <p className="text-P3_Gray4 text-sm">2024-06-13</p>
                        </div>
                        <p>계좌1(신한)로 345,543,000원이 출금 되었어요.</p>
                    </li>
                    <li className={LiContainer.class}>
                        <div className="flex justify-between">
                            <p className="text-P3_Red text-lg font-semibold">
                                출금 완료
                            </p>
                            <p className="text-P3_Gray4 text-sm">2024-06-13</p>
                        </div>
                        <p>계좌1(신한)로 345,543,000원이 출금 되었어요.</p>
                    </li>
                    <li className={LiContainer.class}>
                        <div className="flex justify-between">
                            <p className="text-P3_Red text-lg font-semibold">
                                출금 완료
                            </p>
                            <p className="text-P3_Gray4 text-sm">2024-06-13</p>
                        </div>
                        <p>계좌1(신한)로 345,543,000원이 출금 되었어요.</p>
                    </li>
                </ul>
            </Flex>
        </Flex>
    );
};
