import { tw } from '@shared/lib/Tailwindest';

export const modalLayout = tw.style({
    display: 'flex',
    alignItems: 'items-center',
    justifyContent: 'justify-center',
    position: 'fixed',
    backgroundColor: 'bg-P3_bg_cloudy',
    inset: 'inset-0',
    zIndex: 'z-40',
});

export const modalContainer = tw.style({
    display: 'flex',
    alignItems: 'items-center',
    justifyContent: 'justify-center',
    textAlign: 'text-start',
    minWidth: "min-w-[372px]",
    borderWidth: 'border',
    overflow: 'overflow-auto',
    backgroundColor: 'bg-P3_White',
    borderRadius: 'rounded-[8px]',
    padding: 'p-11',
    zIndex: 'z-50',
});

export const modalTransiton = tw.style({
    transitionProperty: 'transition-opacity',
    transitionDuration: 'duration-200',
    transitionTimingFunction: 'ease-in-out',
    position: 'fixed',
    zIndex: 'z-50',
});
