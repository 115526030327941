export const handleDateClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.currentTarget.showPicker();
};

export const handlePaginate = (
    pageNumber: number,
    totalPages: number,
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>,
) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
        setCurrentPage(pageNumber);
    }
};
