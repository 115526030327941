import { companyInfoAPI } from '@entities/CompanyInfo/api/companyInfoAPI';
import { ICompanyInfoDto } from '@entities/CompanyInfo/model/interface';
import { useEffect, useState } from 'react';

export const useCompanyInfo = () => {
    const [companyInfo, setCompanyInfo] = useState<ICompanyInfoDto>();

    useEffect(() => {
        const fetch = async () => {
            try {
                const res = await companyInfoAPI();
                console.log(res);
                setCompanyInfo(res.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetch();
    }, []);
    return companyInfo;
};
