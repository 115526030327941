import { format, isSameMonth, getDay, isToday } from 'date-fns';

export const DayUtils = ({ day, monthStart }: { day: Date; monthStart: Date }) => {
    const formattedDate = format(day, 'd');
    const keyDate = format(day, 'yyyy-MM-dd');
    const cloneDay = new Date(day);
    const dayOfWeek = getDay(day);
    const isSunday = dayOfWeek === 0;
    const isSaturday = dayOfWeek === 6;
    const isCurrentMonth = isSameMonth(day, monthStart);
    const isTodayDate = isToday(day);

    return {
        formattedDate,
        keyDate,
        cloneDay,
        isSunday,
        isSaturday,
        isCurrentMonth,
        isTodayDate,
    };
};
