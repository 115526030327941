import { tw } from '@shared/lib/Tailwindest';
import React from 'react';
import { textFrame, textSize } from './text.styled';

interface IText {
    children: React.ReactNode;
    className?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    size?: TextSize;
}

type TextSize = 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl' | 'xxxxl';

export const Text = ({
    children,
    className,
    onClick,
    size = 'sm',
}: IText) => {
    const textStyle = tw.mergeProps(
        textFrame.style,
        textSize[size as TextSize].style,
    );

    return (
        <span className={`${className} ${textStyle}`} onClick={onClick}>
            {children}
        </span>
    );
};
