import React from 'react';
import { usageInfoContainer } from './usageInfo.styled';
import { AnimatePresence, motion } from 'framer-motion';
import { UsageInfoFee, UsageInfoTime } from '@widgets/UsageInfo';

export const UsageInfo = () => {
    return (
        <div className={usageInfoContainer.class}>
            <AnimatePresence>
                <motion.div
                    key={location.pathname}
                    initial={{ opacity: 0, y: 1000 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -100 }}
                    transition={{ duration: 0.4 }}
                >
                    <h1 className="text-P3_Black text-[22px] font-bold mb-5">
                        이용정보
                    </h1>
                    <UsageInfoFee />
                    <UsageInfoTime />
                </motion.div>
            </AnimatePresence>
        </div>
    );
};
