import React from 'react';

export const CalendarIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <rect x="4" y="2" width="2" height="5" rx="1" fill="#27262B" />
            <rect x="10" y="2" width="2" height="5" rx="1" fill="#27262B" />
            <path
                d="M2 4.33337C2 3.78109 2.44772 3.33337 3 3.33337H13C13.5523 3.33337 14 3.78109 14 4.33337V6.00004H2V4.33337Z"
                fill="#27262B"
            />
            <path
                d="M2 6H14V13C14 13.5523 13.5523 14 13 14H3C2.44772 14 2 13.5523 2 13V6Z"
                fill="#E34922"
            />
        </svg>
    );
};
