import React from 'react';
import { LoginLayout } from '@widgets/Login';
import { AnimatePresence, motion } from 'framer-motion';

export const Login = () => {
    return (
        <AnimatePresence>
            <motion.animate
                key={location.pathname}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.1 }}
            >
                <LoginLayout />
            </motion.animate>
        </AnimatePresence>
    );
};
