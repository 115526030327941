import axios, { AxiosRequestHeaders, AxiosResponse } from 'axios';
import { ILoginDto } from '../model/interfaces';



const LoginData = {
    username: "sumin",
    password: "tnlas",
};

export const loginAPI = async (): Promise<AxiosResponse<ILoginDto>> => {
    const response = await axios.post<ILoginDto>('https://p3/login', LoginData, {
        headers: {
            'Content-Type': 'application/json',
        } as AxiosRequestHeaders,
    });
    return response;
};
