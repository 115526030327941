import React, { useEffect, useState } from 'react';
import {
    CalendarAmount,
    CalendarCells,
    CalendarDays,
    CalendarHeader,
} from '@widgets/Calendar';
import {
    ITodaysDto,
    useSales,
    useSettlement,
    useTotalAmount,
    useWithdraw,
} from '@entities/Calendar';
import { homeContainer } from './home.styled';
import { AnimatePresence, motion } from 'framer-motion';
import { Wallet } from '@widgets/Wallet';
import { HomeNotice } from '@widgets/HomeNotice';
import { Flex } from '@shared/ui';

export const Home = () => {
    const [currentDate, setCurrentDate] = useState(new Date());

    const TotalAmountInfo = useTotalAmount();
    const SalesInfo = useSales();
    const SettlementInfo = useSettlement();
    const WithdrawInfo = useWithdraw();
    const TodaysInfo: ITodaysDto['todaysInfo'] = {
        sales: SalesInfo,
        settlement: SettlementInfo,
        withdraw: WithdrawInfo,
    };

    useEffect(() => {
        console.log(TodaysInfo);
    },[SalesInfo, SettlementInfo, WithdrawInfo])

    return (
        <div className={homeContainer.class}>
            <AnimatePresence>
                <motion.div
                    key={location.pathname}
                    initial={{ opacity: 0, x: 1000 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 1000 }}
                    transition={{ duration: 0.4 }}
                >
                    <Flex className="border-b min-w-[885px]">
                        <Wallet />
                        <HomeNotice />
                    </Flex>
                    <Flex
                        col
                        className="w-full bg-P3_White p-[25px] min-w-[885px]"
                    >
                        <CalendarHeader
                            currentDate={currentDate}
                            setCurrentDate={setCurrentDate}
                        />
                        {TotalAmountInfo &&
                            SettlementInfo &&
                            SalesInfo &&
                            WithdrawInfo && (
                                <CalendarAmount
                                    currentDate={currentDate}
                                    totalAmountInfo={
                                        TotalAmountInfo.totalAmountInfo
                                    }
                                />
                            )}
                        <CalendarDays currentDate={currentDate} />
                        {TotalAmountInfo &&
                            SettlementInfo &&
                            SalesInfo &&
                            WithdrawInfo &&
                            TodaysInfo && (
                                <CalendarCells
                                    currentDate={currentDate}
                                    todaysInfo={TodaysInfo}
                                />
                            )}
                    </Flex>
                </motion.div>
            </AnimatePresence>
        </div>
    );
};
