import {
    getLastMonthEnd,
    getLastMonthStart,
    getLastWeekDate,
    getThisMonthEnd,
    getThisMonthStart,
    getTodayDate,
    getYesterDayDate,
} from '@shared/lib/date';
import { useState } from 'react';

export const useDateChange = () => {
    const [startDate, setStartDate] = useState(getThisMonthStart());
    const [endDate, setEndDate] = useState(getThisMonthEnd());

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch (e.target.value) {
            case 'yesterday':
                setStartDate(getYesterDayDate());
                setEndDate(getTodayDate());
                break;
            case 'lastWeek':
                setStartDate(getLastWeekDate());
                setEndDate(getTodayDate());
                break;
            case 'lastMonth':
                setStartDate(getLastMonthStart());
                setEndDate(getLastMonthEnd());
                break;
            case 'thisMonth':
                setStartDate(getThisMonthStart());
                setEndDate(getThisMonthEnd());
                break;
            default:
                break;
        }
    };
    return { startDate, setStartDate, endDate, setEndDate, changeHandler };
};
