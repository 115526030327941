import { tw } from '@shared/lib/Tailwindest';

export const customLabel = tw.style({
    display: 'flex',
    alignItems: 'items-center',
    fontWeight: 'font-medium',
    gap: 'gap-2',
});

export const customDateInput = tw.style({
    fontWeight: 'font-semibold',
    color: 'text-P3_Black',
    paddingX: 'px-2',
    borderWidth: 'border',
    borderRadius: 'rounded-md',
    borderStyle: 'border-none',
});

export const customCheckboxInput = tw.style({
    appearance: 'appearance-none',
    width: 'w-4',
    height: 'h-4',
    backgroundColor: 'bg-P3_Gray2',
    borderRadius: 'rounded-full',
    ':checked': {
        backgroundColor: 'checked:bg-P3_Red',
    },
});

export const dropdownUl = tw.style({
    position: 'absolute',
    backgroundColor: 'bg-P3_White',
    left: '-left-[85px]',
    width: 'w-[254px]',
    height: 'h-[153px]',
    marginTop: 'mt-8',
    paddingTop: 'pt-6',
    paddingX: 'px-8',
    borderColor: 'border-P3_Gray4',
    borderStyle: 'border-solid',
    borderWidth: 'border',
    borderRadius: 'rounded-lg',
    overflow: 'overflow-y-scroll',
});
