import { tw } from '@shared/lib/Tailwindest';

export const customInput = tw.style({
    width: 'w-4',
    height: 'h-4',
    color: 'text-blue-600',
    borderRadius: 'rounded',
    borderColor: 'border-gray-300',
});

export const customLabel = tw.style({
    display: 'flex',
    alignItems: 'items-center',
    color: 'text-gray-700',
    fontSize: 'text-sm',
    fontWeight: 'font-medium',
    gap: 'gap-1',
});

export const customDateInput = tw.style({
    fontSize: 'text-base',
    fontWeight: 'font-semibold',
    color: 'text-P3_Black',
    borderWidth: 'border',
    borderRadius: 'rounded-md',
    borderStyle: 'border-none',
});

export const customFilterInput = tw.style({
    paddingLeft: "pl-3",
    fontWeight: 'font-medium',
    outlineWidth: "outline-0",
    color: 'text-P3_Black',
    "::placeholder": {
        color: "placeholder:text-P3_Gray4",
        fontWeight: "placeholder:font-medium"
    },
})
