import { ISettlementDto, settlementAPI } from '@entities/Calendar';
import { useEffect, useState } from 'react';

export function useSettlement() {
    const [settlement, setSettlement] = useState<ISettlementDto | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await settlementAPI();
                setSettlement(response.data);
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        };

        fetchData();
    }, []);

    return settlement;
}
