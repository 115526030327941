import { Button, ExcelIcon } from '@shared/ui';
import React from 'react';



export const ExcelDownload = ( ) => {
    return (
        <Button
            size="sm"
            bg="bg-[#27262B]"
            icon={<ExcelIcon />}
        >
            <p className={`text-P3_White`}>엑셀 다운로드</p>
        </Button>
    );
};
