import React from 'react';
import { IManager } from '@entities/CompanyInfo/model/interface';

export const handleLogout = () => {
    // 로그아웃 처리 API 필요
    console.log('로그아웃 처리됨');
    window.location.href = '/user/login';
};

// 빈 text Field가 있어도 추가 가능하게 구현
export const handleNewAdd = (
    managers: IManager[],
    setManagers: React.Dispatch<React.SetStateAction<IManager[]>>,
    name: string,
    contact: string,
    job: string,
) => {
    const newManager = { name, contact, job };
    addManager(managers, setManagers, newManager);
};

export const addManager = (
    managers: IManager[],
    setManagers: React.Dispatch<React.SetStateAction<IManager[]>>,
    newManager: IManager,
) => {
    if (managers.length < 5) {
        const updatedManagers = [...managers, newManager];
        setManagers(updatedManagers);
        localStorage.setItem('managers', JSON.stringify(updatedManagers));
    }
};

export const deleteManager = (
    managers: IManager[],
    setManagers: React.Dispatch<React.SetStateAction<IManager[]>>,
    index: number,
) => {
    const updatedManagers = managers.filter((_, i) => i !== index);
    setManagers(updatedManagers);
    localStorage.setItem('managers', JSON.stringify(updatedManagers));
};
