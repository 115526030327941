import React, { useEffect, useState } from 'react';
import {
    WarningContainer,
    walletBalanceContainer,
    walletBalanceContainerBasic,
} from './walletBalance.styled';
import { Flex } from '../../../shared/ui/flex/Flex';
import { RefreshBtn } from '../../../shared/ui/button/RefreshBtn';
import { WarningIcon } from '../../../shared/ui/icon';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@app/AppStore';
import { getWalletBalance } from '@features/WalletBalance';

export const WalletBalance = () => {
    const [walletBalance, setWalletBalance] = useState<number>(0);
    const [cancelBalance, setCancelBalance] = useState<number>(0);
    const [needCharge, setNeedCharge] = useState<boolean>(false);
    const { balance } = useSelector((state: RootState) => state.wallet);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        setWalletBalance(balance);
    }, [balance]);

    useEffect(() => {
        setCancelBalance(1000);
    }, [cancelBalance]);

    useEffect(() => {
        if (walletBalance <= cancelBalance) {
            setNeedCharge(true);
        } else {
            setNeedCharge(false);
        }
    }, [walletBalance, cancelBalance]);

    const represhHandler = () => {
        dispatch(getWalletBalance());
    };

    return (
        <div className="min-w-[860px]">
            {needCharge ? (
                <div className={walletBalanceContainer.class}>
                    <Flex col>
                        <h1 className="mt-[14px]">지갑 잔액</h1>
                        <span className="flex text-[32px] font-bold">
                            <p className="mr-5">{walletBalance} 원</p>
                            <RefreshBtn onRefresh={represhHandler} />
                        </span>
                    </Flex>
                    <div className={WarningContainer.class}>
                        <Flex col>
                            <span className="flex items-center gap-2">
                                <WarningIcon />
                                <h1 className="text-P3_Black font-bold">
                                    충전이 필요해요.
                                </h1>
                            </span>
                            <p className="text-P3_Black text-sm mt-1">
                                652,000원 (3건) 결제 취소에 실패했어요.
                            </p>
                        </Flex>
                    </div>
                </div>
            ) : (
                <div className={walletBalanceContainerBasic.class}>
                    <h1 className='mt-[14px]'>지갑 잔액</h1>
                    <Flex>
                        <p className="text-[32px] font-bold mr-5">
                            {walletBalance} 원
                        </p>
                        <RefreshBtn onRefresh={represhHandler} />
                    </Flex>
                </div>
            )}
        </div>
    );
};
