import { AxiosRequestHeaders, AxiosResponse } from 'axios';
import { ICeoInfoDto } from '../model/interface';

const ceoInfomationData = {
    ceoInfo: {
        koreaName: '홍길동',
        englishName: 'Hong-Gil-Dong',
        socialSecurityNumber: '240520-3020134',
        phoneNumber: '010-1234-5678',
        address: '우주시 태양구 지구동',
        businessAddress: '우주시 태양구 지구동 가든파이브',
    },
};

export const ceoInfoAPI = async (): Promise<AxiosResponse<ICeoInfoDto>> => {
    return {
        data: ceoInfomationData,
        status: 200,
        statusText: 'OK',
        headers: {},
        config: {
            headers: {} as AxiosRequestHeaders,
        },
    };
};
