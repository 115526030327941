import { IDailyPaymentInfoHistoryArrDto, IDailyPaymentInfoHistoryDto } from '@entities/DailyPaymentInfo';
import React from 'react';

interface IFilterCriteria {
    startDate: string;
    endDate: string;
}

export const filterCheck = (
    DailyHistoryInfo: IDailyPaymentInfoHistoryArrDto,
    filterCriteria: IFilterCriteria,
    setFilteredHistory: React.Dispatch<
        React.SetStateAction<IDailyPaymentInfoHistoryDto[]>
    >,
) => {
    if (!DailyHistoryInfo) return;

    const isDateInRange = (item: IDailyPaymentInfoHistoryDto) => {
        const date = item.tradingDate;
        return (
            filterCriteria.startDate <= date && date <= filterCriteria.endDate
        );
    };

    const filtered = DailyHistoryInfo.DailyHistoryInfo.filter((item) => {
        return isDateInRange(item);
    });
    setFilteredHistory(filtered);
};
