import React from 'react';
import { IFlex } from '@shared/types/flexInterface';

export const Flex = ({
    children,
    col = false,
    className = '',
    justify = 'start',
    items = 'stretch',
    onClick
}: IFlex) => {
    const baseClasses = 'flex';
    const directionClass = col ? 'flex-col' : 'flex-row';
    let justifyClass = '';
    switch (justify) {
        case 'between':
            justifyClass = 'justify-between';
            break;
        case 'around':
            justifyClass = 'justify-around';
            break;
        case 'evenly':
            justifyClass = 'justify-evenly';
            break;
        default:
            justifyClass = `justify-${justify}`;
    }
    const alignClass = `items-${items}`;

    return (
        <div
            className={`${baseClasses} ${directionClass} ${justifyClass} ${alignClass} ${className}`} onClick={onClick}
        >
            {children}
        </div>
    );
};
