import React, { ChangeEvent } from 'react';

export const useNumberLimit = (
    setAmount: React.Dispatch<React.SetStateAction<number | undefined>>,
) => {
    return (e: ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value.replace(/[^0-9,]/g, '');

        if (inputValue === '') {
            setAmount(undefined);
            return;
        }

        if (inputValue.length <= 30) {
            const value = parseInt(inputValue.replace(/,/g, ''), 10);
            // 최대 16자리 (최대 1000조 까지 입력 가능)
            const maxValue = 1000000000000000;
            setAmount(value > maxValue ? maxValue : value);
        } else if (inputValue === '') {
            // 입력 값이 비어 있는 경우
            setAmount(undefined);
        }
    };
};
