import React, { useState } from 'react';
import { paymentHistoryContainer } from './paymentHistory.styled';
import { AnimatePresence, motion } from 'framer-motion';
import { Button, Flex } from '@shared/ui';
import { PHFilter, PHHistory } from '@widgets/PaymentHistory';
import { IPaymentHistoryDto, useHistoryInfo } from '@entities/PaymentHistory';
import { WalletBalance } from '@widgets/WalletBalance';
import { handleCriteria } from '@features/PaymentHistory';

export const PaymentHistory = () => {
    const [filteredHistory, setFilteredHistory] = useState<
        IPaymentHistoryDto[]
    >([]);
    const [filterCriteria, setFilterCriteria] = useState({
        startDate: '',
        endDate: '',
        paymentType: [] as string[],
        withdrawalAccount: {
            account: '',
            bankName: '',
            accountNumber: '',
        },
    });

    const historyInfo = useHistoryInfo();

    return (
        <div className={paymentHistoryContainer.class}>
            <AnimatePresence>
                <motion.div
                    key={location.pathname}
                    initial={{ opacity: 0, y: 1000 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -100 }}
                    transition={{ duration: 0.4 }}
                >
                    <h1 className="text-P3_Black text-[22px] font-bold mb-5">
                        거래내역
                    </h1>
                    <WalletBalance />
                    <PHFilter setFilterCriteria={setFilterCriteria} />
                    <Flex justify="end" className="my-3 min-w-[860px]">
                        <Button
                            size="sm"
                            bg="bg-[#E34922]"
                            color="text-[#FFFFF]"
                            onClick={() => {
                                if (historyInfo)
                                    handleCriteria(
                                        historyInfo,
                                        filterCriteria,
                                        setFilteredHistory,
                                    );
                            }}
                        >
                            조회하기
                        </Button>
                    </Flex>
                    <PHHistory historyInfo={filteredHistory} />
                </motion.div>
            </AnimatePresence>
        </div>
    );
};
