import {
    IPaymentHistoryArrDto,
    IPaymentHistoryDto,
} from '@entities/PaymentHistory';
import { IfilterCheck } from '../model/interface';

export const filterCheck = (
    filterCriteria: IfilterCheck,
    historyInfo: IPaymentHistoryArrDto,
) => {
    if (!historyInfo.historyInfo.length) return [];

    const isDateInRange = (item: IPaymentHistoryDto) => {
        const result =
            filterCriteria.startDate <= item.paymentDate &&
            item.paymentDate <= filterCriteria.endDate;
        return result;
    };

    const isAccountMatch = (item: IPaymentHistoryDto) => {
        const { withdrawalAccount } = filterCriteria;
        const itemAccount = item.withdrawalAccount;

        if (withdrawalAccount.account === '전체') return true;

        const result =
            (withdrawalAccount.account === '' ||
                itemAccount.account === withdrawalAccount.account) &&
            (withdrawalAccount.accountNumber === '' ||
                itemAccount.accountNumber ===
                    withdrawalAccount.accountNumber) &&
            (withdrawalAccount.bankName === '' ||
                itemAccount.bankName === withdrawalAccount.bankName);
        return result;
    };

    const isTypeMatch = (item: IPaymentHistoryDto) => {
        return (
            filterCriteria.paymentType.length === 0 ||
            filterCriteria.paymentType.includes(item.paymentType)
        );
    };

    const filtered = historyInfo.historyInfo.filter((item) => {
        return isDateInRange(item) && isAccountMatch(item) && isTypeMatch(item);
    });

    return filtered;
};
