import React, { useRef, useState } from 'react';
import DayInfo from './DayInfo';
import TodayIndicator from './TodayIndicator';
import { IDayCell } from '@entities/Calendar/model/interfaces';
import { Flex } from '@shared/ui';

import { useModal, useOutsideClick } from '@shared/lib';
import { DetailBtn } from '../../amount/withdrawalAmount/DetailBtn';
import { useDetail } from '@entities/Calendar';

export const DayCell = ({ day, monthStart, todaysInfo }: IDayCell) => {
    const modalRef = useRef<HTMLDivElement>(null);
    const { modalIsOpen, openModal, closeModal } = useModal();
    const [isClosing, setIsClosing] = useState<boolean>(false);

    const detailInfo = useDetail();

    const handleOpenModal = (selectedDate: Date) => {
        console.log('Selected Date:', selectedDate);
        openModal();
    };

    useOutsideClick(
        modalRef,
        () => {
            setIsClosing(true);
            setTimeout(() => {
                setIsClosing(false);
                closeModal();
            }, 200);
        },
        modalIsOpen,
    );

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            setIsClosing(false);
            closeModal();
        }, 200);
    };

    return (
        <>
            <DayInfo
                day={day}
                monthStart={monthStart}
                onDayClick={handleOpenModal}
            >
                {({
                    formattedDate,
                    isSaturday,
                    isSunday,
                    isCurrentMonth,
                    isTodayDate,
                }) => (
                    <div
                        className={`text-start ${isSunday ? `text-P3_Red` : isCurrentMonth ? 'text-P3_Black' : 'text-P3_Gray3'} ${isSaturday ? 'text-P3_PointB' : isCurrentMonth ? 'text-P3_Black' : 'text-P3_Gray3'}`}
                    >
                        <Flex className="mb-3">
                            <Flex>{formattedDate}</Flex>
                            {isTodayDate && <TodayIndicator />}
                        </Flex>
                        <div className="text-P3_PointB text-[16px] font-medium md:text-base">
                            {todaysInfo.sales?.sales
                                ? `${todaysInfo.sales.sales.toLocaleString()} `
                                : '0'}
                        </div>
                        <div className="text-P3_Red text-[16px] font-medium md:text-base">
                            {todaysInfo.settlement?.settlement
                                ? `${todaysInfo.settlement.settlement.toLocaleString()}`
                                : '0'}
                        </div>
                        <div className="text-P3_Gray4 text-[16px] font-medium md:text-base">
                            {todaysInfo.withdraw?.withdraw
                                ? `${todaysInfo.withdraw.withdraw.toLocaleString()}`
                                : '0'}
                        </div>
                    </div>
                )}
            </DayInfo>
            <DetailBtn
                detailInfo={detailInfo}
                handleClose={handleClose}
                modalIsOpen={modalIsOpen}
                isClosing={isClosing}
                modalRef={modalRef}
            />
        </>
    );
};
