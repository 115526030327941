import { historyAPI } from '@entities/Settlement/api/historyAPI';
import { ISettlementHistoryArrDto } from '@entities/Settlement/model/interfaces';
import { useEffect, useState } from 'react';

export const useHistoryInfo = () => {
    const [historyInfo, setHistoryInfo] =
        useState<ISettlementHistoryArrDto | null>(null);

    useEffect(() => {
        const fetch = async () => {
            try {
                const res = await historyAPI();
                console.log(res);
                setHistoryInfo(res.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetch();
    }, []);
    return historyInfo;
};
