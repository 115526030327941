import { tw } from '@shared/lib/Tailwindest';

export const customLabel = tw.style({
    display: 'flex',
    alignItems: 'items-center',
    color: 'text-gray-700',
    fontSize: 'text-sm',
    fontWeight: 'font-medium',
    gap: 'gap-1',
});

export const customInput = tw.style({
    width: 'w-4',
    height: 'h-4',
    color: 'text-blue-600',
    borderRadius: 'rounded',
    borderColor: 'border-gray-300',
});

export const customDateInput = tw.style({
    fontSize: 'text-base',
    fontWeight: 'font-semibold',
    color: 'text-P3_Black',
    paddingY: 'py-1',
    paddingX: 'px-2',
    borderWidth: 'border',
    borderRadius: 'rounded-md',
    borderStyle: 'border-none',
});
