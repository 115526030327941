import React from 'react';
import { IDrawerItem } from '@shared/types';

export const DrawerItem = ({
    name,
    action,
    isSelected = false,
    fontSize = 'text-[18px]',
    color = "text-P3_Black",
    px = 'px-12',
    pb = 'pb-[14px]',
    pt = 'pt-[14px]',
    cursorPointer = true,
}: IDrawerItem) => {
    const cursorClass = cursorPointer ? 'cursor-pointer' : 'cursor-default';
    const selectedClass = isSelected ? 'text-P3_Red font-bold' : '';

    return (
        <div className={`${px} ${pb} ${pt} py-[14px] ${color} ${fontSize} ${cursorClass} rounded-lg ${selectedClass}`}
            onClick={action}
            style={{ paddingInline: px, paddingBottom: pb, fontSize }}
        >
            <div>{name}</div>
        </div>
    );
};


