import React, { useState } from 'react';
import { RefreshIcon } from '../icon/RefreshIcon';

interface RefreshBtnProps {
    onRefresh?: () => void;
}

export const RefreshBtn: React.FC<RefreshBtnProps> = ({ onRefresh }) => {
    const [isRefresh, setIsRefresh] = useState(false);

    const handleClick = () => {
        if (!isRefresh) {
            setIsRefresh(true);
            if (onRefresh) {
                onRefresh();
            }
            setTimeout(() => {
                setIsRefresh(false);
            }, 1000);
        }
    };

    return (
        <button
            onClick={handleClick}
            className="flex items-center gap-3 relative"
        >
            <span
                className={`inline-block transition-transform duration-1000 ${isRefresh ? 'animate-refresh-spin' : ''}`}
            >
                <RefreshIcon />
            </span>
            <p className="text-[#E34922] text-base font-bold">새로고침</p>
        </button>
    );
};
