import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { DrawerItem } from '@features/Drawer';
import { Flex } from '@shared/ui';
import { drawerContainer } from './drawer.styled';



export const DrawerLayout = () => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <Flex>
            <div className={drawerContainer.class}>
                <DrawerItem
                    cursorPointer
                    name="홈"
                    action={() => navigate('/')}
                    isSelected={location.pathname === '/'}
                />
                <DrawerItem
                    cursorPointer
                    name="정산내역"
                    pb="10"
                    action={() => navigate('/settlement')}
                    isSelected={location.pathname === '/settlement'}
                />
                <DrawerItem
                    name="지갑 관리"
                    color="text-P3_Gray5"
                    fontSize="text-base"
                    px="px-9"
                    cursorPointer={false}
                />
                <DrawerItem
                    cursorPointer
                    pt="pt-[6px]"
                    name="거래 내역"
                    action={() => navigate('/payment-history')}
                    isSelected={location.pathname === '/payment-history'}
                />
                <DrawerItem
                    cursorPointer
                    name="출금하기"
                    action={() => navigate('/withdraw')}
                    isSelected={location.pathname === '/withdraw'}
                />
                <DrawerItem
                    cursorPointer
                    name="충전하기"
                    pb="pb-[26px]"
                    action={() => navigate('/charge')}
                    isSelected={location.pathname === '/charge'}
                />
                <DrawerItem
                    name="결제조회"
                    color="text-P3_Gray5"
                    fontSize="text-base"
                    px="px-9"
                    cursorPointer={false}
                />
                <DrawerItem
                    cursorPointer
                    pt="pt-[6px]"
                    name="일자별 결제 조회"
                    action={() => navigate('/daily-payment-info')}
                    isSelected={location.pathname === '/daily-payment-info'}
                />
                <DrawerItem
                    cursorPointer
                    name="건별 결제 조회"
                    pb="pb-[26px]"
                    action={() => navigate('/transaction-payment-info')}
                    isSelected={location.pathname === '/transaction-payment-info'}
                />
                <DrawerItem
                    name="계약,운영"
                    color="text-P3_Gray5"
                    fontSize="text-base"
                    px="px-9"
                    cursorPointer={false}
                />
                <DrawerItem
                    cursorPointer
                    pt="pt-[6px]"
                    name="회사정보"
                    action={() => navigate('/company-info')}
                    isSelected={location.pathname === '/company-info'}
                />
                <DrawerItem
                    cursorPointer
                    name="이용정보"
                    action={() => navigate('/usage-info')}
                    isSelected={location.pathname === '/usage-info'}
                />
                {/* <SidebarItem
                    cursorPointer
                    hoverEffect
                    name="사용자관리"
                    action={() => navigate('/UserManagement')}
                    isSelected={location.pathname === '/UserManagement'}
                /> */}
            </div>
        </Flex>
    );
};

