import React from 'react';
import { formatMonthSales } from '../../../model/salesAmount.model';
import { ITotalAmountDto } from '@entities/Calendar';
import { Flex } from '@shared/ui';

export const SalesAmount = ({
    totalAmountInfo,
    currentDate,
}: ITotalAmountDto) => {
    const monthText = formatMonthSales({ currentDate });

    return (
        <Flex col className="gap-3">
            <span className="text-P3_Black font-semibold">
                {monthText} 매출액
            </span>
            <span className="font-semibold text-xl">
                {' '}
                {totalAmountInfo
                    ? `${totalAmountInfo.monthSales.toLocaleString()} 원`
                    : '데이터 로딩 중...'}
            </span>
        </Flex>
    );
};
