import {
    IPaymentHistoryArrDto,
    IPaymentHistoryDto,
} from '@entities/PaymentHistory';
import React from 'react';
import { IfilterCheck } from '../model/interface';
import { filterCheck } from './filterCheck';

// 거래유형 변경 핸들러
export const handleCriteriaChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setPaymentTypes: React.Dispatch<React.SetStateAction<string[]>>,
) => {
    const { value, checked } = e.target;
    setPaymentTypes((prev) =>
        checked ? [...prev, value] : prev.filter((type) => type !== value),
    );
};

// 조회하기 버튼 핸들러
export const handleCriteria = (
    historyInfo: IPaymentHistoryArrDto,
    filterCriteria: IfilterCheck,
    setFilteredHistory: React.Dispatch<
        React.SetStateAction<IPaymentHistoryDto[]>
    >,
) => {
    if (historyInfo) {
        const filtered = filterCheck(filterCriteria, historyInfo);
        setFilteredHistory(filtered);
    }
};
