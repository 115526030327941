import React, { useRef, useState } from 'react';

import { useModal, useOutsideClick } from '@shared/lib';
import {
    checkboxLayout,
    checkboxStatus,
    modalContainer,
    modalLayout,
    modalTransiton,
    selectBtn,
} from './tableSettingBtn.styled';
import { Button, Flex, SettingIcon } from '@shared/ui';
import { CloseBtnIcon } from '@shared/ui/icon/CloseBtnIcon';

interface ITableSettingBtn {
    onSettingsChange: (checkedValues: string[]) => void;
}

export const TableSettingBtn = ({ onSettingsChange }: ITableSettingBtn) => {
    const modalRef = useRef<HTMLDivElement>(null);
    const { modalIsOpen, openModal, closeModal } = useModal();
    const [isClosing, setIsClosing] = useState<boolean>(false);

    useOutsideClick(
        modalRef,
        () => {
            setIsClosing(true);
            setTimeout(() => {
                setIsClosing(false);
                closeModal();
            }, 200);
        },
        modalIsOpen,
    );

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            setIsClosing(false);
            closeModal();
        }, 200);
    };
    const allValues = [
        '정산날짜',
        '결제+취소건수',
        '매출액',
        'PG수수료',
        'PG부가세',
        'PG수수료 합',
        '정산액',
        '출금액',
        '지갑잔고',
    ];

    const [checkedItems, setCheckedItems] = useState<string[]>(allValues);
    const [changeItems, setChangeItems] = useState<string[]>(allValues);

    const handleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const { value, checked } = event.target;
        let updatedCheckedItems;

        if (checked) {
            updatedCheckedItems = [...checkedItems, value];
        } else {
            updatedCheckedItems = checkedItems.filter((item) => item !== value);
        }

        setCheckedItems(updatedCheckedItems);
        setChangeItems(updatedCheckedItems);
    };

    const applyHandler = () => {
        onSettingsChange(changeItems);
        closeModal();
    };

    const selectAll = () => {
        setCheckedItems(allValues);
        setChangeItems(allValues);
    };

    const deselectAll = () => {
        setCheckedItems([]);
        setChangeItems([]);
    };

    return (
        <div>
            <Button
                size="sm"
                bg="bg-[#27262B]"
                color="text-[#FFFFF]"
                onClick={() => {
                    openModal();
                }}
                icon={<SettingIcon />}
            >
                <p className="content-center">표 설정</p>
            </Button>
            <div
                className={`${modalIsOpen && !isClosing ? 'opacity-100 ' : 'opacity-0'} ${modalTransiton.class}`}
            >
                {modalIsOpen && (
                    <div className={modalLayout.class}>
                        <div ref={modalRef} className={modalContainer.class}>
                            <div className="w-[100%] h-[100%]">
                                <Flex
                                    justify="between"
                                    className="mb-2"
                                    onClick={handleClose}
                                >
                                    <h1 className="text-xl flex items-end font-bold">
                                        표 설정
                                    </h1>
                                    <CloseBtnIcon />
                                </Flex>

                                <p className="text-lg font-medium mb-8 text-P3_Gray5">
                                    보고 싶은 항목을 선택해주세요
                                </p>
                                <Flex className="gap-5" justify="end">
                                    <button
                                        className={`${selectBtn.class}`}
                                        onClick={selectAll}
                                    >
                                        전체 선택
                                    </button>
                                    <button
                                        className={selectBtn.class}
                                        onClick={deselectAll}
                                    >
                                        전체 해제
                                    </button>
                                </Flex>
                                <div className={checkboxLayout.class}>
                                    <Flex items="center">
                                        <input
                                            type="checkbox"
                                            id="정산날짜"
                                            value="정산날짜"
                                            checked={checkedItems.includes(
                                                '정산날짜',
                                            )}
                                            onChange={handleCheckboxChange}
                                            className={checkboxStatus.class}
                                        />
                                        <label htmlFor="정산날짜">
                                            정산날짜
                                        </label>
                                    </Flex>
                                    <Flex items="center">
                                        <input
                                            type="checkbox"
                                            id="결제+취소건수"
                                            value="결제+취소건수"
                                            checked={checkedItems.includes(
                                                '결제+취소건수',
                                            )}
                                            onChange={handleCheckboxChange}
                                            className={checkboxStatus.class}
                                        />
                                        <label htmlFor="결제+취소건수">
                                            결제+취소건수
                                        </label>
                                    </Flex>
                                    <Flex items="center">
                                        <input
                                            type="checkbox"
                                            id="매출액"
                                            value="매출액"
                                            checked={checkedItems.includes(
                                                '매출액',
                                            )}
                                            onChange={handleCheckboxChange}
                                            className={checkboxStatus.class}
                                        />
                                        <label htmlFor="매출액">매출액</label>
                                    </Flex>
                                    <Flex items="center">
                                        <input
                                            type="checkbox"
                                            id="PG수수료"
                                            value="PG수수료"
                                            checked={checkedItems.includes(
                                                'PG수수료',
                                            )}
                                            onChange={handleCheckboxChange}
                                            className={checkboxStatus.class}
                                        />
                                        <label htmlFor="PG수수료">
                                            PG수수료
                                        </label>
                                    </Flex>
                                    <Flex items="center">
                                        <input
                                            type="checkbox"
                                            id="PG부가세"
                                            value="PG부가세"
                                            checked={checkedItems.includes(
                                                'PG부가세',
                                            )}
                                            onChange={handleCheckboxChange}
                                            className={checkboxStatus.class}
                                        />
                                        <label htmlFor="PG부가세">
                                            PG부가세
                                        </label>
                                    </Flex>
                                    <Flex items="center">
                                        <input
                                            type="checkbox"
                                            id="PG수수료 합"
                                            value="PG수수료 합"
                                            checked={checkedItems.includes(
                                                'PG수수료 합',
                                            )}
                                            onChange={handleCheckboxChange}
                                            className={checkboxStatus.class}
                                        />
                                        <label htmlFor="PG수수료 합">
                                            PG수수료 합
                                        </label>
                                    </Flex>
                                    <Flex items="center">
                                        <input
                                            type="checkbox"
                                            id="정산액"
                                            value="정산액"
                                            checked={checkedItems.includes(
                                                '정산액',
                                            )}
                                            onChange={handleCheckboxChange}
                                            className={checkboxStatus.class}
                                        />
                                        <label htmlFor="정산액">정산액</label>
                                    </Flex>
                                    <Flex items="center">
                                        <input
                                            type="checkbox"
                                            id="출금액"
                                            value="출금액"
                                            checked={checkedItems.includes(
                                                '출금액',
                                            )}
                                            onChange={handleCheckboxChange}
                                            className={checkboxStatus.class}
                                        />
                                        <label htmlFor="출금액">출금액</label>
                                    </Flex>
                                    <Flex items="center">
                                        <input
                                            type="checkbox"
                                            id="지갑잔고"
                                            value="지갑잔고"
                                            checked={checkedItems.includes(
                                                '지갑잔고',
                                            )}
                                            onChange={handleCheckboxChange}
                                            className={checkboxStatus.class}
                                        />
                                        <label htmlFor="지갑잔고">
                                            지갑잔고
                                        </label>
                                    </Flex>
                                </div>
                                <Button
                                    size="lg"
                                    onClick={() => {
                                        applyHandler();
                                    }}
                                    className="w-full"
                                >
                                    적용하기
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
