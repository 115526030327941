import React from 'react';
import { Flex } from '@shared/ui';
import { handleLogout } from '@features/CompanyInfo/lib/handlers';

export const Logout = () => {
    return (
        <Flex justify="end">
            <button onClick={handleLogout}>
                <p className="text-P3_Red font-semibold underline">로그아웃</p>
            </button>
        </Flex>
    );
};
