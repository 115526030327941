import { startOfMonth, endOfMonth, startOfWeek, endOfWeek } from 'date-fns';
import { ICalendarUtils } from '@shared/types';

export const CalendarUtils = (currentDate: Date): ICalendarUtils => {
    const monthStart = startOfMonth(currentDate);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);
    return { monthStart, monthEnd, startDate, endDate };
};
