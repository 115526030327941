import React, { useEffect, useRef, useState } from 'react';
import {
    dropdownUl,
    inBoxContainer,
    inBoxContainer2,
    withdrawHopeContainer,
} from './withdrawHope.styled';
import { DropdownGrayIcon, Flex } from '@shared/ui';
import { WithdrawBtn } from '@features/Withdraw';
import { useDropdown, useNumberLimit, useOutsideClick } from '@shared/lib';
import {
    IWithdrawalAccountArrDto,
    IWithdrawalAccountDto,
} from '@entities/Withdraw';

export const WithdrawHope = ({
    savedAccountInfo,
}: IWithdrawalAccountArrDto) => {
    const [account, setAccount] = useState<IWithdrawalAccountDto>({
        account: '',
        bankName: '',
        bankNumber: '',
    });
    const [amount, setAmount] = useState<number | undefined>();
    const resetState = () => {
        setAccount({
            account: '전체',
            bankName: '',
            bankNumber: '',
        });
        setAmount(0);
    };

    const ref = useRef<HTMLDivElement>(null);
    const onChangeHandler = useNumberLimit(setAmount);
    const {
        dropdownIsOpen: isDropdownOpen,
        toggleDropdown,
        selectItem,
        selectedItem,
    } = useDropdown<IWithdrawalAccountDto>();

    useOutsideClick(ref, toggleDropdown, isDropdownOpen);

    useEffect(() => {
        // 실제 계좌 API로 가져와서 넣어야 함
        if (selectedItem) {
            setAccount(selectedItem);
        }
    }, [selectedItem]);

    // 임시 코드 (레거시)
    useEffect(() => {
        selectItem(savedAccountInfo[0]);
    }, []);

    return (
        <Flex className={withdrawHopeContainer.class}>
            <Flex col className="w-[50%] mr-[48px]">
                <p className="text-P3_Gray5 mb-3">출금 희망 계좌</p>
                <button
                    className={inBoxContainer.class}
                    onClick={toggleDropdown}
                >
                    <p className="text-P3_Black text-lg">{account.account}</p>
                    <DropdownGrayIcon />
                </button>
                {isDropdownOpen && (
                    <div className="sticky" ref={ref}>
                        <ul className={dropdownUl.class}>
                            {savedAccountInfo.map((d, index) => (
                                <li
                                    key={index}
                                    className={`font-medium text-lg mb-5 cursor-pointer ${
                                        selectedItem &&
                                        d.account === selectedItem.account &&
                                        d.bankName === selectedItem.bankName &&
                                        d.bankNumber === selectedItem.bankNumber
                                            ? 'text-P3_Black'
                                            : 'text-P3_Gray4'
                                    }`}
                                    onClick={() =>
                                        selectItem({
                                            account: d.account,
                                            bankName: d.bankName,
                                            bankNumber: d.bankNumber,
                                        })
                                    }
                                >
                                    {d.account}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </Flex>
            <Flex col className="w-[50%]">
                <label className="text-P3_Gray5" htmlFor="">
                    출금 희망 금액
                </label>
                <Flex className={inBoxContainer2.class}>
                    <input
                        className="w-[100%] items-center text-end text-lg mr-[17px] focus:outline-none"
                        type="text"
                        value={
                            amount !== undefined ? amount.toLocaleString() : ''
                        }
                        onChange={onChangeHandler}
                        placeholder="금액을 입력해 주세요."
                        maxLength={30}
                    />
                    <p className="font-bold text-lg">원</p>
                </Flex>
            </Flex>
            <Flex justify="center" items="end">
                {amount ? (
                    <WithdrawBtn resetState={resetState} reqAmount={amount} />
                ) : (
                    <WithdrawBtn resetState={resetState} />
                )}
            </Flex>
        </Flex>
    );
};
