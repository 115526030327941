import { useModal, useOutsideClick } from '@shared/lib';
import React, { useRef, useState } from 'react';
import {
    h1Text,
    modalContainer,
    modalLayout,
    modalTransiton,
    pText,
} from './status.styled';
import { Flex, Text } from '@shared/ui';

export const StatusHandler = ({ data }: { data: string }) => {
    const modalRef = useRef<HTMLDivElement>(null);
    const { modalIsOpen, openModal, closeModal } = useModal();
    const [isClosing, setIsClosing] = useState<boolean>(false);

    useOutsideClick(
        modalRef,
        () => {
            setIsClosing(true);
            setTimeout(() => {
                setIsClosing(false);
                closeModal();
            }, 200);
        },
        modalIsOpen,
    );

    const completeHandler = () => {
        openModal();
    };

    const closeHandler = () => {
        setIsClosing(true);
        setTimeout(() => {
            setIsClosing(false);
            closeModal();
        }, 200);
    };

    const renderCompleteModal = () => (
        <div
            className={`${modalIsOpen && !isClosing ? 'opacity-100 ' : 'opacity-0'} ${modalTransiton.class}`}
        >
            <div className={modalLayout.class}>
                <div ref={modalRef} className={modalContainer.class}>
                    <div className="w-[100%] h-[100%]">
                        <Flex justify="between" onClick={closeHandler}>
                            <h1 className="flex items-end font-bold text-xl">
                                충전 신청 완료
                            </h1>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                                fill="none"
                            >
                                <path
                                    d="M30 10L10 30"
                                    stroke="#6C6D5D"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M10 10L30 30"
                                    stroke="#6C6D5D"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </Flex>
                        <div className="my-12">
                            <Flex>
                                <h1 className={h1Text.class}>입금계좌</h1>
                                <p className={pText.class}>
                                    {'국민 331231-01-542132'}
                                </p>
                            </Flex>
                            <Flex>
                                {/* 신청일시 API로 가져와서 담아야함 */}
                                <h1 className={h1Text.class}>신청일시</h1>
                                <p className={pText.class}>
                                    {'2024-06-13 14:23'}
                                </p>
                            </Flex>
                            <Flex>
                                {/* API로 가져온 신청일시 값에 +24시간 더해서 뿌려줘야 함 */}
                                <h1 className={h1Text.class}>입금기한</h1>
                                <p className={pText.class}>
                                    {'2024-07-08 17:00까지'}
                                </p>
                            </Flex>
                            <Flex>
                                <h1 className={h1Text.class}>충전금액</h1>
                                <p className={pText.class}>{'203,042,123'}</p>
                            </Flex>
                            <Flex>
                                <h1 className={h1Text.class}>입금자명</h1>
                                <p className={pText.class}>
                                    {'바이올렛페이 주식회사'}
                                </p>
                            </Flex>
                        </div>
                        <Text size="sm">
                            ※ 기간 내 입금이 완료되지 않을 시 충전이 실패돼요.
                        </Text>
                    </div>
                </div>
            </div>
        </div>
    );
    const renderFailModal = () => (
        <div
            className={`${modalIsOpen && !isClosing ? 'opacity-100 ' : 'opacity-0'} ${modalTransiton.class}`}
        >
            <div className={modalLayout.class}>
                <div ref={modalRef} className={modalContainer.class}>
                    <div className="w-[100%] h-[100%]">
                        <Flex justify="between" onClick={closeHandler}>
                            <h1 className="flex items-end font-bold text-xl">
                                충전 실패
                            </h1>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                                fill="none"
                            >
                                <path
                                    d="M30 10L10 30"
                                    stroke="#6C6D5D"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M10 10L30 30"
                                    stroke="#6C6D5D"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </Flex>
                        <div className="my-12">
                            <Flex>
                                <h1 className={h1Text.class}>입금계좌</h1>
                                <p className={pText.class}>
                                    {'국민 331231-01-542132'}
                                </p>
                            </Flex>
                            <Flex>
                                <h1 className={h1Text.class}>신청일시</h1>
                                <p className={pText.class}>
                                    {'2024-06-13 14:23'}
                                </p>
                            </Flex>
                            <Flex>
                                <h1 className={h1Text.class}>입금기간</h1>
                                <p className={pText.class}>
                                    {'2024-07-08 17:00까지'}
                                </p>
                            </Flex>
                            <Flex>
                                <h1 className={h1Text.class}>충전금액</h1>
                                <p className={pText.class}>{'203,042,123'}</p>
                            </Flex>
                            <Flex>
                                <h1 className={h1Text.class}>입금자명</h1>
                                <p className={pText.class}>
                                    {'바이올렛페이 주식회사'}
                                </p>
                            </Flex>
                        </div>
                        <Text size="sm">※ 고객센터 02-0000-0000</Text>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <>
            {data === '입금대기' ? (
                <>
                    <button
                        className="text-P3_Red underline cursor-pointer"
                        onClick={completeHandler}
                    >
                        {data}
                    </button>
                    {modalIsOpen && renderCompleteModal()}
                </>
            ) : data === '충전실패' ? (
                <>
                    <button
                        className="text-P3_Red underline cursor-pointer"
                        onClick={completeHandler}
                    >
                        {data}
                    </button>
                    {modalIsOpen && renderFailModal()}
                </>
            ) : data === '충전완료' ? (
                <p className="text-P3_Black">{data}</p>
            ) : (
                <p>{data}</p>
            )}
        </>
    );
};
