import { useEffect, useState } from 'react';
import { IWithdrawalAccountArrDto } from '../model/interface';
import { savedAccountAPI } from '../api/savedAccountAPI';

export const useSavedAccountInfo = () => {
    const [withdrawalAccountInfo, setWithdrawalAccountInfo] =
        useState<IWithdrawalAccountArrDto | null>(null);

    useEffect(() => {
        const fetch = async () => {
            try {
                const res = await savedAccountAPI();
                console.log(res);
                setWithdrawalAccountInfo(res.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetch();
    }, []);
    return withdrawalAccountInfo;
};
