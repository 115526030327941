import { historyAPI } from '@entities/DailyPaymentInfo/api/dailyPaymentInfoAPI';
import { IDailyPaymentInfoHistoryArrDto } from '@entities/DailyPaymentInfo/model/interfaces';
import { useEffect, useState } from 'react';

export function useHistoryInfo() {
    const [amountData, setAmountData] = useState<IDailyPaymentInfoHistoryArrDto | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await historyAPI();
                setAmountData(response.data);
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        };
        fetchData();
    }, [amountData]);

    return amountData;
}
