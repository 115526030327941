import {
    IPaymentHistoryArrDto,
    IPaymentHistoryDto,
} from '@entities/PaymentHistory';
import { handlePaginate } from '@shared/lib';
import { formatNumber } from '@shared/lib/FormatNumber';
import {
    Flex,
    PaginatedList,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from '@shared/ui';
import React, { useState } from 'react';

export const PHHistory = ({ historyInfo }: IPaymentHistoryArrDto) => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const totalPages = Math.ceil(historyInfo.length / itemsPerPage);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const slicedHistoryInfo = historyInfo.slice(indexOfFirstItem, indexOfLastItem);

    const tableComponent = (historyInfo: IPaymentHistoryDto[]) => {
        return (
            <Table className="mt-[44px]">
                <Thead>
                    <Tr>
                        <Th className="py-[10px]">거래 일시</Th>
                        <Th>거래구분</Th>
                        <Th>거래 금액</Th>
                        <Th>거래 후 잔액</Th>
                        <Th>출금계좌</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {historyInfo.map((data, index) => (
                        <Tr className="text-end text-[14px]" key={index}>
                            <Td className="text-center">{data.paymentDate}</Td>
                            <Td className="text-center">{data.paymentType}</Td>

                            <Td>{formatNumber(data.paymentAmount)}</Td>

                            <Td>{formatNumber(data.paymentAfterBalance)}</Td>

                            <Td className="text-center">
                                <p>{data.withdrawalAccount.account}</p>
                                <Flex className="justify-center text-P3_Gray4 text-[14px]">
                                    <p>{data.withdrawalAccount.bankName}</p>
                                    <p>
                                        {data.withdrawalAccount.accountNumber}
                                    </p>
                                </Flex>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        );
    };
    return (
        <div className="overflow-auto min-w-[860px]">
            <ul>{tableComponent(slicedHistoryInfo)}</ul>
            <PaginatedList
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={(pageNumber) =>
                    handlePaginate(pageNumber, totalPages, setCurrentPage)
                }
            />
        </div>
    );
};
