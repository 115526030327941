import { AxiosResponse, AxiosRequestHeaders } from 'axios';
import { IDailyPaymentInfoHistoryArrDto } from '../model/interfaces';



const DailyPaymentInfoData: IDailyPaymentInfoHistoryArrDto = {
    DailyHistoryInfo: [
        {
            tradingDate: '2024-06-06',
            paymentCancelTransactionCount: 431,
            paymentCancleAmountsCount: 531333,
            transaction: 3000,
            amount: 56000,
        }
    ]
};


export const historyAPI = async (): Promise<AxiosResponse<IDailyPaymentInfoHistoryArrDto>> => {
    return {
        data: DailyPaymentInfoData,
        status: 200,
        statusText: 'OK',
        headers: {},
        config: {
            headers: {} as AxiosRequestHeaders,
        },
    };
};
