import { tw } from '@shared/lib/Tailwindest';


export const modalTransiton = tw.style({
    transitionProperty: "transition-opacity",
    transitionDuration: "duration-200",
    transitionTimingFunction: "ease-in-out",
    position: "fixed",
    zIndex: "z-50"
})

export const modalLayout = tw.style({
    display: 'flex',
    alignItems: 'items-center',
    justifyContent: 'justify-center',
    position: 'fixed',
    inset: 'inset-0',
    zIndex: 'z-40',
    backgroundColor: 'bg-P3_bg_cloudy',
});

export const modalContainer = tw.style({
    display: 'flex',
    alignItems: 'items-center',
    justifyContent: 'justify-center',
    textAlign: 'text-start',
    minWidth: "min-w-[387px]",
    borderWidth: 'border',
    overflow: "overflow-auto",
    backgroundColor: 'bg-white',
    borderRadius: 'rounded-xl',
    zIndex: 'z-50',
    padding: "p-10"
});


export const selectBtn = tw.style({
    color: 'text-P3_Red',
    fontWeight: "font-bold",
    textDecorationLine: 'underline',
});

export const checkboxLayout = tw.style({
    marginTop: "mt-5",
    marginBottom: "mb-8",
    spaceY: "space-y-2",
    fontSize: "text-lg",
    fontWeight: "font-semibold",
    color: "text-P3_Black"
});

export const checkboxStatus = tw.style({
    marginRight: "mr-2",
    width: "w-6",
    height: "h-6",
    appearance: "appearance-none",
    backgroundColor: "bg-P3_Gray2",
    borderRadius: "rounded",
    ":checked": {
        backgroundColor: "checked:bg-[url('/public/images/checkbox.svg')]"
    }
});