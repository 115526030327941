import React from 'react';
import { CurrentMonth, NextBtn, PrevBtn } from '@features/Calendar';
import { ICalendarHeader } from '@shared/types';
import { Flex } from '@shared/ui/flex/Flex';
import { Spacer } from '@shared/ui';

export const CalendarHeader = ({
    currentDate,
    setCurrentDate,
}: ICalendarHeader) => {
    return (
        <Flex justify="between" items="center" className="gap-[22px] mb-8">
            <CurrentMonth
                currentDate={currentDate}
                setCurrentDate={setCurrentDate}
            />

            <PrevBtn
                currentDate={currentDate}
                setCurrentDate={setCurrentDate}
            />
            <NextBtn
                currentDate={currentDate}
                setCurrentDate={setCurrentDate}
            />

            <Spacer />
        </Flex>
    );
};
