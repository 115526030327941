import { tw } from '@shared/lib/Tailwindest';

export const modalTransiton = tw.style({
    transitionProperty: "transition-opacity",
    transitionDuration: "duration-200",
    transitionTimingFunction: "ease-in-out",
    position: "fixed",
    zIndex: "z-50"
})

export const modalLayout = tw.style({
    display: 'flex',
    alignItems: 'items-center',
    justifyContent: 'justify-center',
    position: 'fixed',
    inset: 'inset-0',
    zIndex: 'z-40',
    backgroundColor: 'bg-P3_bg_cloudy',
});

export const modalContainer = tw.style({
    display: 'flex',
    alignItems: 'items-center',
    justifyContent: 'justify-center',
    textAlign: 'text-start',
    minWidth: "min-w-[372px]",
    borderWidth: 'border',
    overflow: "overflow-auto",
    backgroundColor: 'bg-white',
    borderRadius: 'rounded-xl',
    zIndex: 'z-50',
    padding: "p-10"
});