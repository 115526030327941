import { AxiosRequestHeaders, AxiosResponse } from 'axios';
import { IChargeHistoryArrDto } from '../model/interface';

const mockData = {
    chargeHistoryInfo: [
        {
            requestDate: '2024-06-13 14:23:12',
            chargeDate: '2024-06-14 14:00:00',
            balance: 203042123,
            status: '입금대기',
        },
        {
            requestDate: '2024-06-14 14:23:12',
            chargeDate: '2024-06-15 14:00:00',
            balance: 203042123,
            status: '충전완료',
        },
        {
            requestDate: '2024-06-15 14:23:12',
            chargeDate: '2024-06-16 14:00:00',
            balance: 203042123,
            status: '충전실패',
        },
        {
            requestDate: '2024-06-16 14:23:12',
            chargeDate: '2024-06-17 14:00:00',
            balance: 203042123,
            status: '입금대기',
        },
        {
            requestDate: '2024-06-13 14:23:12',
            chargeDate: '2024-06-14 14:00:00',
            balance: 203042123,
            status: '입금대기',
        },
        {
            requestDate: '2024-06-14 14:23:12',
            chargeDate: '2024-06-15 14:00:00',
            balance: 203042123,
            status: '충전완료',
        },
        {
            requestDate: '2024-06-15 14:23:12',
            chargeDate: '2024-06-16 14:00:00',
            balance: 203042123,
            status: '충전실패',
        },
        {
            requestDate: '2024-06-16 14:23:12',
            chargeDate: '2024-06-17 14:00:00',
            balance: 203042123,
            status: '입금대기',
        },
    ],
};

export const historyAPI = async (): Promise<
    AxiosResponse<IChargeHistoryArrDto>
> => {
    return {
        data: mockData,
        status: 200,
        statusText: 'OK',
        headers: {},
        config: {
            headers: {} as AxiosRequestHeaders,
        },
    };
};
