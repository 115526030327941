import React, { useEffect, useState } from 'react';
import {
    CalendarIcon,
    HyphenIcon,
    Table,
    Tbody,
    Td,
    Th,
    Tr,
} from '@shared/ui';

import { customDateInput, customFilterInput } from './transactionPaymentInfoFilter.styled';
import { CheckInput } from '@shared/ui/input/CheckInput';
import { useDateChange } from '@shared/lib';
import { handleDateClick } from '@shared/lib/handler';
import { ITransactionPaymentInfoFilter } from '@features/TransactionPaymentInfo';

export const TransactionPaymentInfoFilter = ({ setFilterCriteria }: ITransactionPaymentInfoFilter) => {
    const { startDate, setStartDate, endDate, setEndDate, changeHandler } =
        useDateChange();
    const [buyerName, setBuyerName] = useState('');
    const [buyerId, setBuyerId] = useState('');
    const [paymentAmount, setPaymentAmount] = useState('')


    useEffect(() => {
        setFilterCriteria({
            startDate,
            endDate,
            buyerName,
            buyerId,
            paymentAmount,
        });
    }, [startDate, endDate, buyerName, buyerId, paymentAmount]);

    return (
        <div className='min-w-[1080px]'>
            <Table>
                <Tbody>
                    <Tr className="border-P3_Gray4">
                        <Th className=" text-start border-none w-[14%]">
                            <label className="py-[10px] pl-[20px] pr-[64px] text-[16px] font-medium text-P3_Black">
                                조회기간
                            </label>
                        </Th>
                        <Td className="border-none  flex items-center">
                            <div className="flex ml-[15px]  items-center">
                                <CalendarIcon />
                                <input
                                    type="date"
                                    value={startDate}
                                    onChange={(e) =>
                                        setStartDate(e.target.value)
                                    }
                                    onClick={handleDateClick}
                                    className={`${customDateInput.class} hide-calendar-icon ml-[22px] `}
                                />
                            </div>
                            <span className="flex justify-center items-center ">
                                <HyphenIcon />
                            </span>
                            <div className="flex ml-[22px]">
                                <input
                                    type="date"
                                    value={endDate}
                                    onChange={(e) =>
                                        setEndDate(e.target.value)
                                    }
                                    onClick={handleDateClick}
                                    className={`${customDateInput.class} hide-calendar-icon`}
                                />
                            </div>
                            <div className='flex gap-6'>
                                <label className="flex items-center">
                                    <CheckInput
                                        input_type="radio"
                                        input_name="query_period"
                                        input_value="yesterday"
                                        input_onChange={changeHandler}
                                    />
                                    <span className="ml-2 text-base font-medium text-P3_Black">
                                        어제
                                    </span>
                                </label>

                                <label className="flex items-center">
                                    <CheckInput
                                        input_type="radio"
                                        input_name="query_period"
                                        input_value="lastWeek"
                                        input_onChange={changeHandler}
                                    />
                                    <span className="ml-2 text-base font-medium text-P3_Black">
                                        지난주
                                    </span>
                                </label>

                                <label className="flex items-center">
                                    <CheckInput
                                        input_type="radio"
                                        input_name="query_period"
                                        input_value="lastMonth"
                                        input_onChange={changeHandler}
                                    />
                                    <span className="ml-2 text-base font-medium text-P3_Black">
                                        지난달
                                    </span>
                                </label>

                                <label className="flex items-center">
                                    <CheckInput
                                        input_type="radio"
                                        input_name="query_period"
                                        input_value="thisMonth"
                                        input_onChange={changeHandler}
                                        defaultChecked
                                    />
                                    <span className="ml-2 text-base font-medium text-P3_Black">
                                        이번달
                                    </span>
                                </label>
                            </div>
                        </Td>
                    </Tr>
                </Tbody>
            </Table>
            <Table>
                <Tbody>
                    <Tr className="border-P3_Gray4 border-t-0">
                        <Th className=" text-start border-none w-[14%]">
                            <label className="py-[10px] pl-[20px] pr-[64px] text-[16px] font-medium text-P3_Black">
                                결제상태
                            </label>
                        </Th>

                        <Td className="border-none flex gap-6 ">
                            <label className="flex items-center  ml-[15px]">
                                <CheckInput
                                    input_type="radio"
                                    input_name="query_checkStandard"
                                    defaultChecked
                                />
                                <span className="ml-2 text-base font-medium text-P3_Black">
                                    완료
                                </span>
                            </label>

                            <label className="flex items-center">
                                <CheckInput
                                    input_type="radio"
                                    input_name="query_checkStandard"
                                />
                                <span className="ml-2 text-base font-medium text-P3_Black">
                                    취소
                                </span>
                            </label>

                            <label className="flex items-center">
                                <CheckInput
                                    input_type="radio"
                                    input_name="query_checkStandard"
                                />
                                <span className="ml-2 text-base font-medium text-P3_Black">
                                    부분취소
                                </span>
                            </label>

                            <label className="flex items-center">
                                <CheckInput
                                    input_type="radio"
                                    input_name="query_checkStandard"
                                />
                                <span className="ml-2 text-base font-medium text-P3_Black">
                                    취소 요청
                                </span>
                            </label>

                            <label className="flex items-center">
                                <CheckInput
                                    input_type="radio"
                                    input_name="query_checkStandard"
                                />
                                <span className="ml-2 text-base font-medium text-P3_Black">
                                    취소 실패
                                </span>
                            </label>
                        </Td>
                    </Tr>
                </Tbody>
            </Table>
            <Table>
                <Tbody>
                    <Tr className="border-P3_Gray4 w-[100%] border-t-0">
                        <Th className=" text-start border-none w-[14%]">
                            <label className="py-[10px] pl-[20px] pr-[64px] text-[16px] font-medium text-P3_Black">
                                구매자명
                            </label>
                        </Th>
                        <Td className="border-none  flex items-center ">
                            <input
                                placeholder='입력'
                                maxLength={30}
                                className={`${customFilterInput.class}`}
                                value={buyerName}
                                onChange={(e) => setBuyerName(e.target.value)}
                            />
                        </Td>

                        <Th className=" text-start border-none w-[14%]">
                            <label className="py-[10px] pl-[20px] pr-[32px] text-[16px] font-medium text-P3_Black">
                                구매자 아이디
                            </label>
                        </Th>
                        <Td className=" border-none flex items-center">
                            <input
                                placeholder='입력'
                                maxLength={30}
                                className={`${customFilterInput.class}`}
                                value={buyerId}
                                onChange={(e) => setBuyerId(e.target.value)}
                            />
                        </Td>
                        <Th className=" text-start border-none w-[14%]">
                            <label className="py-[10px] pl-[20px] pr-[64px] text-[16px] font-medium text-P3_Black">
                                결제액
                            </label>
                        </Th>
                        <Td className=" border-none flex items-center">
                            <input
                                placeholder='입력'
                                maxLength={30}
                                className={`${customFilterInput.class}`}
                                value={paymentAmount}
                                onChange={(e) => setPaymentAmount(e.target.value)}
                            />
                        </Td>
                    </Tr>
                </Tbody>
            </Table>
        </div>
    );
};
