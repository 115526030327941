import React from 'react';

export const InfoIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
        >
            <path
                d="M7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35786 11.6421 0 7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15Z"
                fill="#C3C4BC"
            />
            <path
                d="M5.76929 5.76922C5.76929 5.42691 5.8708 5.09228 6.06097 4.80766C6.25115 4.52303 6.52146 4.3012 6.83772 4.1702C7.15398 4.0392 7.50198 4.00493 7.83771 4.07171C8.17345 4.13849 8.48184 4.30333 8.7239 4.54538C8.96595 4.78744 9.13079 5.09583 9.19757 5.43157C9.26435 5.7673 9.23008 6.1153 9.09908 6.43156C8.96808 6.74782 8.74624 7.01812 8.46162 7.2083C8.177 7.39848 7.84237 7.49999 7.50006 7.49999V8.65384"
                stroke="#F1F1EE"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.50001 11.5386C7.3407 11.5386 7.21155 11.4094 7.21155 11.2501C7.21155 11.0908 7.3407 10.9617 7.50001 10.9617"
                stroke="#F1F1EE"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.5 11.5386C7.65931 11.5386 7.78846 11.4094 7.78846 11.2501C7.78846 11.0908 7.65931 10.9617 7.5 10.9617"
                stroke="#F1F1EE"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
