import React from 'react';

export const CopyIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
        >
            <g clipPath="url(#clip0_331_3542)">
                <path
                    d="M6.78575 2.5H1.07146C0.676974 2.5 0.357178 2.8198 0.357178 3.21429V8.92857C0.357178 9.32306 0.676974 9.64286 1.07146 9.64286H6.78575C7.18024 9.64286 7.50004 9.32306 7.50004 8.92857V3.21429C7.50004 2.8198 7.18024 2.5 6.78575 2.5Z"
                    fill="#E34922"
                />
                <path
                    d="M2.85718 0.714233H8.64289C8.81339 0.714233 8.9769 0.789488 9.09746 0.923443C9.21802 1.0574 9.28575 1.23908 9.28575 1.42852V7.85709"
                    stroke="#E34922"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_331_3542">
                    <rect width="10" height="10" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
