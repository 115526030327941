import { AxiosRequestHeaders, AxiosResponse } from 'axios';
import { IWalletBalance } from '../model/interface';

const mockData = {
    amount: 0,
};

setInterval(() => {
    mockData.amount += 1;
    // clearInterval(IntervalId);
}, 1000);

export const walletBalanceAPI = async (): Promise<
    AxiosResponse<IWalletBalance>
> => {
    return {
        data: mockData,
        status: 200,
        statusText: 'OK',
        headers: {},
        config: {
            headers: {} as AxiosRequestHeaders,
        },
    };
};
