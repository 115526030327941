import { AxiosRequestHeaders, AxiosResponse } from 'axios';
import { ISalesDto } from '../model/interfaces';

const salesData: ISalesDto = {
    sales: 10000,
};

export const salesAPI = async (): Promise<AxiosResponse<ISalesDto>> => {
    return {
        data: salesData,
        status: 200,
        statusText: 'OK',
        headers: {},
        config: {
            headers: {} as AxiosRequestHeaders,
        },
    };
};
