import React from 'react';
import { ICalendarHeader } from '@shared/types';
import { nextMonth } from '@features/Calendar/model/nextMonthBtn';

export const NextBtn = ({
    currentDate,
    setCurrentDate,
}: ICalendarHeader) => {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        nextMonth({ ...e, currentDate, setCurrentDate });
    };
    return (
        <button onClick={handleClick}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
            >
                <rect
                    x="32"
                    y="32"
                    width="32"
                    height="32"
                    rx="4"
                    transform="rotate(-180 32 32)"
                    fill="#27262B"
                />
                <path
                    d="M13 21L19.8317 16.2692C19.8848 16.2347 19.9272 16.193 19.9561 16.1467C19.9851 16.1004 20 16.0505 20 16C20 15.9495 19.9851 15.8996 19.9561 15.8533C19.9272 15.807 19.8848 15.7653 19.8317 15.7308L13 11"
                    stroke="#E34922"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </button>
    );
};
