import { tw } from '@shared/lib/Tailwindest';

export const textFrame = tw.style({
    fontWeight: 'font-bold',
});

export const textSize = {
    sm: tw.style({
        fontSize: 'text-[14px]',
        color: 'text-P3_Gray4',
    }),
    md: tw.style({
        fontSize: 'text-base',
        color: 'text-P3_Gray4',
    }),
    lg: tw.style({
        fontSize: 'text-lg',
    }),
    xl: tw.style({
        fontSize: 'text-xl',
    }),
    xxl: tw.style({
        fontSize: 'text-2xl',
    }),
    xxxl: tw.style({
        fontSize: 'text-3xl',
    }),
    xxxxl: tw.style({
        fontSize: 'text-4xl',
    }),
};
