import React, { useRef, useState } from 'react';
import { useModal, useOutsideClick } from '@shared/lib';
import { Button, Table, Td, Th, Tr, Flex } from '@shared/ui';
import { modalContainer, modalLayout, modalTransiton } from './addBtn.styled';
import { IAdminAddBtn } from '@entities/CompanyInfo/model/interface';

export const AdminAddBtn: React.FC<IAdminAddBtn> = ({
    addManager,
    onDisable,
}) => {
    const modalRef = useRef<HTMLDivElement>(null);
    const { modalIsOpen, openModal, closeModal } = useModal();
    const [isClosing, setIsClosing] = useState<boolean>(false);

    const [name, setName] = useState<string>('');
    const [contact, setContact] = useState<string>('');
    const [job, setJob] = useState<string>('');

    useOutsideClick(
        modalRef,
        () => {
            setIsClosing(true);
            setTimeout(() => {
                setIsClosing(false);
                closeModal();
            }, 200);
        },
        modalIsOpen,
    );

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            setIsClosing(false);
            closeModal();
            setName('');
            setContact('');
            setJob('');
        }, 200);
    };

    const handleAddClick = () => {
        const newManager = { name, contact, job };
        addManager(newManager);
        handleClose();
    };

    return (
        <>
            <Flex justify="end">
                <button onClick={openModal} disabled={onDisable}>
                    <p
                        className={`${onDisable ? 'text-P3_Gray3 font-semibold underline' : 'text-P3_Red font-semibold underline'}`}
                    >
                        추가하기
                    </p>
                </button>
            </Flex>

            <div
                className={`${modalIsOpen && !isClosing ? 'opacity-100 ' : 'opacity-0'} ${modalTransiton.class}`}
            >
                {modalIsOpen && (
                    <div className={modalLayout.class}>
                        <div ref={modalRef} className={modalContainer.class}>
                            <div className="w-[100%] h-[100%]">
                                <Flex
                                    justify="between"
                                    className="mb-5"
                                    onClick={handleClose}
                                >
                                    <h1 className="text-xl flex items-end font-bold">
                                        담당자 추가
                                    </h1>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="40"
                                        height="40"
                                        viewBox="0 0 40 40"
                                        fill="none"
                                    >
                                        <path
                                            d="M30 10L10 30"
                                            stroke="#6C6D5D"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M10 10L30 30"
                                            stroke="#6C6D5D"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </Flex>
                                <Table className="h-full mt-5">
                                    <Tr>
                                        <Th className="w-[117px] text-start pl-5">
                                            이름
                                        </Th>
                                        <Td className="px-5 font-medium ">
                                            <input
                                                className="outline-none"
                                                value={name}
                                                onChange={(e) =>
                                                    setName(e.target.value)
                                                }
                                            />
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Th className="text-start pl-5">
                                            연락처
                                        </Th>
                                        <Td className="px-5 font-medium ">
                                            <input
                                                className="outline-none"
                                                value={contact}
                                                onChange={(e) =>
                                                    setContact(e.target.value)
                                                }
                                            />
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Th className="text-start pl-5">
                                            업무
                                        </Th>
                                        <Td className="px-5 font-medium ">
                                            <input
                                                className="outline-none"
                                                value={job}
                                                onChange={(e) =>
                                                    setJob(e.target.value)
                                                }
                                            />
                                        </Td>
                                    </Tr>
                                </Table>
                                <Button
                                    size="lg"
                                    onClick={handleAddClick}
                                    className="w-full mt-5"
                                >
                                    추가하기
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
