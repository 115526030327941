import { IWithdrawalAccountArrDto } from '@entities/Withdraw';
import { Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from '@shared/ui';
import React from 'react';

export const WithdrawManagement = ({
    savedAccountInfo,
}: IWithdrawalAccountArrDto) => {
    // 등록된 계좌 10개까지만 보이게
    const visibleAccounts = savedAccountInfo.slice(0, 10);
    return (
        <Flex col className="min-w-[860px]">
            <h1 className="text-[18px] font-semibold text-P3_Black mb-[17px]">
                등록 계좌 관리
            </h1>
            <Table>
                <Thead>
                    <Tr>
                        <Th className="py-[10px]">계좌 이름</Th>
                        <Th>계좌번호</Th>
                        <Th>등록일</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {visibleAccounts.map((d, index) => (
                        <Tr className="text-center" key={index}>
                            <Td className="py-[14px]">{d.bankName}</Td>
                            <Td>{d.bankNumber}</Td>
                            <Td>{d.savedDate}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
            <Flex col className="text-end mt-[18px]">
                <Text>
                    ∙ 정보 수정을 원하시면 고객센터로 연락해 주세요. 고객센터
                    (02-4323-1004)
                </Text>
                <Text>
                    ∙ 출금 성공, 실패 내역은 [알림] 메뉴에서 확인 가능해요.
                </Text>
            </Flex>
        </Flex>
    );
};
