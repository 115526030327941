import { useModal, useOutsideClick } from '@shared/lib';
import { Button, Flex, Text } from '@shared/ui';
import React, { useRef, useState } from 'react';
import {
    h1Text,
    modalContainer,
    modalLayout,
    modalTransiton,
    pText,
} from './chargeBtn.styled';
import { format, addHours } from 'date-fns';
import { formatNumber } from '@shared/lib/FormatNumber';

interface IChargeCompleteBtn {
    name: string;
    amount: number | undefined;
    resetState: () => void;
}

export const ChargeCompleteBtn = ({
    name,
    amount,
    resetState,
}: IChargeCompleteBtn) => {
    const modalRef = useRef<HTMLDivElement>(null);
    const { modalIsOpen, openModal, closeModal } = useModal();
    const [isClosing, setIsClosing] = useState<boolean>(false);
    const [timeCheck, setTimeCheck] = useState<string>('');
    const [limitTime, setLimitTime] = useState<string>(''); // 입금 기한 = timeCheck + 24시간
    const [costomAmount, setCustomAmount] = useState<string | undefined>('');
    useOutsideClick(
        modalRef,
        () => {
            setIsClosing(true);
            resetState();
            setTimeout(() => {
                setIsClosing(false);
                closeModal();
            }, 200);
        },
        modalIsOpen,
    );

    const handleClose = () => {
        setIsClosing(true);
        resetState();
        setTimeout(() => {
            setIsClosing(false);
            closeModal();
        }, 200);
    };

    const chargeHandler = () => {
        if (!amount) {
            alert('Test, 문자나 특수문자에 대한 처리');
        } else {
            const now = new Date();
            const nowTime = format(now, 'yyyy-MM-dd HH:mm');
            const plus24Time = addHours(now, 24);
            const limitTime = format(plus24Time, 'yyyy-MM-dd HH:mm');
            setTimeCheck(nowTime);
            setLimitTime(limitTime);

            const costomAmount = formatNumber(amount);
            setCustomAmount(costomAmount);

            openModal();
        }
    };
    return (
        <>
            <Button size="md" className="ml-[90px]" onClick={chargeHandler}>
                충전하기
            </Button>
            <div
                className={`${modalIsOpen && !isClosing ? 'opacity-100 ' : 'opacity-0'} ${modalTransiton.class}`}
            >
                {modalIsOpen && (
                    <div className={modalLayout.class}>
                        <div ref={modalRef} className={modalContainer.class}>
                            <div className="w-[100%] h-[100%]">
                                <Flex
                                    justify="between"
                                    className="mb-5"
                                    onClick={handleClose}
                                >
                                    <h1 className="flex items-end font-bold text-xl mb-8">
                                        충전 신청 완료
                                    </h1>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="40"
                                        height="40"
                                        viewBox="0 0 40 40"
                                        fill="none"
                                    >
                                        <path
                                            d="M30 10L10 30"
                                            stroke="#6C6D5D"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M10 10L30 30"
                                            stroke="#6C6D5D"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </Flex>
                                <div className="my-12">
                                    <Flex>
                                        <h1 className={h1Text.class}>
                                            입금계좌
                                        </h1>
                                        <p className={pText.class}>
                                            {'국민 331231-01-542132'}
                                        </p>
                                    </Flex>
                                    <Flex>
                                        <h1 className={h1Text.class}>
                                            신청일시
                                        </h1>
                                        <p className={pText.class}>
                                            {timeCheck}
                                        </p>
                                    </Flex>
                                    <Flex>
                                        <h1 className={h1Text.class}>
                                            입금기한
                                        </h1>
                                        <p className={pText.class}>
                                            {limitTime}
                                        </p>
                                    </Flex>
                                    <Flex>
                                        <h1 className={h1Text.class}>
                                            충전금액
                                        </h1>
                                        <p className={pText.class}>
                                            {costomAmount}
                                        </p>
                                    </Flex>
                                    <Flex>
                                        <h1 className={h1Text.class}>
                                            입금자명
                                        </h1>
                                        <p className={pText.class}>{name}</p>
                                    </Flex>
                                </div>
                                <Text size="sm">
                                    ※ 기간 내 입금이 완료되지 않을 시 충전이
                                    실패돼요.
                                </Text>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
