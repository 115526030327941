import React from 'react';

export const SettingIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="13"
            viewBox="0 0 12 13"
            fill="none"
        >
            <path
                d="M4.22049 1.75L4.6528 0.64C4.72571 0.452064 4.85398 0.290411 5.02082 0.176212C5.18765 0.0620139 5.38527 0.000595057 5.5878 0H6.4122C6.61473 0.000595057 6.81235 0.0620139 6.97918 0.176212C7.14602 0.290411 7.27429 0.452064 7.3472 0.64L7.77951 1.75L9.24735 2.59L10.4337 2.41C10.6312 2.38333 10.8323 2.41567 11.0113 2.50292C11.1903 2.59017 11.3392 2.72838 11.4391 2.9L11.8412 3.6C11.9443 3.77435 11.9917 3.97568 11.9774 4.17742C11.963 4.37916 11.8875 4.57183 11.7608 4.73L11.0269 5.66V7.34L11.7809 8.27C11.9076 8.42817 11.9831 8.62084 11.9975 8.82258C12.0118 9.02432 11.9644 9.22565 11.8613 9.4L11.4592 10.1C11.3593 10.2716 11.2104 10.4098 11.0314 10.4971C10.8524 10.5843 10.6513 10.6167 10.4538 10.59L9.26746 10.41L7.79962 11.25L7.36731 12.36C7.2944 12.5479 7.16613 12.7096 6.99929 12.8238C6.83245 12.938 6.63483 12.9994 6.43231 13H5.5878C5.38527 12.9994 5.18765 12.938 5.02082 12.8238C4.85398 12.7096 4.72571 12.5479 4.6528 12.36L4.22049 11.25L2.75265 10.41L1.56631 10.59C1.36877 10.6167 1.16772 10.5843 0.988703 10.4971C0.809688 10.4098 0.660792 10.2716 0.560935 10.1L0.158786 9.4C0.0557368 9.22565 0.00825882 9.02432 0.0226181 8.82258C0.0369774 8.62084 0.112499 8.42817 0.239216 8.27L0.973138 7.34V5.66L0.219108 4.73C0.0923915 4.57183 0.01687 4.37916 0.00251069 4.17742C-0.0118486 3.97568 0.0356293 3.77435 0.138678 3.6L0.540827 2.9C0.640685 2.72838 0.789581 2.59017 0.968596 2.50292C1.14761 2.41567 1.34866 2.38333 1.5462 2.41L2.73254 2.59L4.22049 1.75ZM3.98925 6.5C3.98925 6.89556 4.10718 7.28224 4.32813 7.61114C4.54907 7.94004 4.86311 8.19638 5.23052 8.34776C5.59794 8.49913 6.00223 8.53874 6.39228 8.46157C6.78232 8.3844 7.1406 8.19392 7.42181 7.91421C7.70302 7.63451 7.89452 7.27814 7.97211 6.89018C8.04969 6.50222 8.00987 6.10009 7.85769 5.73463C7.7055 5.36918 7.44777 5.05682 7.11711 4.83706C6.78644 4.6173 6.39769 4.5 6 4.5C5.46672 4.5 4.95528 4.71071 4.57819 5.08579C4.2011 5.46086 3.98925 5.96957 3.98925 6.5Z"
                fill="#E34922"
            />
        </svg>
    );
};
