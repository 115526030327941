import React from "react";
import { ICopyButton } from "@shared/types/button";
import { CopyIcon } from "../icon";

export const CopyButton = ({ text }: ICopyButton) => {
    const copyToClipboard = () => {
        navigator.clipboard.writeText(text)
            .then(() => alert('주문번호가 복사되었습니다'))
            .catch((err) => console.error('Failed to copy: ', err));
    };

    return (
        <button onClick={copyToClipboard} className="ml-1" >
            <CopyIcon />
        </button>
    );
};