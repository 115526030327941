import React, { useEffect } from 'react';
import {
    CalendarIcon,
    Flex,
    HyphenIcon,
    Table,
    Tbody,
    Td,
    Th,
    Tr,
} from '@shared/ui';
import { CheckInput } from '@shared/ui/input/CheckInput';
import { customDateInput } from './settlementFilter.styled';
import { useDateChange } from '@shared/lib';
import { ISettlementFilter } from '@features/Settlement';
import { handleDateClick } from '@shared/lib/handler';

export const SettlementFilter = ({ setFilterCriteria }: ISettlementFilter) => {
    const { startDate, setStartDate, endDate, setEndDate, changeHandler } =
        useDateChange();

    useEffect(() => {
        setFilterCriteria({
            startDate,
            endDate,
        });
    }, [startDate, endDate]);

    return (
        <Flex className="mt-[33px] min-w-[860px] mb-3">
            <Table>
                <Tbody>
                    <Tr className="border-P3_Gray4">
                        <Th className="text-start border-none w-[14%]">
                            <label className="py-[10px] pl-[20px] pr-[64px] text-P3_Black">
                                조회기간
                            </label>
                        </Th>
                        <Flex items="center">
                            <Td className="flex justify-center items-center border-none">
                                <div className="flex ml-[15px] items-center">
                                    <CalendarIcon />
                                    <input
                                        type="date"
                                        value={startDate}
                                        onChange={(e) =>
                                            setStartDate(e.target.value)
                                        }
                                        onClick={handleDateClick}
                                        className={`${customDateInput.class} hide-calendar-icon ml-[22px]`}
                                    />
                                </div>
                            </Td>
                            <span className="flex items-center justify-center">
                                <HyphenIcon />
                            </span>
                            <Td className="flex justify-center items-center border-none">
                                <div className="flex ml-[22px]">
                                    <input
                                        type="date"
                                        value={endDate}
                                        onChange={(e) =>
                                            setEndDate(e.target.value)
                                        }
                                        onClick={handleDateClick}
                                        className={`${customDateInput.class} hide-calendar-icon`}
                                    />
                                </div>
                            </Td>

                            <Td className="border-none">
                                <label className="flex items-center">
                                    <CheckInput
                                        input_type="radio"
                                        input_name="query_period"
                                        input_value="yesterday"
                                        input_onChange={changeHandler}
                                    />
                                    <span className="ml-2 text-P3_Black">
                                        어제
                                    </span>
                                </label>
                            </Td>
                            <Td className="border-none">
                                <label className="flex items-center">
                                    <CheckInput
                                        input_type="radio"
                                        input_name="query_period"
                                        input_value="lastWeek"
                                        input_onChange={changeHandler}
                                    />
                                    <span className="ml-2 text-P3_Black">
                                        일주일
                                    </span>
                                </label>
                            </Td>
                            <Td className="border-none">
                                <label className="flex items-center">
                                    <CheckInput
                                        input_type="radio"
                                        input_name="query_period"
                                        input_value="lastMonth"
                                        input_onChange={changeHandler}
                                    />
                                    <span className="ml-2 text-P3_Black">
                                        지난달
                                    </span>
                                </label>
                            </Td>
                            <Td>
                                <label className="flex items-center">
                                    <CheckInput
                                        input_type="radio"
                                        input_name="query_period"
                                        input_value="thisMonth"
                                        input_onChange={changeHandler}
                                        defaultChecked
                                    />
                                    <span className="ml-2 text-P3_Black">
                                        이번 달
                                    </span>
                                </label>
                            </Td>
                        </Flex>
                    </Tr>
                </Tbody>
            </Table>
        </Flex>
    );
};
