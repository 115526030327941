import { AxiosRequestHeaders, AxiosResponse } from 'axios';
import { IDailyPaymentTotalListArrDto } from '../model/interfaces';


// totalListAPI는 필요 없을 듯. 나중에 API 넘어오는걸로 다 더하게 만들면 될 듯.

const totalListInfoData = {
    totalListInfo: [
        {
            paymentCancleTransaction: 1000,
            paymentCancleAmounts: 11111,
            transactions: 222222,
            amounts: 3333333,
        },
    ],
};

export const totalListAPI = async (): Promise<
    AxiosResponse<IDailyPaymentTotalListArrDto>
> => {
    return {
        data: totalListInfoData,
        status: 200,
        statusText: 'OK',
        headers: {},
        config: {
            headers: {} as AxiosRequestHeaders,
        },
    };
};
