import React, { useState } from 'react';
import { ITransactionPaymentInfoHistoryArrDto, ITransactionPaymentInfoHistoryDto } from '@entities/TransactionPaymentInfo';
import {
    CancelBtn,
    ReceiptBtn,
    TableSettingBtn,
} from '@features/TransactionPaymentInfo';
import { CopyButton, Flex, PaginatedList, Table, Tbody, Td, Th, Thead, Tr } from '@shared/ui';
import { formatNumber, handlePaginate } from '@shared/lib';

export const TransactionPaymentInfoHistory = ({
    TransactionHistoryInfo = [],
}: ITransactionPaymentInfoHistoryArrDto) => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const totalPages = Math.ceil(TransactionHistoryInfo.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = TransactionHistoryInfo.slice(indexOfFirstItem, indexOfLastItem);

    const tableComponent = (
        data: ITransactionPaymentInfoHistoryDto[]) => {
        const allValues = [
            '결제일시',
            '취소 요청 일시',
            '취소 완료 일시',
            '주문번호',
            '결제상태',
            '취소하기',
            '영수증',
            '구매자명',
            '구매자아이디',
            '결제액',
            '취소액',
        ];
        const [visibleColumns, setVisibleColumns] = useState<string[]>(allValues);

        return (
            <div className='overflow-auto min-w-[1080px]'>
                <Flex justify='between' items='center' className='mr-auto mt-11'>
                    <TableSettingBtn onSettingsChange={setVisibleColumns} />
                    <select className="border-gray-300 text-sm ">
                        <option>결제 일시 순으로 보기</option>
                        <option>마지막 결제상태로 보기</option>
                    </select>
                </Flex>

                <Table>
                    <Thead>
                        <Tr className="bg-P3-Gray_1 h-[41px]">
                            {visibleColumns.includes('결제일시') && (
                                <Th >결제일시</Th>
                            )}
                            {visibleColumns.includes('취소 요청 일시') && (
                                <Th>취소 요청 일시</Th>
                            )}
                            {visibleColumns.includes('취소 완료 일시') && (
                                <Th>취소 완료 일시</Th>
                            )}
                            {visibleColumns.includes('주문번호') && (
                                <Th>주문번호</Th>
                            )}
                            {visibleColumns.includes('결제상태') && (
                                <Th>결제상태</Th>
                            )}
                            {visibleColumns.includes('취소하기') && (
                                <Th>취소하기</Th>
                            )}
                            {visibleColumns.includes('영수증') && <Th>영수증</Th>}
                            {visibleColumns.includes('결제액') && <Th>결제액</Th>}
                            {visibleColumns.includes('취소액') && <Th>취소액</Th>}
                            {visibleColumns.includes('구매자명') && (
                                <Th>구매자명</Th>
                            )}
                            {visibleColumns.includes('구매자아이디') && (
                                <Th className="border-none">구매자아이디</Th>
                            )}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {data.map((item, index) => (
                            <Tr className="text-center" key={index}>
                                {visibleColumns.includes('결제일시') && (
                                    <Td className="border-r">
                                        {item?.paymentDateTime}
                                    </Td>
                                )}
                                {visibleColumns.includes('취소 요청 일시') && (
                                    <Td className="border-r">
                                        {item?.cancellationDateTime}
                                    </Td>
                                )}
                                {visibleColumns.includes('취소 완료 일시') && (
                                    <Td className="border-r">
                                        {item?.cancellationDateTime}
                                    </Td>
                                )}
                                {visibleColumns.includes('주문번호') && (
                                    <Td className="border-r">
                                        {item?.orderNumber}
                                        <CopyButton
                                            text={
                                                item?.orderNumber?.toString() ||
                                                ''
                                            }
                                        />
                                    </Td>
                                )}
                                {visibleColumns.includes('결제상태') && (
                                    <Td className="border-r">
                                        {item?.paymentStatus}
                                    </Td>
                                )}
                                {visibleColumns.includes('취소하기') && (
                                    <Td className="border-r">{<CancelBtn />}</Td>
                                )}
                                {visibleColumns.includes('영수증') && (
                                    <Td className="border-r">
                                        <ReceiptBtn />
                                    </Td>
                                )}
                                {visibleColumns.includes('결제액') && (
                                    <Td className="border-r">
                                        {formatNumber(item?.paymentAmount)}
                                    </Td>
                                )}
                                {visibleColumns.includes('취소액') && (
                                    <Td className="border-r">
                                        {formatNumber(item?.cancellationAmount)}
                                    </Td>
                                )}
                                {visibleColumns.includes('구매자명') && (
                                    <Td className="border-r">
                                        {item?.buyerName}
                                    </Td>
                                )}
                                {visibleColumns.includes('구매자아이디') && (
                                    <Td className="border-r">
                                        {item?.buyerId}
                                    </Td>
                                )}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </div>
        );
    };
    return (
        <div className="min-w-[1080px] overflow-auto">
            <ul>{tableComponent(currentItems)}</ul>
            {TransactionHistoryInfo.length > 0 && (
                <PaginatedList
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(pageNumber: number) =>
                        handlePaginate(pageNumber, totalPages, setCurrentPage)
                    }
                />
            )}
        </div>
    );
};
