import React from 'react';
import { DayCell } from '../dayCell/DayCell';
import { addDays, format } from 'date-fns';
import { ITodaysDto } from '@entities/Calendar';
import { Flex } from '@shared/ui';
import { CalendarUtils } from '@features/Calendar/lib/utils/CalendarUtils';

export const RenderCell = ({ currentDate, todaysInfo }: ITodaysDto) => {
    const { monthStart, startDate, endDate } = CalendarUtils(currentDate);

    const rows = [];
    let days = [];
    let day = startDate;

    while (day <= endDate) {
        for (let i = 0; i < 7; i++) {
            days.push(
                <DayCell
                    key={format(day, 'yyyy-MM-dd')}
                    day={day}
                    monthStart={monthStart}
                    todaysInfo={todaysInfo}
                />,
            );
            day = addDays(day, 1);
        }
        rows.push(
            <Flex
                className="w-full overflow-auto"
                key={format(day, 'yyyy-MM-dd')}
            >
                {days}
            </Flex>,
        );
        days = [];
    }
    return <div>{rows}</div>;
};
