import { Flex, Table, Tbody, Td, Th, Tr } from '@shared/ui';
import React from 'react';

export const UsageInfoTime = () => {
    return (
        <div className='min-w-[860px]'>
            <h1 className="text-P3_Black text-[18px] font-semibold mt-[41px] mb-[17px] ml-2">
                이용 가능 시간
            </h1>
            <Table>
                <Tbody>
                    <Tr className="border-y-P3_Gray4">
                        <Th className="w-[8%] text-start py-[10px] px-5">
                            출금, 충전
                        </Th>
                        <Td className="w-[35%] pl-[15px] font-semibold">
                            <Flex>
                                <p className="mr-1">실시간</p>
                                <p className="text-P3_Gray4">(최대 3시간)</p>
                            </Flex>
                        </Td>
                    </Tr>
                    <Tr className="border-P3_Gray4">
                        <Th className="text-start py-[10px] px-5">지갑 조회</Th>
                        <Td className="pl-[15px] font-semibold">실시간</Td>
                    </Tr>
                    <Tr className="border-P3_Gray4">
                        <Th className="text-start py-[10px] px-5">결제 조회</Th>
                        <Td className="pl-[15px] font-semibold">실시간</Td>
                    </Tr>
                </Tbody>
            </Table>
        </div>
    );
};
