import { AxiosRequestHeaders, AxiosResponse } from 'axios';
import { IPaymentHistoryArrDto } from '../model/interfaces';

const historyInfoData = {
    historyInfo: [
        {
            paymentDate: '2024-06-27 14:23:12',
            paymentType: 'withdraw',
            paymentAmount: 300000000,
            paymentAfterBalance: 31212515176,
            withdrawalAccount: {
                account: '계좌 A',
                bankName: '국민',
                accountNumber: '123412-41-2421313',
            },
        },
        {
            paymentDate: '2024-06-28 14:23:12',
            paymentType: 'charge',
            paymentAmount: 300000000,
            paymentAfterBalance: 31212515176,
            withdrawalAccount: {
                account: '계좌 A',
                bankName: '국민',
                accountNumber: '123412-41-2421313',
            },
        },
        {
            paymentDate: '2024-06-29 14:23:12',
            paymentType: 'charge',
            paymentAmount: 300000000,
            paymentAfterBalance: 31212515176,
            withdrawalAccount: {
                account: '계좌 A',
                bankName: '국민',
                accountNumber: '123412-41-2421313',
            },
        },
        {
            paymentDate: '2024-06-30 14:23:12',
            paymentType: 'charge',
            paymentAmount: 300000000,
            paymentAfterBalance: 31212515176,
            withdrawalAccount: {
                account: '계좌 A',
                bankName: '국민',
                accountNumber: '123412-41-2421313',
            },
        },
        {
            paymentDate: '2024-07-01 14:23:12',
            paymentType: 'charge',
            paymentAmount: 300000000,
            paymentAfterBalance: 31212515176,
            withdrawalAccount: {
                account: '계좌 A',
                bankName: '국민',
                accountNumber: '123412-41-2421313',
            },
        },
        {
            paymentDate: '2024-07-02 14:23:12',
            paymentType: 'charge',
            paymentAmount: 300000000,
            paymentAfterBalance: 31212515176,
            withdrawalAccount: {
                account: '계좌 A',
                bankName: '국민',
                accountNumber: '123412-41-2421313',
            },
        },
        {
            paymentDate: '2024-07-03 14:23:12',
            paymentType: 'cancel',
            paymentAmount: 300000000,
            paymentAfterBalance: 31212515176,
            withdrawalAccount: {
                account: '계좌 A',
                bankName: '국민',
                accountNumber: '123412-41-2421313',
            },
        },
        {
            paymentDate: '2024-07-04 14:23:12',
            paymentType: 'cancel',
            paymentAmount: 300000000,
            paymentAfterBalance: 31212515176,
            withdrawalAccount: {
                account: '계좌 A',
                bankName: '국민',
                accountNumber: '123412-41-2421313',
            },
        },
        {
            paymentDate: '2024-07-05 14:23:12',
            paymentType: 'cancel',
            paymentAmount: 300000000,
            paymentAfterBalance: 31212515176,
            withdrawalAccount: {
                account: '계좌 A',
                bankName: '국민',
                accountNumber: '123412-41-2421313',
            },
        },
    ],
};

export const historyAPI = async (): Promise<
    AxiosResponse<IPaymentHistoryArrDto>
> => {
    return {
        data: historyInfoData,
        status: 200,
        statusText: 'OK',
        headers: {},
        config: {
            headers: {} as AxiosRequestHeaders,
        },
    };
};
