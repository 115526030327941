import { AxiosRequestHeaders, AxiosResponse } from 'axios';
import { IMIDDto } from '../model/interface';

const MarketIDData: IMIDDto = {
    marketID: ['123456AA', '213312BB'],
};

export const MIDAPI = async (): Promise<AxiosResponse<IMIDDto>> => {
    return {
        data: MarketIDData,
        status: 200,
        statusText: 'OK',
        headers: {},
        config: {
            headers: {} as AxiosRequestHeaders,
        },
    };
};
