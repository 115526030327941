import React, { useState } from 'react';
import {
    SettlementHistory,
    SettlementFilter,
    TotalSettlementList,
} from '@widgets/Settlement';

import { settlementContainer } from './settlement.styled';
import {
    ISettlementHistoryDto,
    useHistoryInfo,
    useTotalList,
} from '@entities/Settlement';
import { Button, Flex, SpotlightBtn } from '@shared/ui';
import { AnimatePresence, motion } from 'framer-motion';
import { filterCheck } from '@features/Settlement';

export const Settlement = () => {
    const [view, setView] = useState<string>('daily');
    const [filteredHistory, setFilteredHistory] = useState<
        ISettlementHistoryDto[]
    >([]);
    const [filterCriteria, setFilterCriteria] = useState({
        startDate: '',
        endDate: '',
    });

    const totalListInfo = useTotalList();
    const historyInfo = useHistoryInfo();

    return (
        <div className={settlementContainer.class}>
            <AnimatePresence>
                <motion.div
                    key={location.pathname}
                    initial={{ opacity: 0, y: 1000 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 0 }}
                    transition={{ duration: 0.4 }}
                >
                    <h1 className="text-[22px] font-bold mb-15">정산내역</h1>
                    <Flex className="m-4 gap-8 text-xl">
                        <SpotlightBtn
                            viewValue="daily"
                            currentView={view}
                            setView={setView}
                        >
                            일자별
                        </SpotlightBtn>
                        <SpotlightBtn
                            viewValue="range"
                            currentView={view}
                            setView={setView}
                        >
                            기간별
                        </SpotlightBtn>
                    </Flex>

                    {view === 'daily' && (
                        <>
                            <SettlementFilter
                                setFilterCriteria={setFilterCriteria}
                            />
                            <Flex
                                justify="end"
                                className="w-full min-w-[860px] mb-4"
                            >
                                <Button
                                    size="sm"
                                    bg="bg-[#E34922]"
                                    color="text-[#FFFFF]"
                                    onClick={() => {
                                        if (historyInfo) {
                                            filterCheck(
                                                historyInfo,
                                                filterCriteria,
                                                setFilteredHistory,
                                            );
                                        }
                                    }}
                                >
                                    조회하기
                                </Button>
                            </Flex>
                            {totalListInfo && (
                                <TotalSettlementList
                                    totalListInfo={totalListInfo.totalListInfo}
                                />
                            )}

                            {historyInfo && (
                                <SettlementHistory
                                    historyInfo={filteredHistory}
                                />
                            )}
                        </>
                    )}
                    {view === 'range' && (
                        <>
                            <SettlementFilter
                                setFilterCriteria={setFilterCriteria}
                            />
                            <Flex justify="end" className="mb-4 min-w-[860px]">
                                <Button
                                    size="sm"
                                    bg="bg-[#E34922]"
                                    color="text-[#FFFFF]"
                                    onClick={() => {
                                        if (historyInfo) {
                                            filterCheck(
                                                historyInfo,
                                                filterCriteria,
                                                setFilteredHistory,
                                            );
                                        }
                                    }}
                                >
                                    조회하기
                                </Button>
                            </Flex>
                            {totalListInfo && (
                                <TotalSettlementList
                                    totalListInfo={totalListInfo.totalListInfo}
                                />
                            )}
                        </>
                    )}
                </motion.div>
            </AnimatePresence>
        </div>
    );
};
