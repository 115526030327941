import React, { useRef, useState } from 'react';
import { useModal, useOutsideClick } from '@shared/lib';
import {
    modalContainer,
    modalLayout,
} from './receiptBtn.styled';
import { Flex, ReceiptIcon } from '@shared/ui';
import { modalTransiton } from '../cancle/cancelBtn.styled';

export const ReceiptBtn = () => {
    const modalRef = useRef<HTMLDivElement>(null);
    const { modalIsOpen, openModal, closeModal } = useModal();
    const [isClosing, setIsClosing] = useState<boolean>(false);

    useOutsideClick(
        modalRef,
        () => {
            setIsClosing(true);
            setTimeout(() => {
                setIsClosing(false);
                closeModal();
            }, 200);
        },
        modalIsOpen,
    );

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            setIsClosing(false);
            closeModal();
        }, 200);
    }

    return (
        <>
            <button
                onClick={() => {
                    openModal();
                }}
            >
                <ReceiptIcon />
            </button>
            <div
                className={`${modalIsOpen && !isClosing ? 'opacity-100 ' : 'opacity-0'} ${modalTransiton.class}`}
            >
                {modalIsOpen && (
                    <div className={modalLayout.class}>
                        <div ref={modalRef} className={modalContainer.class}>
                            <div className="w-[90%] h-[100%]">
                                <div
                                    className="flex justify-end my-6"
                                    onClick={handleClose}
                                >
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 40 40"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <line
                                            x1="10"
                                            y1="10"
                                            x2="30"
                                            y2="30"
                                            stroke="black"
                                            strokeWidth="5"
                                        />
                                        <line
                                            x1="10"
                                            y1="30"
                                            x2="30"
                                            y2="10"
                                            stroke="black"
                                            strokeWidth="5"
                                        />
                                    </svg>
                                </div>
                                <h1 className="text-3xl text-center font-bold">
                                    매출전표
                                </h1>
                                <div>
                                    <Flex justify="between">
                                        <p className="py-2 px-4 font-medium">
                                            주문번호
                                        </p>
                                        <p className="py-2 px-4 font-medium">
                                            41241
                                        </p>
                                    </Flex>
                                    <Flex justify="between">
                                        <p className="py-2 px-4 font-medium ">
                                            구매자
                                        </p>
                                        <p className="py-2 px-4 font-medium ">
                                            황지섭
                                        </p>
                                    </Flex>
                                    <Flex justify="between">
                                        <p className="py-2 px-4 font-medium ">
                                            구매상품
                                        </p>
                                        <p className="py-2 px-4 font-medium ">
                                            양자컴퓨터
                                        </p>
                                    </Flex>

                                    <div className="border-b border-gray-300"></div>
                                    <div className="border-b border-gray-300"></div>

                                    <Flex justify="between">
                                        <p className="py-2 px-4 font-medium">
                                            카드종류
                                        </p>
                                        <p className="py-2 px-4 font-medium">
                                            블랙카드
                                        </p>
                                    </Flex>
                                    <Flex justify="between">
                                        <p className="py-2 px-4 font-medium ">
                                            카드번호
                                        </p>
                                        <p className="py-2 px-4 font-medium ">
                                            1234-1234-1233-414
                                        </p>
                                    </Flex>
                                    <Flex justify="between">
                                        <p className="py-2 px-4 font-medium ">
                                            할부
                                        </p>
                                        <p className="py-2 px-4 font-medium ">
                                            일시불
                                        </p>
                                    </Flex>
                                    <Flex justify="between">
                                        <p className="py-2 px-4 font-medium ">
                                            결제상태
                                        </p>
                                        <p className="py-2 px-4 font-medium ">
                                            승인
                                        </p>
                                    </Flex>
                                    <Flex justify="between">
                                        <p className="py-2 px-4 font-medium">
                                            승인번호
                                        </p>
                                        <p className="py-2 px-4 font-medium">
                                            비밀
                                        </p>
                                    </Flex>
                                    <Flex justify="between">
                                        <p className="py-2 px-4 font-medium ">
                                            결제일시
                                        </p>
                                        <p className="py-2 px-4 font-medium ">
                                            2024-05-14 17:55:59
                                        </p>
                                    </Flex>

                                    <div className="border-b border-gray-300"></div>
                                    <div className="border-b border-gray-300"></div>

                                    <Flex justify="between">
                                        <p className="py-2 px-4 font-medium ">
                                            공급가액
                                        </p>
                                        <p className="py-2 px-4 font-medium ">
                                            50,000,000,000원
                                        </p>
                                    </Flex>
                                    <Flex justify="between">
                                        <p className="py-2 px-4 font-medium ">
                                            면세가액
                                        </p>
                                        <p className="py-2 px-4 font-medium ">
                                            0원
                                        </p>
                                    </Flex>
                                    <Flex justify="between">
                                        <p className="py-2 px-4 font-medium">
                                            부가세
                                        </p>
                                        <p className="py-2 px-4 font-medium">
                                            5000원
                                        </p>
                                    </Flex>
                                    <Flex justify="between">
                                        <p className="py-2 px-4 font-medium ">
                                            합계
                                        </p>
                                        <p className="py-2 px-4 font-medium ">
                                            50,000,005,000원
                                        </p>
                                    </Flex>

                                    <div className="border-b border-gray-300"></div>
                                    <div className="border-b border-gray-300"></div>
                                </div>
                                <Flex justify="start" className="ml-4 mt-4">
                                    <div>
                                        <p>이용상점 (주)바이올렛페이</p>
                                        <p>대표자명:박찬수</p>
                                        <p>사업자등록번호:777-77-777777</p>
                                    </div>
                                </Flex>
                                <div className="text-end mt-3">
                                    {/* <Button
                                        className={modalConfirmBtn.class}
                                        onClick={() => {
                                            closeModal();
                                        }}
                                    >
                                        확인
                                    </Button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
