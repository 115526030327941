import React from 'react';

interface ICheckInput extends React.InputHTMLAttributes<HTMLInputElement> {
    input_type: string;
    input_name: string;
    input_value?: string;
    input_onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export const CheckInput = ({
    input_type,
    input_name,
    input_value,
    input_onChange,
    ...props
}: ICheckInput) => {
    return (
        <input
            type={`${input_type}`}
            name={`${input_name}`}
            value={`${input_value}`}
            onChange={input_onChange}
            {...props}
            className="appearance-none w-4 h-4 bg-P3_Gray2 border-2 border-P3_Gray2 rounded-full checked:bg-P3_Red checked:border-P3_Red relative cursor-pointer checked:after:bg-P3_Red checked:after:text-P3_Red checked:after:text-lg checked:after:absolute checked:after:top-1/2 checked:after:left-1/2 checked:after:transform checked:after:-translate-x-1/2 checked:after:-translate-y-1/2"
        />
    );
};

export const CheckInputType = ({
    input_type,
    input_name,
    input_value,
    input_onChange,
    ...props
}: ICheckInput) => {
    return (
        <input
            type={`${input_type}`}
            name={`${input_name}`}
            value={`${input_value}`}
            onChange={input_onChange}
            {...props}
            className="appearance-none w-4 h-4 bg-P3_Gray2 border-2 border-P3_Gray2 rounded-full checked:bg-P3_Red checked:border-P3_Red relative cursor-pointer checked:after:bg-P3_Red checked:after:text-P3_Red checked:after:text-lg checked:after:absolute checked:after:top-1/2 checked:after:left-1/2 checked:after:transform checked:after:-translate-x-1/2 checked:after:-translate-y-1/2"
        />
    );
};
