import { AppThunk } from '@app/AppStore';
import { walletBalanceAPI } from '@entities/WalletBalance';
import {
    setBalance,
    setError,
    setLoading,
} from '@entities/WalletBalance/lib/walletSlice';

export const getWalletBalance = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const balance = await walletBalanceAPI();
        dispatch(setBalance(balance.data.amount));
    } catch (error) {
        const errorMessage =
            (error as Error).message || 'An unknown error occurred';
        dispatch(setError(errorMessage));
    } finally {
        dispatch(setLoading(false));
    }
};
