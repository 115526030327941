import React, { useEffect, useState } from 'react';
import {
    chargeHopeContainer,
    inBoxContainer,
    inBoxContainer2,
} from './chargeHope.styled';
import { ChargeCompleteBtn } from '@features/Charge';
import { useNumberLimit } from '@shared/lib';

export const ChargeHope = () => {
    const [name, setName] = useState<string>('');
    const [amount, setAmount] = useState<number | undefined>();
    const resetState = () => {
        setName('');
        setAmount(0);
    };

    useEffect(() => {
        // 고객사명 API로 가져와서 넣어야 함
        setName('바이올렛 페이 주식회사');
    }, [name]);

    const onChangeHandler = useNumberLimit(setAmount);

    return (
        <div className={chargeHopeContainer.class}>
            <div className="w-[50%] mr-[48px]">
                <p className="text-P3_Gray5 mb-3">충전 희망 금액</p>
                <div className={inBoxContainer.class}>
                    <input
                        className="w-[100%] items-center text-lg text-end mr-[17px] focus:outline-none"
                        type="text"
                        value={
                            amount !== undefined ? amount.toLocaleString() : ''
                        }
                        onChange={onChangeHandler}
                        placeholder="금액을 입력해 주세요."
                    />
                    <p className="font-bold">원</p>
                </div>
            </div>
            <div className="w-[50%]">
                <label className="text-P3_Gray5" htmlFor="name">
                    입금자명
                </label>
                <div className={inBoxContainer2.class}>
                    <input
                        className="w-[100%] items-center bg-P3_White text-lg text-end mr-[17px]"
                        type="text"
                        id="name"
                        value={name}
                        placeholder={name}
                        disabled
                    />
                </div>
            </div>
            <div className="flex justify-center items-end">
                <ChargeCompleteBtn
                    amount={amount ?? 0}
                    name={name}
                    resetState={resetState}
                />
            </div>
        </div>
    );
};
