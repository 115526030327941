import { AxiosRequestHeaders, AxiosResponse } from 'axios';
import { ISettlementHistoryArrDto } from '../model/interfaces';

const historyInfoData = {
    historyInfo: [
        {
            settlementDepositDate: '2024-06-01',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-02',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-03',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-04',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-05',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-06',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-07',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-08',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-09',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-10',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-11',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-12',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-13',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-14',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-15',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-16',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-17',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-18',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-19',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-20',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
        {
            settlementDepositDate: '2024-06-27',
            paymentCancellationCount: 9,
            historySalesAmount: 99999999,
            pgFee: 134213,
            pgVat: 13402,
            totalPgFee: 134213 + 13402,
            dailySettlementAmount: 99999999 - 134213 + 13402,
            withdrawAmount: 77777,
            walletBalance: 10000000000,
        },
    ],
};

export const historyAPI = async (): Promise<
    AxiosResponse<ISettlementHistoryArrDto>
> => {
    return {
        data: historyInfoData,
        status: 200,
        statusText: 'OK',
        headers: {},
        config: {
            headers: {} as AxiosRequestHeaders,
        },
    };
};
