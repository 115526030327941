import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button, Flex, Point3LogoIcon } from '@shared/ui';
import { loginErrorMsg, loginInput } from './login.styled';
import { ILoginDto, loginAPI } from '@entities/Login';


export const LoginLayout = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ILoginDto>();

    const navigate = useNavigate();

    const onSubmit: SubmitHandler<ILoginDto> = async () => {
        try {
            const response = await loginAPI();
            console.log('Login success', response);
            navigate('/success');
        } catch (error) {
            console.error('Login error', error);
            alert('로그인에 실패했습니다. 다시 시도해주세요.');
        }
    };

    const handleSubmitReq = () => {
        handleSubmit(onSubmit)();
    };

    return (
        <Flex col justify='center' items='center' className="h-[100vh] bg-[#F1F1EE]">
            <Flex col justify='center' items='center' className="max-w-[620px] w-full max-h-[810px] min-h-[810px] bg-white rounded-xl">
                <div className='max-w-[366px] w-full'>
                    <Point3LogoIcon />
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="flex flex-col mt-[60px] w-full items-center"
                    >
                        <div className="relative w-full h-[60px]">
                            <label className='font-bold'>아이디</label>
                            <input
                                className={`${loginInput.class} `}
                                type="text"
                                {...register('username', {
                                    required: true,
                                    maxLength: 20,
                                })}
                            />
                            {errors.username &&
                                errors.username.type === 'required' && (
                                    <span className={loginErrorMsg.class}>
                                        아이디를 입력하세요!
                                    </span>
                                )}
                            {errors.username &&
                                errors.username.type === 'maxLength' && (
                                    <span className={loginErrorMsg.class}>
                                        20자 이하로 입력하세요!
                                    </span>
                                )}
                        </div>
                        <div className="relative w-full mt-16 h-[60px]">
                            <label className='font-bold'>비밀번호</label>
                            <input
                                className={`${loginInput.class} `}
                                type="password"
                                {...register('password', {
                                    required: true,
                                    minLength: 6,
                                    maxLength: 20,
                                })}
                            />
                            {errors.password &&
                                errors.password.type === 'required' && (
                                    <span className={loginErrorMsg.class}>
                                        비밀번호를 입력하세요!
                                    </span>
                                )}
                            {errors.password &&
                                errors.password.type === 'minLength' && (
                                    <span className={loginErrorMsg.class}>
                                        6자 이상 20자 이하로 입력하세요.
                                    </span>
                                )}
                            {errors.password &&
                                errors.password.type === 'maxLength' && (
                                    <span className={loginErrorMsg.class}>
                                        6자 이상 20자 이하로 입력하세요.
                                    </span>
                                )}
                        </div>
                        <Flex justify='end' className=" mt-20 w-full">
                            <Button onClick={handleSubmitReq} bg='bg-P3_Red' color='text-white' size='md' className='hover:opacity-75'>
                                로그인
                            </Button>
                        </Flex>
                    </form>
                </div>
                <span className='flex justify-center items-end h-[80px]'>고객센터 (1234-1234)</span>
            </Flex>
        </Flex>
    );
};
