import React, { useState } from 'react';
import { PaginatedList, Table, Tbody, Td, Th, Thead, Tr } from '@shared/ui';
import {
    IDailyPaymentInfoHistoryArrDto,
    IDailyPaymentInfoHistoryDto,
} from '@entities/DailyPaymentInfo';
import { formatNumber, handlePaginate } from '@shared/lib';

export const DailyPaymentInfoHistory = ({
    DailyHistoryInfo = [],
}: IDailyPaymentInfoHistoryArrDto) => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const totalPages = Math.ceil(DailyHistoryInfo.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = DailyHistoryInfo.slice(indexOfFirstItem, indexOfLastItem);

    const tableComponent = (data: IDailyPaymentInfoHistoryDto[]) => {
        return (
            <div className="min-w-[1080px]">
                <Table>
                    <Thead>
                        <Tr className="h-[41px] ">
                            <Th className='w-[162px]' rowSpan={2}>거래일</Th>
                            <Th colSpan={2}>합계</Th>
                            <Th colSpan={2}>결제</Th>
                            <Th colSpan={2}>취소</Th>
                        </Tr>
                        <Tr className="bg-P3_Gray1 text-center border-r min-w-[100px] last:border-none">
                            <Td className='w-[14%]'>결제건+취소건</Td>
                            <Td className='w-[14%]'>결재액-취소액</Td>
                            <Td className='w-[14%]'>건</Td>
                            <Td className='w-[14%]'>금액</Td>
                            <Td className='w-[14%]'>건</Td>
                            <Td className='w-[14%]'>금액</Td>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {data.map((item, index) => (
                            <Tr className="text-center" key={index}>
                                <Td className="border-r ">
                                    {item.tradingDate}
                                </Td>
                                <Td>{formatNumber(item.paymentCancelTransactionCount)}</Td>
                                <Td className="border-r ">
                                    {formatNumber(item.paymentCancleAmountsCount)}
                                </Td>
                                <Td className="border-r">
                                    {formatNumber(item.transaction)}
                                </Td>
                                <Td className="border-r">{formatNumber(item.amount)}</Td>
                                <Td className="border-r">
                                    {formatNumber(item.transaction)}
                                </Td>
                                <Td>{formatNumber(item.amount)}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </div>
        );
    };

    return (
        <div className="min-w-[1080px] overflow-auto">
            <ul>{tableComponent(currentItems)}</ul>
            {DailyHistoryInfo.length > 0 && (
                <PaginatedList
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(pageNumber) =>
                        handlePaginate(pageNumber, totalPages, setCurrentPage)
                    }
                />
            )}
        </div>
    );
};
