import React from 'react';
import { IMIDDto } from '@entities/CompanyInfo';
import { Logout } from '@features/CompanyInfo';
import { Table, Tbody, Td, Th, Tr } from '@shared/ui';

export const MaketID = ({ marketID }: IMIDDto) => {
    return (
        <>
            <Table className="mb-5">
                <Tbody>
                    <Tr className="border-P3_Gray4">
                        <Th className="w-[15%] text-start py-[10px] px-5">
                            상점아이디(MID)
                        </Th>
                        <Td className="w-[35%] pl-[15px] font-semibold">
                            {marketID}
                        </Td>
                    </Tr>
                    <Tr className="border-P3_Gray4">
                        <Th className="text-start py-[10px] px-5">계약상태</Th>
                        <Td className="pl-[15px] font-semibold">계약완료</Td>
                        <Th className="w-[15%] text-start py-[10px] px-5">
                            계약일
                        </Th>
                        <Td className="pl-[15px] font-semibold text-P3_Black">
                            {'2024-06-24'}
                        </Td>
                    </Tr>
                </Tbody>
            </Table>
            <Logout />
        </>
    );
};
