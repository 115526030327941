import React from 'react';
import { chargeContainer } from './charge.styled';
import { AnimatePresence, motion } from 'framer-motion';
import { ChargeHistory, ChargeHope } from '@widgets/Charge';
import { useHistoryInfo } from '@entities/Charge';
import { WalletBalance } from '@widgets/WalletBalance';

export const Charge = () => {
    const chargeHistoryInfo = useHistoryInfo();
    return (
        <div className={chargeContainer.class}>
            <AnimatePresence>
                <motion.div
                    key={location.pathname}
                    initial={{ opacity: 0, x: 1000 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 1000 }}
                    transition={{ duration: 0.4 }}
                >
                    <h1 className="text-P3_Black text-[22px] font-bold mb-5">
                        충전하기
                    </h1>
                    <WalletBalance />
                    <ChargeHope />
                    {chargeHistoryInfo && (
                        <ChargeHistory
                            chargeHistoryInfo={
                                chargeHistoryInfo.chargeHistoryInfo
                            }
                        />
                    )}
                </motion.div>
            </AnimatePresence>
        </div>
    );
};
