import { tw } from '@shared/lib/Tailwindest';

export const searchInput = tw.style({
    color:"text-P3_Black",
    width: "w-full",
    minWidth: "min-w-[420px]",
    height: "h-[40px]",
    borderRadius: "rounded-[8px]",
    padding: "p-3",
    borderWidth: "border",
    marginLeft: "ml-[2px]",
    boxSizing: "box-border",
    minHeight: "min-h-[25px]",
    backgroundColor: "bg-P3_Gray1",
    "::placeholder": {
        color: "placeholder:text-P3_Gray4",
        fontWeight:"placeholder:font-medium"
    },
    ':focus': {
        outlineStyle: "focus:outline-none",
        ringWidth: "focus:ring-2",
        ringColor: "focus:ring-P3_Red",
    },
    ':hover': {
        ringWidth: "hover:ring-2",
        ringColor: "hover:ring-P3_Red",

    }
});