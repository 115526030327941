import { Table, Tbody, Td, Th, Tr } from '@shared/ui';
import React from 'react';

export const UsageInfoFee = () => {
    return (
        <div className='min-w-[860px]'>
            <h1 className="text-P3_Black text-[18px] font-semibold mb-[17px] ml-2">
                수수료
            </h1>
            <Table>
                <Tbody>
                    <Tr className="border-P3_Gray4">
                        <Th className="w-[8%] text-start py-[10px] px-5 ">
                            point 3 페이
                        </Th>
                        <Td className="w-[35%] pl-[15px] font-semibold">
                            0.3%
                        </Td>
                    </Tr>
                </Tbody>
            </Table>
        </div>
    );
};
