import { ISalesDto } from '@entities/Calendar';
import { salesAPI } from '@entities/Calendar/api/salesAPI';
import { useEffect, useState } from 'react';

export function useSales() {
    const [sales, setSales] = useState<ISalesDto | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await salesAPI();
                setSales(response.data);
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        };

        fetchData();
    }, []);

    return sales;
}
